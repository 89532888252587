import React, { useState } from 'react';
import {
	Button,
	Modal,
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Stack,
	TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TemplateDatas from './TemplateDatas';
import { useRef } from 'react';
import { useEffect } from 'react';
import useTemplateStore from '../../../store/TemplateStore';
import { convert } from './TemplateConverter';
import ModalConfirm from '../../common/modal/ModalConfirm';

function TemplateMenu({ content, onApply }) {
	const { templates } = useTemplateStore();
	//적용시 공백 표기
	const handleApply = param => {
		const formattedContent = param.content.replace(/\n/g, '<br>');
		convert(param.template, content);

		onApply({ id: param.id, content: formattedContent, title: param.template.title });
	};

	const [open, setOpen] = useState(false);

	const [templateData, setTemplateData] = useState(
		TemplateDatas.reduce((acc, template) => {
			acc[template.id] = template.content || '';
			return acc;
		}, {})
	);

	const [expandedAccordions, setExpandedAccordions] = useState(
		TemplateDatas.reduce((acc, template) => {
			acc[template.id] = false;
			return acc;
		}, {})
	);

	useEffect(() => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect();
			setModalStyle({
				position: 'absolute',
				top: `${rect.top}px`,
				left: `${rect.left}px`,
			});
		}
	}, []);

	const [modalStyle, setModalStyle] = useState({});
	const buttonRef = useRef(null);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleInputChange = (id, value) => {
		setTemplateData(prev => ({
			...prev,
			[id]: value,
		}));
	};

	const toggleAccordion = id => () => {
		setExpandedAccordions(prev => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	const getAppliedContent = target => {
		if (content) {
			return convert(target, content);
		} else {
			return '';
		}
	};

	const getDoubleNumber = number => {
		return number < 10 ? `0${number}` : number;
	};

	return (
		<Box>
			{/* 팝업 열기 버튼 */}
			<Button
				ref={buttonRef}
				onClick={handleOpen}
				sx={theme => ({
					color: `#fff`,
					border: `1px solid #878D91`,
					minWidth: 'unset',
					width: 'fit-content',
					p: 0,
					ml: 0.5,
				})}
			>
				<PlayArrowIcon
					sx={theme => ({
						transform: 'rotate(90deg)',
						color: theme.palette.grey[500],
						fontSize: '24px',
					})}
				/>
			</Button>

			{/* 팝업 */}
			<Modal
				open={open}
				onClose={handleClose}
				sx={theme => ({
					display: 'flex',
					alignItems: 'center',
					'& > .MuiBackdrop-root': {
						bgcolor: `hsla(0, 0%, 100%, 0.5)`,
					},
				})}
			>
				<Stack sx={{ ...modalStyle }}>
					<Button
						onClick={handleClose}
						sx={theme => ({
							background: '#fff',
							border: `1px solid #CED3D6`,
							minWidth: 'unset',
							width: 'fit-content',
							p: 0,
							display: 'flex',
						})}
					>
						<PlayArrowIcon
							sx={theme => ({
								transform: 'rotate(-90deg)',
								color: `#878D91`,
								// fontSize: '0px',
							})}
						/>
					</Button>
					<Stack
						sx={theme => ({
							boxSizing: 'content-box',
							width: '481px',
							maxHeight: '640px',
							backgroundColor: `#fff`,
							border: `1px solid #CED3D6`,
							borderRadius: '8px',
							overflowY: 'auto',
							overflowX: 'hidden',
							// pr: 1,
							'&::-webkit-scrollbar': {
								width: '8px',
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '8px',
								background: `#CED3D6`,
							},
						})}
						spacing={0}
					>
						{templates.map((template, index) => (
							<Accordion
								key={template.id}
								expanded={expandedAccordions[template.id]}
								onChange={toggleAccordion(template.id)}
								disableGutters={true}
								sx={{ width: '481px' }}
							>
								<AccordionSummary sx={{ px: 3, py: 2, '& .MuiAccordionSummary-content': { m: 0 } }}>
									<Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '433px' }}>
										<Box sx={{ width: '279px', display: 'flex', alignItems: 'center' }}>
											<Typography
												sx={theme => ({
													...theme.typography.body2,
													mr: 1,
												})}
											>
												{`${getDoubleNumber(index + 1)} ${template.title}`}
											</Typography>
											{/* <Typography
												sx={theme => ({
													...theme.typography.body2,
												})}
											>
												{template.name}
											</Typography> */}
										</Box>

										<Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
											<ExpandMoreIcon
												sx={theme => ({
													color: theme.palette.grey[400],
													transition: 'transform 0.3s',
													transform: expandedAccordions[template.id]
														? 'rotate(180deg)'
														: 'rotate(0deg)',
												})}
											/>

											<ModalConfirm
												title='템플릿을 적용 할까요?'
												description='템플릿을 적용하면 기존 노트의 내용이 삭제됩니다.'
												onConfirm={() => {
													handleClose();
													handleApply({
														id: template.id,
														content: getAppliedContent(template),
														template: template,
													});
												}}
											>
												<Button
													sx={theme => ({
														px: 2,
														py: 0.5,
														background: `#1C6EFF`,
													})}
												>
													<Typography
														sx={theme => ({
															...theme.typography.body1,
															fontWeight: 500,
															color: `#fff`,
														})}
													>
														적용
													</Typography>
												</Button>
											</ModalConfirm>
										</Stack>
									</Stack>
								</AccordionSummary>
								<AccordionDetails>
									<TextField
										value={getAppliedContent(template)} //
										multiline
										onChange={e => handleInputChange(template.id, e.target.value)}
										minRows={15}
										maxRows={15}
										InputProps={{
											readOnly: true,
										}}
										sx={theme => ({
											'& .MuiInputBase-root': {
												borderRadius: '0px',
												border: `1px solid `,
												whiteSpace: 'pre-wrap',
												p: 2,
												background: `#fff`,
											},
											...theme.typography.body2,
											minWidth: '100%',
											'&:hover': {
												background: 'transparent',
											},
										})}
									></TextField>
								</AccordionDetails>
							</Accordion>
						))}
					</Stack>
				</Stack>
			</Modal>
		</Box>
	);
}

export default TemplateMenu;
