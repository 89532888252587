import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';

import { formatDate } from '@fullcalendar/core/index.js';
import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import './Calendar.css';
import moment from 'moment';
import 'moment/locale/ko';
import { drawContentIcon, drawContentTypeV2 } from '../common/ContentIcon/ContentIcon';

import { ReactComponent as DotIcon } from './img/dot.svg';
import { ReactComponent as ArrowLeftIcon } from './img/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from './img/arrowRight.svg';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Avatar from 'react-avatar';
import { getAvatarColor, getSafeUrl } from '../../util/Util';
import Empty from '../page-status/Empty';
import useContentsStore from '../../store/ContentsStore';
import { ToastError } from '../common/toast/Toast';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentCard from '../content/ContentCard';
import useFilterStore from '../../store/FilterStore';
import useAuthStore from '../../store/AuthStore';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const MAX_DISPLAYED_SHARE_USERS_COUNT = 10;

const getDiffDays = targetDate => {
	const today = moment();
	const target = moment(targetDate);

	return today.diff(target, 'days');
};

const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

const Calendar = forwardRef(({ onClickContent }, ref) => {
	const location = useLocation();
	const calendarRef = useRef();
	const navigate = useNavigate();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [currentMonth, setCurrentMonth] = useState(null);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [cards, setCards] = useState([]);

	const { auth } = useAuthStore();
	const { contents, getCalendarContents, setSelectedContent, applyedFilters } = useContentsStore();
	const { orderType, shareType, contentType, getFilteredData } = useFilterStore();

	useImperativeHandle(ref, () => ({
		refreshCalendar: refreshCalendarContents,
	}));

	useLayoutEffect(() => {
		refreshCalendarContents();
	}, [location]);

	useEffect(() => {
		refreshCards();
	}, [selectedDate, orderType, shareType, contentType]);

	useEffect(() => {
		console.log('contents.calendar : ', contents.calendar);
	}, [contents.calendar]);

	const refreshCalendarContents = () => {
		const calendarApis = calendarRef.current.getApi();

		if (calendarApis) {
			const startDate = moment(calendarApis.view.activeStart).format('YYYYMMDD');
			const endDate = moment(calendarApis.view.activeEnd).format('YYYYMMDD');

			const month = moment(calendarApis.view.currentStart).format('YYYY년 MM월');

			getCalendarContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, startDate, endDate);
			setCurrentMonth(month);
		}
	};
	const refreshCards = async () => {
		let result = [];

		if (contents && contents.calendar && contents.calendar.length) {
			const timeZone = 'Asia/Seoul'; // 서울 시간대 사용

			// selectedDate를 서울 시간대로 변환하여 해당 날짜의 시작과 끝 구하기
			const startOfDay = dayjs.tz(`${moment(selectedDate).format('YYYY-MM-DD')}T00:00:00`, timeZone);
			const endOfDay = dayjs.tz(`${moment(selectedDate).format('YYYY-MM-DD')}T23:59:59.999`, timeZone);

			// 데이터 필터링: meetingStartTime이 선택한 날짜와 일치하는지 확인
			result = contents.calendar.filter(content => {
				const meetingStart = dayjs.tz(content.meetingStartTime, 'UTC').tz(timeZone); // UTC -> 서울 시간대 변환
				return meetingStart.isSame(startOfDay, 'day'); // meetingStart가 선택한 날짜와 일치하는지 확인
			});

			// meetingStartTime 기준으로 정렬 (서울 시간대 기준)
			result.sort((a, b) => {
				const aTime = dayjs.tz(a.meetingStartTime, 'UTC').tz(timeZone).valueOf(); // 서울 시간으로 변환 후 밀리초로 비교
				const bTime = dayjs.tz(b.meetingStartTime, 'UTC').tz(timeZone).valueOf();
				return aTime - bTime; // 오름차순 정렬
			});
		}

		setCards(result);
	};

	// const refreshCards = useCallback(async () => {
	// 	const headers = {
	// 		Authorization: `Bearer ${cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken}`,
	// 	};

	// 	const response = await axios.get(
	// 		`${process.env.REACT_APP_API_URL}/contents?date=${moment(selectedDate).format('YYYYMMDD')}&withShared=true`,
	// 		{ headers }
	// 	);

	// 	if (response && response.status === 200) {
	// 		const filteredData = getFilteredData(response.data.data, shareType, contentType, orderType);
	// 		setCards(filteredData ?? response.data.data);
	// 	}
	// }, [selectedDate, orderType, shareType, contentType]);

	const drawDateTitle = () => {
		return moment(selectedDate).locale('ko').format('YYYY. MM. DD. dddd');
	};

	const drawContentCard = useCallback(
		data => {
			const getDurationFormat = milliseconds => {
				let seconds = Math.floor(milliseconds / 1000);
				const hours = Math.floor(seconds / 3600);
				seconds %= 3600;
				const minutes = Math.floor(seconds / 60);
				seconds %= 60;

				let result = '';
				if (hours > 0) {
					result += `${hours}시간 `;
				}
				if (minutes > 0) {
					result += `${minutes}분 `;
				}
				if (seconds > 0) {
					result += `${seconds}초`;
				}

				return result.trim();
			};

			let isVisible = true;

			if (applyedFilters && Object.keys(applyedFilters).length) {
				if (
					(applyedFilters.title && !data.title.includes(applyedFilters.title)) ||
					!data.editedTitle.includes(applyedFilters.title)
				) {
					isVisible = false;
				} else if (applyedFilters.type && !applyedFilters.type.some(type => type === data.type)) {
					isVisible = false;
				}
			}

			if (isVisible) {
				return (
					<ContentCard
						content={data}
						onClickCard={onClickContent}
						refreshContent={() => {
							refreshCards();
						}}
					/>
				);
			}
		},
		[applyedFilters, contents.calendar, cards]
	);

	const CustomEvent = useCallback(
		({ event }) => {
			const content = contents.calendar.find(content => content.contentId === event.id);
			let isVisible = true;

			if (applyedFilters && Object.keys(applyedFilters).length) {
				if (applyedFilters.title && !content.title.includes(applyedFilters.title)) {
					isVisible = false;
				} else if (applyedFilters.type && !applyedFilters.type.some(type => type === content.type)) {
					isVisible = false;
				}
			}

			const getBackgroundColor = () => {
				switch (content.type.toLowerCase()) {
					case 'audio':
						return '#D6E9FF';

					case 'video':
						return '#E2D9FD';

					case 'record':
						return '#FCD6D5';

					default:
						return 'transparent';
				}
			};

			if (isVisible) {
				return (
					<EventContainer title={event.title} backgroundColor={getBackgroundColor()}>
						{drawContentTypeV2(auth, content, 14, false, true)}

						<div
							style={{
								flex: 1,
								display: 'block',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{event.title}
						</div>
					</EventContainer>
				);
			} else {
				return <></>;
			}
		},
		[contents.calendar, applyedFilters]
	);

	const handleEventClick = event => {
		const target = contents.calendar.find(content => content.contentId === event.event.id);

		if (target) {
			setSelectedContent(target);
			navigate(getSafeUrl(`/content/${event.event.id}`));
		} else {
			ToastError(1000);
		}
	};

	const handlePrev = () => {
		calendarRef.current.getApi().prev();
		refreshCalendarContents();
	};

	const handleNext = () => {
		calendarRef.current.getApi().next();
		refreshCalendarContents();
	};

	const handleToday = () => {
		calendarRef.current.getApi().today();
		refreshCalendarContents();
		setSelectedDate(moment());
	};

	return (
		<div className='calendar-container'>
			<div className='calendar'>
				<div className='header'>
					<ButtonGroup disableRipple variant='outlined' size='small'>
						<Button
							sx={{
								width: 36,
								height: 40,
								padding: 0,
								borderRadius: `2px`,
								minWidth: `0 !important`,
								borderColor: '#CED3D6',
							}}
							onClick={handlePrev}
						>
							<ArrowLeftIcon />
						</Button>
						<Button
							sx={{ width: 36, height: 40, padding: 0, minWidth: `0 !important`, borderColor: '#CED3D6' }}
							onClick={handleNext}
						>
							<ArrowRightIcon />
						</Button>
						<Button
							sx={{
								width: 58,
								height: 40,
								padding: 0,
								minWidth: 0,
								borderRadius: `2px`,
								borderColor: '#CED3D6',
								color: '#292A2B',
								fontSize: 18,
								fontFamily: 'Noto Sans KR',
							}}
							onClick={handleToday}
						>
							오늘
						</Button>
					</ButtonGroup>

					<div className='date'>{currentMonth}</div>
				</div>
				<div className='component'>
					<FullCalendar
						ref={calendarRef}
						height={'100%'}
						dayHeaderContent={arg => daysOfWeek[arg.date.getDay()]}
						dayHeaderClassNames={arg => {
							const day = arg.date.getDay();
							if (day === 0) {
								return 'header sunday';
							} else if (day === 6) {
								return 'header saturday';
							}
							return '';
						}}
						dayCellClassNames={arg => {
							const day = arg.date.getDay();
							if (day === 0) {
								return 'day sunday';
							} else if (day === 6) {
								return 'day saturday';
							}
							return '';
						}}
						contentHeight={172}
						expandRows={true}
						plugins={[dayGridPlugin, interactionPlugin]}
						dateClick={date => setSelectedDate(date.date)}
						selectable={true}
						selectAllow={selectInfo => {
							const start = moment(selectInfo.startStr);
							const end = moment(selectInfo.endStr).subtract(1, 'days');
							return moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD');
						}}
						events={contents.calendar}
						eventContent={event => <CustomEvent event={event.event} />}
						eventOrder={'start'}
						headerToolbar={false}
						eventClick={handleEventClick}
						dayMaxEvents={4}
						dayMaxEventRows={true}
						eventBackgroundColor='transparent'
						eventBorderColor='transparent'
						eventTextColor='#28323c'
						moreLinkText={num => `+${num}개 더보기`} // 동적으로 설정
						moreLinkClick={info => {
							setSelectedDate(info.date);
							setTimeout(() => {
								refreshCalendarContents();
							}, 300);

							return 'dayGridMonth';
						}}
						showNonCurrentDates={true}
					/>
				</div>
			</div>
			<div className='day-contents' style={{ backgroundColor: '#F9FAFB' }}>
				<div className='date-text'>{drawDateTitle()}</div>
				<div className='cards'>
					{cards && cards.length ? (
						cards.map(card => drawContentCard(card))
					) : (
						<div className='empty'>
							<Empty type='calendar' />
						</div>
					)}
				</div>
			</div>
		</div>
	);
});

export default Calendar;

const EventContainer = styled.div`
	width: 100%;
	height: 26px;
	gap: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: ${props => props.backgroundColor || 'transparent'};
	border-radius: 4px;
	font-size: 14px;
	padding: 0 5px;

	&:hover {
		background: #e2e5e9;
	}
`;
