import ReactLoading from 'react-loading';

import { ReactComponent as CallIcon } from './img/call.svg';
import { ReactComponent as MeetingIcon } from './img/meeting.svg';
import { ReactComponent as RecordIcon } from './img/record.svg';
import { ReactComponent as VideoIcon } from './img/video.svg';
import { ReactComponent as VoiceIcon } from './img/voice.svg';
import { ReactComponent as DefaultIcon } from './img/default.svg';
import { ReactComponent as ErrorIcon } from './img/error.svg';

// V2
import { ReactComponent as AudioV2Icon } from './img/audio_v2.svg';
import { ReactComponent as VideoV2Icon } from './img/video_v2.svg';

import { ReactComponent as RestoreIcon } from './img/restore.svg';
import { ReactComponent as DeleteIcon } from './img/delete.svg';
import { ReactComponent as ShareIcon } from './img/share.svg';
import { ReactComponent as SharedContentIcon } from './img/is_shared.svg';
import { ReactComponent as ExportedContentIcon } from './img/is_export.svg';
import { ReactComponent as RecordV2Icon } from './img/record_v2.svg';

export const drawContentIcon = (type, size = 40) => {
	if (type) {
		switch (type.toLowerCase()) {
			case 'loading':
				return <ReactLoading type={'spinningBubbles'} color='#858c99' height={'100%'} width={'100%'} />;

			case 'video':
				return <VideoIcon width={size} height={size} />;

			case 'meeting':
				return <MeetingIcon width={size} height={size} />;

			case 'call':
				return <CallIcon width={size} height={size} />;

			case 'record':
				return <RecordIcon width={size} height={size} />;

			case 'audio':
				return <VoiceIcon width={size} height={size} />;

			default:
				return <DefaultIcon width={size} height={size} />;
		}
	}
};

export const drawContentTypeV2 = (auth, content, size = 40, isContent, onlyIcon = false) => {
	try {
		if (content.transcribeStatus === 'ERROR') {
			return <ErrorIcon width={size} height={size} />;
		} else if (content.isNew || content.transcribeStatus === 'RUNNING') {
			return <ReactLoading type={'spinningBubbles'} color='#858c99' height={size} width={size} />;
		} else {
			const isExportContent =
				auth && auth.user && content && content.creatorPID === auth.user.pid && content.shareUsers?.length;

			const isSharedContent = auth && auth.user && content && content.creatorPID !== auth.user.pid;

			const drawIcon = () => {
				if (content && content.type) {
					switch (content.type.toLowerCase()) {
						case 'call':
						case 'record':
							return <RecordV2Icon width={size} height={size} />;

						case 'audio':
							return <AudioV2Icon width={size} height={size} />;

						case 'video':
						case 'meeting':
							return <VideoV2Icon width={size} height={size} />;

						default:
							return <>{content.type.toLowerCase()}</>;
					}
				} else {
					return <>Unknown</>;
				}
			};

			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'relative',
					}}
				>
					{drawIcon()}
					<div
						className='status'
						style={{
							position: 'absolute',
							right: 0,
							bottom: '0px',
							height: size / 2,
							width: size / 2,
							lineHeight: '0 !important',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{!onlyIcon && (isSharedContent || content.isShared) ? (
							<SharedContentIcon style={{ position: 'absolute', width: 'inherit', height: 'inherit' }} />
						) : (
							<></>
						)}
						{!onlyIcon && (isExportContent || content.isExport) ? (
							<ExportedContentIcon
								style={{ position: 'absolute', width: 'inherit', height: 'inherit' }}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
			);
		}
	} catch (e) {
		return <>Unknown Exception</>;
	}
};

export const drawGridIcons = (type, size = 24, shareUsers = []) => {
	try {
		if (type) {
			switch (type) {
				case 'share': {
					const isExistShareUsers = shareUsers && shareUsers.length;

					return (
						<div style={{ position: 'relative', display: 'flex' }}>
							<ShareIcon width={size} height={size} />
							{isExistShareUsers ? (
								<div
									style={{
										position: 'absolute',
										top: -2,
										right: -2,
										width: '6px',
										height: '6px',
										borderRadius: '50%',
										background: '#1C6EFF',
									}}
								/>
							) : null}
						</div>
					);
				}

				case 'restore':
					return <RestoreIcon width={size} height={size} />;

				case 'delete':
					return <DeleteIcon width={size} height={size} />;

				default:
					return <></>;
			}
		}
	} catch {
		return <>Unknown</>;
	}
};
