// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./NotoSansKR-Thin.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./NotoSansKR-ExtraLight.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./NotoSansKR-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./NotoSansKR-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./NotoSansKR-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./NotoSansKR-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./NotoSansKR-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./NotoSansKR-ExtraBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./NotoSansKR-Black.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 100;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 200;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 300;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 400;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 500;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 600;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 700;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 800;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 900;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff');
}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAiD;AAClD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAuD;AACxD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAkD;AACnD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAoD;AACrD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAmD;AACpD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAqD;AACtD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAiD;AAClD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAsD;AACvD;AACA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,2DAAkD;AACnD","sourcesContent":["@charset \"utf-8\";\n\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 100;\n\tsrc: url('./NotoSansKR-Thin.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 200;\n\tsrc: url('./NotoSansKR-ExtraLight.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 300;\n\tsrc: url('./NotoSansKR-Light.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 400;\n\tsrc: url('./NotoSansKR-Regular.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 500;\n\tsrc: url('./NotoSansKR-Medium.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 600;\n\tsrc: url('./NotoSansKR-SemiBold.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 700;\n\tsrc: url('./NotoSansKR-Bold.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 800;\n\tsrc: url('./NotoSansKR-ExtraBold.woff') format('woff');\n}\n@font-face {\n\tfont-family: 'Noto Sans KR';\n\tfont-weight: 900;\n\tsrc: url('./NotoSansKR-Black.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
