import React from 'react';
import styled from 'styled-components';
import ActionLink from './ActionLink';

const AccountActions = ({ components }) => {
	const actions = [
		{ label: '비밀번호 찾기', id: 'password-reset' },
		{ label: '아이디 찾기', id: 'id-recovery' },
		{ label: '계정 가입', id: 'account-signup' },
	];

	return (
		<AccountActionsWrapper>
			<ActionList>{components}</ActionList>
		</AccountActionsWrapper>
	);
};

const AccountActionsWrapper = styled.section`
	display: flex;
	margin-top: 16px;
	width: 100%;
	max-width: 320px;
	padding-right: 16px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const ActionList = styled.nav`
	display: flex;
	align-items: center;
	gap: 12px;
	justify-content: start;
	font-family: 'Noto Sans KR';
`;

export default AccountActions;
