import chalk from 'chalk';
import axios from 'axios';

(function () {
	const originalConsoleLog = console.log;
	const logStorage = {}; // 그룹별 로그를 저장할 객체
	const MAX_LOG_SIZE = 256; // 최대 로그 크기 설정

	// console.log 재정의
	console.info = function (...args) {
		if (args.length === 2 && typeof args[0] === 'string' && typeof args[1] === 'string') {
			const [message, group] = args;

			const date = new Date().toLocaleTimeString();

			// 로그 저장
			if (!logStorage[group]) {
				logStorage[group] = [];
			}
			// 로그 배열 크기가 1024 이상일 경우 오래된 로그부터 삭제
			if (logStorage[group].length >= MAX_LOG_SIZE) {
				logStorage[group].shift(); // 첫 번째 항목(가장 오래된)을 삭제
			}
			logStorage[group].push({ date, message });

			// 콘솔에 출력
			originalConsoleLog(
				`${chalk.bold.blue(`[${group}] [${date}]`)}${chalk.bold.blue('[info]')} ${chalk.blue(`${message}`)}`
			);
		} else {
			// 기본 동작
			originalConsoleLog(args[0]);
		}
	};

	console.warn = function (...args) {
		if (args.length === 2 && typeof args[0] === 'string' && typeof args[1] === 'string') {
			const [message, group] = args;

			const date = new Date().toLocaleTimeString();

			// 로그 저장
			if (!logStorage[group]) {
				logStorage[group] = [];
			}
			// 로그 배열 크기가 1024 이상일 경우 오래된 로그부터 삭제
			if (logStorage[group].length >= MAX_LOG_SIZE) {
				logStorage[group].shift(); // 첫 번째 항목(가장 오래된)을 삭제
			}
			logStorage[group].push({ date, message });

			// 콘솔에 출력
			originalConsoleLog(
				`${chalk.bold.blue(`[${group}] [${date}]`)}${chalk.bold.yellow('[warn]')} ${chalk.yellow(`${message}`)}`
			);
		} else {
			// 기본 동작
			originalConsoleLog(args[0]);
		}
	};

	console.error = function (...args) {
		if (args.length === 2 && typeof args[0] === 'string' && typeof args[1] === 'string') {
			const [message, group] = args;

			const date = new Date().toLocaleTimeString();

			// 로그 저장
			if (!logStorage[group]) {
				logStorage[group] = [];
			}
			// 로그 배열 크기가 1024 이상일 경우 오래된 로그부터 삭제
			if (logStorage[group].length >= MAX_LOG_SIZE) {
				logStorage[group].shift(); // 첫 번째 항목(가장 오래된)을 삭제
			}
			logStorage[group].push({ date, message });

			// 콘솔에 출력
			originalConsoleLog(
				`${chalk.bold.blue(`[${group}] [${date}]`)}${chalk.bold.red('[error]')} ${chalk.red(`${message}`)}`
			);
		} else {
			// 기본 동작
			originalConsoleLog(args[0]);
		}
	};

	// flush 함수 추가
	console.flush = function (group, email, accessToken, isSave = true) {
		if (logStorage[group]) {
			const logs = logStorage[group];
			console.log('flush', logs);

			// 서버로 전송하는 로직 (예: fetch 사용)
			const URL = `${process.env.REACT_APP_API_URL}/bo/log/error`;

			axios
				.post(
					URL,
					{ email: email, group: group, transaction: [...logs] },
					{ headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` } }
				)
				.then(response => {
					console.log('[Recorder] flush response', response);
					if (response.data.httpCode === 201) {
						originalConsoleLog(chalk.green(`[${group}] logs flushed successfully.`));
						delete logStorage[group]; // 성공적으로 전송 후 해당 그룹 로그 삭제
					} else {
						originalConsoleLog(chalk.red(`[${group}] error flushing logs: ${response.data.httpCode}`));
					}
				})
				.catch(error => {
					originalConsoleLog(chalk.red(`[${group}] error flushing logs: ${error.message}`));
				});
		} else {
			originalConsoleLog(chalk.yellow(`[${group}] no logs to flush.`));
		}
	};
})();
