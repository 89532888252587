import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './ContentV2.css';

import dayjs from 'dayjs';
import HeaderV2 from '../body/headerV2/HeaderV2';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../store/AuthStore';
import useContentsStore from '../../store/ContentsStore';
import { drawContentTypeV2 } from '../common/ContentIcon/ContentIcon';
import Popup from 'reactjs-popup';
import Share from '../popup/share/Share';
import Download from '../popup/download/Download';
import TimbloButton from '../button/TimbloButton';
import ReSummary from '../popup/resummary/ReSummary';

import { ReactComponent as DisableCheckIcon } from './transcription/img/disablecheck.svg';
import { ReactComponent as AbleCheckIcon } from './transcription/img/ablecheck.svg';

import DeleteConfirm from '../popup/content/delete-confirm/DeleteConfirm';
import { ToastError, ToastInfo } from '../common/toast/Toast';
import { getAvatarColor, getBlockInfo, getBlockTitle, getSafeUrl, isUUID, onCopyText } from '../../util/Util';
import {
	Box,
	Stack,
	Tab,
	Tabs,
	Typography,
	IconButton,
	InputBase,
	Paper,
	Button,
	Popover,
	SvgIcon,
	Modal,
	Checkbox,
} from '@mui/material';
import { PageLoading, SttMaking } from '../page-status/PageLoading';
import SummaryBlock from './SummaryBlock';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as RecordIcon } from './img/mic.svg';
import { ReactComponent as DesktopIcon } from './img/desktop.svg';
import { ReactComponent as MobileIcon } from './img/mobile.svg';
import { ReactComponent as ChatbotIcon } from './img/chatbot.svg';
import { ReactComponent as PlayIcon } from './img/play.svg';
import { ReactComponent as CalendarIcon } from './img/calendar.svg';

import Transcription from './transcription/Transcription';
import TranscriptionVS from './transcription/TranscriptionVS';
import TranscriptionV2 from './transcription/TranscriptionV2';
import Note from './note/Note';
import MyTemplate from './template/MyTemplate';
import DataChip from './DataChip';
import useAttendeeStore from '../../store/AttendeeStore';
import BookmarkList from './bookmark/Bookmark';
import Chatbot from '../chatbot/Chatbot';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContextMenu from '../popup/contextMenu/ContextMenu';
import Empty from '../page-status/Empty';
import { getHighlightText, getHighlightTypo } from './common/HighlightText';
import MuiCalendar from '../muicalendar/MuiCalendar';
import { toast } from 'react-toastify';
import MeetingTime from './MeetingTime';
import ModalConfirm from '../common/modal/ModalConfirm';
import ShareButton from '../common/button/ShareButton';
import CorrectionStore from '../../store/CorrectionStore';
import { CheckBox } from '@mui/icons-material';
import axios from 'axios';

const TABS = [
	{ label: '전체 요약', value: 'summary' },
	{ label: '북마크', value: 'bookmark' },
	{ label: '노트', value: 'note' },
	//	{ label: 'MY 템플릿', value: 'template' },
];

const BLOCK_ORDER = ['topics', 'speakerInfo', 'keywords', 'summary', 'issues', 'tasks', 'summaryTime'];

const ContentV2 = forwardRef(({}, ref) => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [highlight, setHighlight] = useState(queryParams.get('highlight') ?? '');

	const noteRef = useRef();
	const transcriptionRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { auth } = useAuthStore();

	const {
		getContents,
		sttStatus,
		selectedContent,
		setSelectedContent,
		refreshContentFromContentId,
		updateContentTitle,
		deleteContents,
		addBookmark,
		deleteBookmark,
		onAppendDataToNote,
		updateContentKeywords,
		updateContentAttendee,
		onResummaryContent,
	} = useContentsStore();

	const { onAddAttendee, onDeleteAttendee } = useAttendeeStore();
	const { setAudioSource } = CorrectionStore();

	const [content, setContent] = useState(null);
	const [blocks, setBlocks] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState(TABS[0]);
	const [status, setStatus] = useState({ code: -1, message: '' });
	const [isEditable, setIsEditable] = useState(false);

	const [selectedSummaryTime, setSelectedSummaryTime] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const [meetingStartTime, setMeetingStartTime] = useState(null);
	const [meetingEndTime, setMeetingEndTime] = useState(null);

	const [isOpenMeetingTimePopup, setIsOpenMeetingTimePopup] = useState(false);
	const [isCheckPopup, setIsCheckPopup] = useState(false);

	const [isFirstRender, setIsFirstRender] = useState(true);

	const makeStreamUrl = useCallback(
		id => {
			let url = '';

			if (
				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
			) {
				if (id) {
					url = `${process.env.REACT_APP_API_URL}/contents/download/${id}?accessToken=${
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
					}`;
				}
			}

			return url;
		},
		[cookies]
	);

	useLayoutEffect(() => {
		const contentId = location.pathname.split('/')[2];

		if (contentId && isUUID(contentId)) {
			refreshContent(contentId);
		}
	}, []);

	useEffect(() => {});

	useEffect(() => {
		if (content && content.meta) {
			const streamUrl = makeStreamUrl(content.meta.contentId);
			setAudioSource(streamUrl);

			if (content.meta.isTimeChangeNeeded && isFirstRender) {
				setIsOpenMeetingTimePopup(content.meta.isTimeChangeNeeded);
				setIsFirstRender(false);
			}
		}
	}, [content, makeStreamUrl, isFirstRender]);

	useEffect(() => {
		if (content) {
			setMeetingStartTime(content.meta.meetingStartTime ?? dayjs());
			setMeetingEndTime(content.meta.meetingEndTime ?? dayjs());
			setIsEditable(content.meta.permission !== 'VIEWER');

			const orderedData = getOrderedData(content);

			if (orderedData) {
				setBlocks(orderedData);
			}
		}
	}, [content]);

	useEffect(() => {
		if (sttStatus.list) {
			const contentId = location.pathname.split('/')[2];

			const item = sttStatus.list.find(item => item.contentId === contentId);

			if (item) {
				switch (item.status.toUpperCase()) {
					case 'DONE':
					case 'ERROR':
						refreshContent(contentId);
						setLoading(false);
						break;
					default:
						setLoading(true);
						break;
				}
			}
		}
	}, [sttStatus.list]);

	const refreshContent = contentId => {
		getContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId)
			.then(data => {
				switch (data.code) {
					case 200:
						setStatus({ code: 200, message: 'OK' });
						setContent(data.data);
						break;
					case 403:
					case 404:
						setStatus({ code: data.code, message: 'Loading...' });
						break;

					case 422:
						setStatus({ code: data.code, message: 'Stt Making' });
						break;

					case 423:
						//조회할 수 없는 컨텐츠입니다.
						setStatus({ code: data.code, message: 'Error' });
						break;
					default:
						//장애 발생
						setStatus({ code: data.code, message: 'Something happened' });
						ToastError(1002);
						break;
				}
			})
			.catch(e => {
				setStatus({ code: e.code, message: e.message });
			})
			.finally(() => setLoading(false));
	};

	const getOrderedData = data => {
		const orderedArray = [];
		BLOCK_ORDER.forEach(key => {
			if (data[key]) {
				orderedArray.push({ [key]: data[key] });
			} else if (data.aiResult && data.aiResult[key]) {
				orderedArray.push({ [key]: data.aiResult[key] });
			}
		});

		return orderedArray;
	};

	const handleTabChange = (event, text) => {
		const target = TABS.find(tab => tab.label === text);

		if (target) {
			setCurrentTab(target);
		}
	};

	const onCheckIsBookmarkBlock = key => {
		if (content && content.bookmarks) {
			if (key === 'summaryTime') {
				const target = content.bookmarks.find(bookmark => bookmark.key === 'summaryTime');
				return target && target.isAll === 'Y';
			}

			return content.bookmarks.some(bookmark => bookmark.key === key);
		} else {
			return false;
		}
	};

	const onToggleBookmark = block => {
		if (isEditable) {
			const key = Object.keys(block)[0];

			if (key) {
				if (onCheckIsBookmarkBlock(key)) {
					deleteBookmark(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						content.file.contentId,
						key,
						key === 'summaryTime' ? -1 : null
					)
						.then(res => {
							switch (res.code) {
								case 200:
									ToastInfo('북마크가 삭제 되었습니다.');
									refreshContent(content.file.contentId);
									break;
								case 401:
									ToastError(0, '권한이 없습니다.');
									break;
								default:
									ToastError(res.message);
									break;
							}
						})
						.catch(e => {
							ToastError(1016);
						});
				} else {
					addBookmark(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						content.file.contentId,
						key,
						null,
						key === 'summaryTime' ? -1 : null
					)
						.then(res => {
							switch (res.code) {
								case 200:
									ToastInfo('북마크가 추가 되었습니다.');
									refreshContent(content.file.contentId);
									break;
								case 401:
									ToastError(0, '권한이 없습니다.');
									break;
								default:
									ToastError(res.message);
									break;
							}
						})
						.catch(e => {
							ToastError(1016);
						});
				}
			}
		} else {
			ToastError(0, '권한이 없습니다.');
		}
	};

	const onCopyClipboard = text => {
		onCopyText(text, () => ToastInfo('텍스트가 복사되었습니다.'));
	};

	const onPasteNote = block => {
		if (isEditable) {
			const key = Object.keys(block)[0];

			if (key) {
				onAppendDataToNote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, content.file.contentId, {
					itemKey: key,
					itemValue: block[key],
				});
			}
		} else {
			ToastError(0, '권한이 없습니다.');
		}
	};

	const update = () => {
		return new Promise((resolve, reject) => {
			try {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken}`,
				};

				const body = {
					meetingStartTime: formatDateToCustomString(new Date(meetingStartTime)),
					meetingEndTime: formatDateToCustomString(new Date(meetingEndTime)),
				};

				const url = `${process.env.REACT_APP_API_URL}/contents/${content.meta.contentId}/meetingTime`;
				axios
					.patch(url, body, {
						headers: headers,
					})
					.then(res => {
						if (res.data.httpCode === 200) {
							resolve();
						} else {
							reject(res.data.httpCode);
						}
					})
					.catch(err => {
						console.error(err);
						reject(-101);
					});
			} catch (e) {
				console.error(e);
				reject(-102);
			}
		});
	};

	const formatDateToCustomString = date => {
		const padZero = num => String(num).padStart(2, '0'); // 숫자를 두 자리로 패딩

		const year = date.getFullYear();
		const month = padZero(date.getMonth() + 1); // 월은 0부터 시작하므로 +1
		const day = padZero(date.getDate());
		const hours = padZero(date.getHours());
		const minutes = padZero(date.getMinutes());

		return `${year}${month}${day}${hours}${minutes}`;
	};

	const handleCheckPopupConfirm = callback => {
		if (isCheckPopup) {
			update();
		}

		callback && callback();
	};

	const drawLeftView = () => {
		switch (currentTab.value) {
			case 'summary':
				return blocks.map((block, index) => (
					<SummaryBlock
						key={index}
						isBookmark={onCheckIsBookmarkBlock(Object.keys(block)[0])}
						title={getBlockTitle(Object.keys(block)[0])}
						info={getBlockInfo(Object.keys(block)[0])}
						enableContextMenu={anchorEl ? false : true}
						onToggleBookmark={() => onToggleBookmark(block)}
						onCopyClipboard={onCopyClipboard}
						onPasteNote={() => onPasteNote(block)}
					>
						{drawBlock(block)}
					</SummaryBlock>
				));
			case 'bookmark':
				return (
					<BookmarkList
						contentId={content.meta.contentId}
						data={content.bookmarks}
						refreshContent={() => refreshContent(content.file.contentId)}
					/>
				);

			case 'note':
				return (
					<Note
						ref={noteRef}
						content={content}
						contentId={content.meta.contentId}
						editable={content.meta.permission !== 'VIEWER'}
					/>
				);

			case 'template':
				return (
					<MyTemplate
						content={content}
						contentId={content.meta.contentId}
						editable={content.meta.permission !== 'VIEWER'}
					/>
				);

			default:
				return <></>;
		}
	};

	const drawContentStatus = () => {
		switch (status.code) {
			case 404:
				return <Empty type='contents-result' />;

			case 413:
				return <Empty type='deleted-content' />;

			case 422:
				return <SttMaking />;

			case 423:
			default:
				return <Empty type='error' />;
		}
	};

	const drawBlock = block => {
		const key = Object.keys(block)[0];
		const value = block[key];

		if (value && value.length) {
			switch (key) {
				case 'speakerInfo': {
					return (
						<Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1.5 }}>
							{value.map((item, index) => (
								<DataChip
									key={index}
									enableEdit={isEditable}
									data={item.displayName ?? item.name}
									text={getHighlightTypo({
										highlightText: highlight,
										text: item.displayName ?? item.name,
										sx: {
											fontWeight: 500,
											fontFamily: 'Noto Sans KR',
										},
									})}
									index={index}
									onDelete={() => {
										onDeleteAttendee(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.file.contentId,
											item.speakerId,
											() => refreshContent(content.file.contentId)
										);
									}}
									onUpdate={newText => {
										updateContentAttendee(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.file.contentId,
											item.speakerId,
											newText,
											() => refreshContent(content.file.contentId),
											() => console.log('FAiled')
										);
									}}
									type={key}
								/>
							))}
							<AddButton
								onAdd={text => {
									onAddAttendee(
										cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
										content.file.contentId,
										{ displayName: text },
										() => refreshContent(content.file.contentId)
									);
								}}
							/>
						</Stack>
					);
				}

				case 'keywords': {
					return (
						<Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1.5 }}>
							{value.map((item, index) => (
								<DataChip
									enableEdit={isEditable}
									data={item}
									text={getHighlightTypo({
										highlightText: highlight,
										text: item,
										sx: { fontWeight: 500, fontFamily: 'Noto Sans KR' },
									})}
									index={index}
									onDelete={() => {
										const newBlocks = [...blocks];
										const keywords = newBlocks.find(
											b => Object.keys(b)[0] === 'keywords'
										)?.keywords;

										if (keywords) {
											const updatedKeywords = keywords.filter(text => text !== item);

											updateContentKeywords(
												cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
												content.file.contentId,
												updatedKeywords,
												() => refreshContent(content.file.contentId),
												() => console.log('FAiled')
											);
										}
									}}
									onUpdate={newText => {
										const newBlocks = [...blocks];
										const keywords = newBlocks.find(
											b => Object.keys(b)[0] === 'keywords'
										)?.keywords;

										if (keywords) {
											const updatedKeywords = keywords.map(word =>
												word === item ? newText : word
											);

											updateContentKeywords(
												cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
												content.file.contentId,
												updatedKeywords,
												() => refreshContent(content.file.contentId),
												() => console.log('FAiled')
											);
										}
									}}
									type={key}
								/>
							))}
							<AddButton
								onAdd={newText => {
									const newBlocks = [...blocks];
									const keywords = newBlocks.find(b => Object.keys(b)[0] === 'keywords')?.keywords;

									if (keywords) {
										const updatedKeywords = [...keywords, newText];

										updateContentKeywords(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.file.contentId,
											updatedKeywords,
											() => refreshContent(content.file.contentId),
											() => console.log('FAiled')
										);
									}
								}}
							/>
						</Stack>
					);
				}

				case 'summaryTime': {
					const onToggleBookmarkSummaryTime = () => {
						if (isEditable) {
							if (onCheckBookmarkSummaryTime(selectedSummaryTime)) {
								deleteBookmark(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									content.meta.contentId,
									'summaryTime',
									selectedSummaryTime.index
								)
									.then(res => {
										switch (res.code) {
											case 200:
												ToastInfo('북마크가 제거 되었습니다.');
												refreshContent(content.file.contentId);
												break;
											case 401:
												ToastError(0, '권한이 없습니다.');
												break;
											default:
												ToastError(res.message);
												break;
										}
									})
									.catch(e => {
										ToastError(1016);
									});
							} else {
								addBookmark(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									content.meta.contentId,
									'summaryTime',
									null,
									selectedSummaryTime.index
								)
									.then(res => {
										switch (res.code) {
											case 200:
												ToastInfo('북마크가 추가 되었습니다.');
												refreshContent(content.file.contentId);
												break;
											case 401:
												ToastError(0, '권한이 없습니다.');
												break;
											default:
												ToastError(res.message);
												break;
										}
									})
									.catch(e => {
										ToastError(1016);
									});
							}
						} else {
							ToastError(null, '권한이 없습니다.');
						}
					};

					const onPasteNoteSummaryTime = () => {
						if (isEditable) {
							onAppendDataToNote(
								cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
								content.meta.contentId,
								{ itemKey: 'summaryTime', itemValue: selectedSummaryTime }
							);
						} else {
							ToastError(null, '권한이 없습니다.');
						}
					};

					const onCheckBookmarkSummaryTime = block => {
						if (!block) {
							return false;
						}

						const summaryBookmarks = content.bookmarks.find(bookmark => bookmark.key === 'summaryTime');

						if (summaryBookmarks) {
							return summaryBookmarks.data.some(summary => summary.index === block.index);
						} else {
							return false;
						}
					};

					const convertTimeStampToSeconds = timestamp => {
						const timeParts = timestamp.split(':');
						if (timeParts.length !== 3) {
							throw new Error('Invalid time format. Expected hh:mm:ss.');
						}

						const [hours, minutes, seconds] = timeParts.map(Number);

						if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
							throw new Error('Invalid time values.');
						}

						return hours * 3600 + minutes * 60 + seconds;
					};

					return (
						<Stack direction={'column'} spacing={4}>
							{value.map((summaryTime, index) => (
								<Paper
									onMouseEnter={e => {
										setAnchorEl(e.currentTarget);
										setSelectedSummaryTime(summaryTime);
									}}
									onMouseLeave={() => {
										setAnchorEl(null);
										setSelectedSummaryTime(null);
									}}
									sx={{
										boxShadow: 'none',
										background: onCheckBookmarkSummaryTime(summaryTime) ? '#F5FEF5' : 'inherit',
										'&:hover [data-index]': {
											opacity: 1,
											transition: 'opacity 0.3s',
										},
									}}
								>
									<Stack spacing={1} sx={{ pb: 1 }}>
										<Typography
											sx={{
												fontSize: 18,
												fontWeight: 600,
												color: '#4D5256',
												fontFamily: 'Noto Sans KR',
												display: 'flex',
												position: 'relative',
											}}
										>
											{getHighlightTypo({
												highlightText: highlight,
												text: summaryTime.index + '. ' + summaryTime.topic,
												sx: {
													fontSize: 18,
													fontWeight: 600,
													color: '#4D5256',
													fontFamily: 'Noto Sans KR',
													display: 'flex',
												},
											})}

											<Typography
												sx={{
													fontSize: 16,
													fontFamily: 'Noto Sans KR',
													color: '#878D91',
													paddingLeft: '8px',
												}}
											>
												{summaryTime.time}
											</Typography>

											<Box
												data-index={index}
												sx={{
													display: 'flex',
													position: 'absolute',
													right: 0,
													top: -25,
													opacity: 0,
												}}
											>
												<ContextMenu
													isBookmark={onCheckBookmarkSummaryTime(selectedSummaryTime)}
													onToggleBookmark={onToggleBookmarkSummaryTime}
													onCopyClipboard={() =>
														onCopyClipboard(anchorEl.parentNode.parentNode.innerText)
													}
													onPasteNote={onPasteNoteSummaryTime}
													onClose={() => setAnchorEl(null)}
												/>
											</Box>

											{/* <IconButton
												className={`function-button function-button-${index}`}
												sx={{
													width: 24,
													height: 24,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													border: '1px solid #E1E4E6',
													borderRadius: '4.8px',
													boxSizing: 'border-box',
													marginLeft: 'auto !important',
													opacity: 0,

													'&:hover': {
														border: '1px solid #5793FF',
													},

													'&:hover > svg': {
														color: '#5793FF',
													},
												}}
												onClick={event => {
													setAnchorEl(event.currentTarget);
													setSelectedSummaryTime(summaryTime);
												}}
											>
												<MoreVertIcon fontSize={'small'} />
											</IconButton> */}
										</Typography>

										{summaryTime.summary.map(summary => (
											<Stack
												spacing={0.5}
												direction={'row'}
												alignItems={'center'}
												sx={{
													// height: 30,
													'&:hover p': {
														fontWeight: 700,
													},

													'&:hover .icon-button': {
														opacity: 1,
													},
												}}
											>
												{getHighlightTypo({
													highlightText: highlight,
													text: `ㆍ ${
														typeof summary === 'object' ? summary.content : summary
													}`,
													sx: {
														fontSize: 18,
														fontWeight: 500,
														fontFamily: 'Noto Sans KR',
														color: '#4D5256',
													},
												})}
												{summary.timestamp && (
													<IconButton
														className='icon-button'
														sx={{ width: '35px', opacity: 0 }}
														onClick={() =>
															transcriptionRef.current.onSeekTime(
																convertTimeStampToSeconds(summary.timestamp)
															)
														}
													>
														<PlayIcon />
													</IconButton>
												)}
											</Stack>
										))}

										{summaryTime.issues &&
											summaryTime.issues.map(issue => (
												<Stack
													spacing={1}
													direction='row'
													alignItems={'center'}
													sx={{
														'&:hover p': {
															fontWeight: 700,
														},

														'&:hover .icon-button': {
															opacity: 1,
														},
													}}
												>
													<Box
														sx={{
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#fff',
															background: '#35B54F',
															padding: `2px 8px`,
															borderRadius: '8px',
															marginLeft: '10px !important',
															flexShrink: 0,
														}}
													>
														이슈
													</Box>
													{getHighlightTypo({
														highlightText: highlight,
														text: typeof issue === 'object' ? issue.content : issue,
														sx: {
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#4D5256',
														},
													})}
													{issue.timestamp && (
														<IconButton
															className='icon-button'
															sx={{ width: '35px', opacity: 0 }}
															onClick={() =>
																transcriptionRef.current.onSeekTime(
																	convertTimeStampToSeconds(issue.timestamp)
																)
															}
														>
															<PlayIcon />
														</IconButton>
													)}
												</Stack>
											))}

										{summaryTime.tasks &&
											summaryTime.tasks.map(task => (
												<Stack
													spacing={1}
													direction='row'
													alignItems={'center'}
													sx={{
														'&:hover p': {
															fontWeight: 700,
														},

														'&:hover .icon-button': {
															opacity: 1,
														},
													}}
												>
													<Box
														sx={{
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#fff',
															background: '#FF7E29',
															padding: `2px 8px`,
															borderRadius: '8px',
															marginLeft: '10px !important',
															flexShrink: 0,
														}}
													>
														할일
													</Box>
													{getHighlightTypo({
														highlightText: highlight,
														text: typeof task === 'object' ? task.content : task,
														sx: {
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#4D5256',
														},
													})}
													{task.timestamp && (
														<IconButton
															className='icon-button'
															sx={{ width: '35px', opacity: 0 }}
															onClick={() =>
																transcriptionRef.current.onSeekTime(
																	convertTimeStampToSeconds(task.timestamp)
																)
															}
														>
															<PlayIcon />
														</IconButton>
													)}
												</Stack>
											))}
									</Stack>
								</Paper>
							))}

							{/* <Popover
								open={anchorEl && anchorEl.className.includes('function-button')}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
								anchorOrigin={{
									vertical: 30,
									horizontal: 0,
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 125,
								}}
							>
								<ContextMenu
									isBookmark={onCheckBookmarkSummaryTime(selectedSummaryTime)}
									onToggleBookmark={onToggleBookmarkSummaryTime}
									onCopyClipboard={() => onCopyClipboard(anchorEl.parentNode.parentNode.innerText)}
									onPasteNote={onPasteNoteSummaryTime}
									onClose={() => setAnchorEl(null)}
								/>
							</Popover> */}
						</Stack>
					);
				}

				default:
					return value.map(text =>
						getHighlightTypo({
							highlightText: highlight,
							text: value.length > 1 ? `ㆍ${text}` : text,
							sx: {
								fontSize: 18,
								fontWeight: 500,
								color: '#4D5256',
								fontFamily: 'Noto Sans KR',
								lineHeight: '30px',
							},
						})
					);
			}
		}
	};

	return (
		<>
			{content && (
				<HeaderV2
					text={content && content.meta ? content.meta.editedTitle ?? content.meta.title : ''}
					description={
						content && content.file
							? `생성일 ${moment(content.file.createAt)
									.locale('ko')
									.format('YY.MM.DD LT')} |  최종 수정일 ${moment(content.file.updateAt)
									.locale('ko')
									.format('YY.MM.DD LT')}  |  ${content.meta.lastUpdator}`
							: ''
					}
					icon={drawContentTypeV2(auth, content?.meta, 48, true, false)}
					enableEdit
					originTitle={content.meta.title}
					onChangeTitle={(text, onSuccess, onError) =>
						updateContentTitle(
							cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
							content.meta.contentId,
							text
						)
							.then(() => {
								setContent({ ...content, meta: { ...content.meta, title: text } });

								onSuccess && onSuccess();
							})
							.catch(() => {
								onError && onError();
							})
					}
					buttonComponents={
						<>
							{/* <Popup
								trigger={<TimbloButton label={'재요약'} alias='refresh' />}
								position={'bottom right'}
								arrow={false}
							>
								{close => (
									<ReSummary
										onSummary={size => {
											onResummaryContent(
												cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
												content.meta.contentId,
												size,
												() => ToastInfo('회의록 재요약을 진행합니다.'),
												err => ToastError(err.httpCode, '재요약 요청 중 장애가 발생했습니다.')
											);

											close();
										}}
									/>
								)}
							</Popup> */}

							<ModalConfirm
								title='재요약을 진행하시겠습니까?'
								description='재요약 시 기존 요약 내용이 수정될 수 있습니다.'
								onConfirm={() => {
									if (content.meta.permission === 'OWNER') {
										onResummaryContent(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.meta.contentId,
											'small',
											() => ToastInfo('회의록 재요약을 진행합니다.'),
											err => ToastError(err.httpCode, '재요약 요청 중 장애가 발생했습니다.')
										);
									} else {
										ToastError(null, '권한이 없습니다.');
									}
								}}
							>
								<TimbloButton label={'재요약'} alias='refresh' />
							</ModalConfirm>

							{/* <ShareButton content={content.meta} /> */}
							<Popup
								trigger={<TimbloButton label={'공유'} alias='share' />}
								position={'bottom right'}
								arrow={false}
								nested
								modal
							>
								<Share contentId={content.meta.contentId} permission={content.meta.permission} />
							</Popup>
							<Popup
								trigger={<TimbloButton label={'다운로드'} alias='download' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								<Download content={content} />
							</Popup>

							<ModalConfirm
								title='회의록을 삭제할까요?'
								description='회의록이 휴지통으로 이동합니다.'
								onConfirm={() => {
									if (content.meta.permission === 'OWNER') {
										deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
											contentId: content.meta.contentId,
										})
											.then(() => {
												ToastInfo('회의록이 삭제되었습니다.', '삭제 완료');

												navigate(getSafeUrl(`/contents`), { replace: true });
											})
											.catch(() => {
												ToastError(null, '권한이 없습니다.');
											});
									} else {
										ToastError(null, '권한이 없습니다.');
									}
								}}
							>
								<TimbloButton label={'삭제'} alias='delete' />
							</ModalConfirm>
						</>
					}
				/>
			)}
			<Stack
				direction={'row'}
				sx={{
					width: '100%',
					height: '100%',
					borderTop: `1px solid #ced3d6`,
				}}
			>
				{isLoading ? (
					<Box
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<PageLoading />
					</Box>
				) : (
					<>
						{status.code === 200 ? (
							<>
								<Stack
									sx={theme => ({
										flex: 0.6,
										minWidth: '500px',
										borderRight: `1px solid #CED3D6`,
									})}
								>
									<Box
										sx={theme => ({
											borderBottom: `1px solid #CED3D6`,
											px: 4,
											position: 'relative',
										})}
									>
										<Tabs
											value={currentTab.label}
											onChange={handleTabChange}
											TabIndicatorProps={{
												sx: theme => ({
													height: '4px',
												}),
											}}
											sx={{
												'& .MuiTabs-indicator': {
													backgroundColor: '#2A53FF',
												},
											}}
										>
											{TABS.map((tab, idx) => (
												<Tab
													key={idx}
													value={tab.label}
													label={tab.label}
													disableRipple
													sx={theme => ({
														minWidth: 'auto',
														p: '16px 24px',
														fontSize: 20,
														fontWeight: 600,
														fontFamily: 'Noto Sans KR',

														'&.Mui-selected': {
															color: '#2A53FF',
														},
													})}
												/>
											))}
										</Tabs>
										<Chatbot content={content} />
									</Box>

									<Box
										sx={{
											flex: 1,
											overflow: 'auto',
											padding: `16px 32px`,
											boxSizing: 'border-box',
										}}
									>
										{currentTab.value === 'summary' && (
											<Box
												sx={{
													width: '100%',
													flexShrink: 0,
													padding: '0px 16px 24px 16px',
													display: 'inline-flex',
													flexDirection: 'column',
													borderBottom: `0.6px solid #E1E4E6`,
													boxSizing: 'border-box',
													justifyContent: 'center',
												}}
											>
												<Stack spacing={1.5} direction={'row'}>
													<Typography
														sx={{
															fontSize: 20,
															fontWeight: 600,
															fontFamily: 'Noto Sans KR',
															color: '#292A2B',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														회의 시간
													</Typography>
													{meetingStartTime && meetingEndTime && (
														<MeetingTime
															startTime={meetingStartTime}
															endTime={meetingEndTime}
															onChangeStartMeetingTime={startTime =>
																setMeetingStartTime(startTime)
															}
															onChangeEndMeetingTime={endTime =>
																setMeetingEndTime(endTime)
															}
															onUpdate={update}
															isEditable={isEditable}
														/>
													)}
												</Stack>
											</Box>
										)}

										{drawLeftView()}
									</Box>
								</Stack>
								<Stack
									spacing={2}
									sx={{
										flex: 0.4,
										minWidth: '40%',
										height: '100%',
										padding: '16px 32px',
										boxSizing: 'border-box',
									}}
								>
									<Stack spacing={2} direction={'row'} sx={{ alignItems: 'center' }}>
										<Typography
											sx={theme => ({
												fontSize: 20,
												fontWeight: 600,
												color: '#28323C',
												fontFamily: 'Noto Sans KR',
											})}
										>
											음성 기록
										</Typography>
										<Stack spacing={1} direction={'row'}>
											{content.meta.isRecord && (
												<SvgIcon inheritViewBox sx={{ width: 32, height: 32 }}>
													<RecordIcon />
												</SvgIcon>
											)}
											<SvgIcon inheritViewBox sx={{ width: 32, height: 32 }}>
												{content.meta.isMobile ? <MobileIcon /> : <DesktopIcon />}
											</SvgIcon>
										</Stack>
									</Stack>

									{content && content.file ? (
										<TranscriptionV2
											ref={transcriptionRef}
											isEditable={isEditable}
											type={content.file.mimeType.split('/')[0]}
											source={makeStreamUrl(content.meta.contentId)}
											contentId={content.meta.contentId}
											data={content.mergedSegments ?? content.segments}
											bookmarks={
												content.bookmarks.filter(
													bookmark => bookmark.key === 'mergedSegments'
												)?.[0]?.data
											}
											speakerInfo={content.speakerInfo}
											onRefresh={() => refreshContent(content.meta.contentId)}
											highlightText={highlight}
										/>
									) : (
										<></>
									)}
								</Stack>
							</>
						) : (
							drawContentStatus()
						)}
					</>
				)}
				<Modal
					disableEnforceFocus
					open={isOpenMeetingTimePopup}
					onClose={() => setIsOpenMeetingTimePopup(false)}
				>
					<Box
						sx={{
							width: 360,
							height: 210,
							borderRadius: '16px',
							padding: '24px',
							background: '#fff',
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}
					>
						<Typography
							sx={{
								fontSize: 20,
								fontWeight: 600,
								fontFamily: 'Noto Sans KR',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							생성일로 회의록이 등록되었습니다.
						</Typography>
						<Typography sx={{ fontSize: 14, fontFamily: 'Noto Sans KR', pb: 1 }}>
							실제 회의 시간으로 시작과 종료시간을 변경해 주세요.
						</Typography>
						<Stack
							spacing={0.5}
							direction={'row'}
							alignItems={'center'}
							justifyContent={'center'}
							sx={{ pb: 2 }}
						>
							<Checkbox
								checked={isCheckPopup}
								icon={<DisableCheckIcon />}
								checkedIcon={<AbleCheckIcon />}
								onChange={event => setIsCheckPopup(event.target.checked)}
								sx={{ width: 20, height: 20, p: 0 }}
							/>
							<Typography sx={{ color: '#878D91', fontFamily: 'Noto Sans KR', fontSize: 14 }}>
								이 팝업을 다시 표시 안함
							</Typography>
						</Stack>

						<Stack spacing={1} direction={'row'}>
							<Button
								sx={{
									width: 152,
									height: 48,
									borderRadius: '4px',
									background: '#E1E4E6',
									color: '#4D5256',
									fontSize: 18,
									fontWeight: 500,
									fontFamily: 'Noto Sans KR',
								}}
								onClick={() => {
									setIsOpenMeetingTimePopup(false);
								}}
							>
								닫기
							</Button>
							<Button
								sx={{
									width: 152,
									height: 48,
									borderRadius: '4px',
									background: '#1C6EFF',
									color: '#fff',
									fontSize: 18,
									fontWeight: 500,
									fontFamily: 'Noto Sans KR',
								}}
								onClick={() => handleCheckPopupConfirm(() => setIsOpenMeetingTimePopup(false))}
							>
								확인
							</Button>
						</Stack>
					</Box>
				</Modal>
			</Stack>
		</>
	);
});

export default ContentV2;

const AddButton = ({ onAdd }) => {
	const containerRef = useRef();
	const inputRef = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [text, setText] = useState('');

	useEffect(() => {
		const handleClickOutside = event => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			inputRef.current.focus();
		} else {
			if (text.trim() !== '') {
				onAdd && onAdd(text);
			}

			setText('');
		}
	}, [isOpen]);

	const onToggleOpen = e => {
		e.stopPropagation();
		setIsOpen(prev => !prev);
	};

	return (
		<Box
			ref={containerRef}
			sx={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				width: 160,
			}}
		>
			<Paper
				sx={{
					display: 'flex',
					alignItems: 'center',
					width: isOpen ? '157px' : '25px',
					height: '40px',
					borderRadius: '20px',
					padding: '0 8px',
					transition: 'width 0.3s ease-in-out, border 0.3s ease-in-out',
					border: isOpen ? '2px solid #5C63D8' : '2px solid transparent',
					position: 'relative',
					boxShadow: 'none',
					background: isOpen ? '#fff' : 'inherit',
				}}
			>
				<InputBase
					inputRef={inputRef}
					value={text}
					onChange={e => {
						setText(e.target.value);
					}}
					inputProps={{ maxLength: 20 }}
					sx={{
						flex: 1,
						ml: isOpen ? 1 : 0,
						opacity: isOpen ? 1 : 0,
						transition: 'all 0.3s ease-in-out',
						paddingRight: '35px',
					}}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							setIsOpen(false);
						} else if (e.key === 'Escape') {
							setText('');
							setIsOpen(false);
						}
					}}
					spellCheck={false}
				/>

				<Box sx={{ position: 'relative' }}>
					<IconButton
						onClick={onToggleOpen}
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-160%, -50%)',
							width: '20px',
							height: '20px',
							// backgroundColor: '#FFFFFF',
							borderRadius: '50%',
							boxShadow: '0 0 4px rgba(0,0,0,0.2)',
							zIndex: 1,
							background: '#fff',
							'&:hover': {
								backgroundColor: '#F0F0F0',
							},
						}}
					>
						<AddIcon sx={{ fontSize: 16, color: '#4D5256', stroke: '#E1E4E6' }} />
					</IconButton>
				</Box>
			</Paper>
		</Box>
	);
};
