import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Share.css';
import Avatar from 'react-avatar';
import Popup from 'reactjs-popup';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as CheckIcon } from './img/check.svg';
import { ReactComponent as LinkIcon } from './img/link.svg';
import { ReactComponent as SendIcon } from './img/send.svg';
import useContentsStore from '../../../store/ContentsStore';
import { ToastError, ToastInfo } from '../../common/toast/Toast';
import { useCookies } from 'react-cookie';
import { getAvatarColor, onCopyText } from '../../../util/Util';
import useMemberStore from '../../../store/MemberStore';
import { Button } from '@mui/material';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { useLocation } from 'react-router-dom';

const ROLES = [
	{ label: '편집자', value: 'EDITOR' },
	{ label: '뷰어', value: 'VIEWER' },
	{ label: '소유자', value: 'OWNER' },
];

const CustomOption = props => {
	return (
		<components.Option {...props}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				<Avatar src={props.data.thumbnailUrl} name={props.data.value} round size={20} />

				{props.data.label}
			</div>
		</components.Option>
	);
};

const Share = ({ contentId, permission, data }) => {
	const location = useLocation();

	const timerRef = useRef();
	const inputRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { getMembersFromKeyword } = useMemberStore();
	const {
		getContents,
		getShareUsers,
		addShareUser,
		updateShareUser,
		deleteShareUser,
		refreshContents,
		applyedFilters,
	} = useContentsStore();
	const [shareUsers, setShareUsers] = useState([]);
	const [keyword, setKeyword] = useState('');

	useEffect(() => {
		if (contentId) {
			refreshList();
		}
	}, [contentId]);

	const refreshList = () => {
		if (contentId) {
			getShareUsers(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId)
				.then(res => {
					if (200 === res.code) {
						setShareUsers(res.data);
					} else {
						ToastError(1012);
					}
				})
				.catch(e => {
					ToastError(1013);
				});
		}
	};

	const onAddEmail = email => {
		if (onCheckEditable()) {
			addShareUser(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, email)
				.then(res => {
					console.log(res.code);

					switch (res.code) {
						case 200:
							setShareUsers([...shareUsers, res.data]);
							setKeyword('');
							break;
						default:
							ToastInfo(res.message);
							setKeyword('');
							break;
					}
				})
				.catch(e => {
					ToastError(1015);
				})
				.finally(() => {
					inputRef.current.clearValue();

					const params = {};

					if (Object.keys(applyedFilters).length) {
						Object.keys(applyedFilters).forEach(key => {
							params[key] =
								typeof applyedFilters[key] === 'object'
									? applyedFilters[key].join(',')
									: applyedFilters[key];
						});
					}

					refreshContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, params);
				});
		} else {
			ToastError(null, '권한이 없습니다.');
		}
	};

	const onUpdateUser = user => {
		if (onCheckEditable()) {
			updateShareUser(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, user)
				.then(res => {
					switch (res.code) {
						case 200:
							refreshList();

							break;
						default:
							ToastInfo(res.message);
							break;
					}
				})
				.catch(e => {
					ToastError(1016);
				});
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const onDeleteUser = user => {
		if (onCheckEditable()) {
			deleteShareUser(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, { contentId, email: user.email })
				.then(res => {
					switch (res.code) {
						case 204:
							refreshList();
							break;
						default:
							ToastInfo(res.message);
							break;
					}
				})
				.catch(e => {
					ToastError(1017);
				});
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const onCopy = () => {
		onCopyText(`${window.location.origin}/content/${contentId}`);
		ToastInfo('복사되었습니다.');
	};
	const onSend = () => {
		ToastInfo('아직 지원하지 않는 기능입니다.');
	};

	const drawShareUser = (user, index) => {
		const getUserRoleText = value => {
			return ROLES.find(role => role.value === value)?.label ?? 'Unknown';
		};

		return (
			<div key={index} className='item'>
				<div className='avatar'>
					<Avatar
						src={user.thumbnailUrl}
						name={user.name}
						round
						size={40}
						maxInitials={1}
						color={getAvatarColor(index).background}
						fgColor={getAvatarColor(index).color}
					/>
				</div>
				<div className='info'>
					<div className='name'>{user.nickName}</div>
					<div className='email'>{user.email}</div>
				</div>

				{user.role === 'OWNER' ? (
					<div className='role'>{getUserRoleText(user.role)}</div>
				) : (
					<Popup
						trigger={
							<div className='role'>
								{getUserRoleText(user.role)}
								<ArrowIcon />
							</div>
						}
						position={'bottom right'}
						// nested
						keepTooltipInside='.main-content-v2'
					>
						{close => (
							<RolePopup
								userRole={user}
								onUpdate={onUpdateUser}
								onDelete={onDeleteUser}
								onClose={close}
							/>
						)}
					</Popup>
				)}
			</div>
		);
	};

	const debouncedPromiseOptions = (inputValue, callback) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(async () => {
			if (inputValue.trim()) {
				if (
					cookies &&
					cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
				) {
					const members = await getMembersFromKeyword(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						inputValue
					);

					const updatedMembers = members.map(member => ({
						...member,
						label: member.nickName,
						value: member.nickName,
					}));

					callback(updatedMembers);
				} else {
					callback([]);
				}
			} else {
				callback([]);
			}
		}, 500);
	};

	const handleCreateOption = email => {
		onAddEmail(email);
		setKeyword('');
	};
	const handleSelectOption = option => {
		if (option && option.email) {
			onAddEmail(option.email);
		}
	};

	const onCheckEditable = () => {
		let isEditable = false;
		if (permission === 'OWNER') {
			isEditable = true;
		} else if (permission === 'EDITOR') {
			isEditable = true;
		}

		return isEditable;
	};

	return (
		<div className='share-content-container'>
			<div className='title'>회의록 공유</div>
			<div className='separator' />
			<AsyncCreatableSelect
				ref={inputRef}
				isDisabled={!onCheckEditable()}
				components={{
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					LoadingIndicator: () => null,
					Option: CustomOption,
				}}
				cacheOptions
				defaultOptions
				loadOptions={debouncedPromiseOptions}
				inputValue={keyword}
				onInputChange={e => setKeyword(e)}
				placeholder='공유할 팀원 이름, 이메일을 입력해 주세요.'
				noOptionsMessage={() => null}
				onCreateOption={handleCreateOption}
				onChange={handleSelectOption}
				formatCreateLabel={inputValue => `"${inputValue}" 추가`}
				styles={{
					container: (baseStyles, state) => ({
						...baseStyles,
						border: '1px solid transparent',
						width: '100%',
					}),
					control: (baseStyles, state) => ({
						...baseStyles,
						border: '1px solid #C5C8CE',
						width: '100%',
						boxShadow: 'none',
						cursor: 'text',
						padding: '5px 10px',
						boxSizing: 'border-box',
					}),
					input: (baseStyles, state) => ({
						...baseStyles,
						margin: 0,
						maxWidth: '140px',
					}),

					valueContainer: baseStyles => ({
						...baseStyles,
						margin: 0,
						padding: 0,
						width: '80px !important',
					}),
					loadingIndicator: baseStyles => ({
						...baseStyles,
						display: 'none',
					}),
					placeholder: baseStyles => ({
						...baseStyles,
						width: '100%',
						fontSize: '14px',
						fontWeight: 500,
					}),
				}}
			/>

			{/* <input 
				type='text'
				value={keyword}
				onChange={e => setKeyword(e.target.value)}
				placeholder='공유할 이메일을 입력해주세요.'
				onKeyDown={handleKeydown}
			/> */}
			<div className='user-list'>
				<div className='title'>공유 사용자</div>

				<div className='items'>
					{shareUsers ? shareUsers.map((item, index) => drawShareUser(item, index)) : <>불러오는중</>}
				</div>
			</div>
			<div className='buttons'>
				<Button
					onClick={() => onCopy()}
					sx={{ color: '#fff', backgroundColor: 'var(--Blue-primary700, #1C6EFF)' }}
				>
					<InsertLinkOutlinedIcon /> 링크 복사
				</Button>
				{/* <button className='send' onClick={() => onSend()}>
					<SendIcon /> 전달
				</button> */}
			</div>
		</div>
	);
};

export default Share;

const RolePopup = ({ userRole, onUpdate, onDelete, onClose }) => {
	return (
		<div className='popup-role-container'>
			{ROLES.map(role =>
				role.value === 'OWNER' ? (
					<></>
				) : (
					<>
						<div
							className={`item ${userRole.role === role.value ? 'selected' : ''}`}
							onClick={() => {
								onUpdate({ ...userRole, role: role.value });
								onClose();
							}}
						>
							{role.label} {userRole.role === role.value ? <CheckIcon /> : <></>}
						</div>
					</>
				)
			)}
			<div className='separator' />
			<div
				className='item'
				onClick={() => {
					onDelete(userRole);
					onClose();
				}}
			>
				사용자 삭제
			</div>
		</div>
	);
};
