import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { getAvatarColor } from '../../util/Util';

import CancelIcon from '@mui/icons-material/Cancel';

const DataChip = ({ data, text, index, onDelete = () => {}, onUpdate = () => {}, type, enableEdit }) => {
	const textRef = useRef(text);
	const chipRef = useRef();

	const [isEdit, setIsEdit] = useState(false);
	const [isHover, setIsHover] = useState(false);

	useEffect(() => {
		if (isEdit) {
			chipRef.current.focus();
		}
	}, [isEdit]);

	const getStyleFromType = () => {
		if (type) {
			switch (type) {
				case 'speakerInfo':
					return {
						background: !isEdit ? getAvatarColor(index).background : '#fff',
						color: !isEdit ? getAvatarColor(index).color : '#000',
						border: !isEdit ? '1px solid transparent' : `1px solid ${getAvatarColor(index).color} `,
					};

				case 'keywords':
					return {
						background: !isEdit ? '#F3F5F7' : '#fff',
						color: !isEdit ? `#417EEA` : '#000',
						border: !isEdit ? `1px solid #ECEDEF` : `1px solid #1C6EFF`,
					};
			}
		}
	};

	const handlePaste = e => {
		e.preventDefault();

		const text = e.clipboardData.getData('text/plain');
		document.execCommand('insertText', false, text.substring(0, 20));
	};

	return (
		<div
			ref={chipRef}
			className={`attendee-chip-container ${type === 'keywords' ? 'keyword' : ''} ${index}`}
			style={getStyleFromType()}
			contentEditable={isEdit}
			onPaste={handlePaste}
			onClick={e => {
				e.stopPropagation();

				if (enableEdit) {
					setIsEdit(true);
				}
			}}
			onKeyDown={e => {
				if (enableEdit) {
					if (e.key === 'Enter') {
						setIsEdit(false);
						onUpdate(e.target.innerText.replace('# ', ''));
					} else if (e.key === 'Escape') {
						setIsEdit(false);
					} else {
						// 현재 텍스트 길이 확인
						const currentText = e.target.innerText;
						// Backspace나 Delete, 방향키가 아닌 경우에만 길이 체크
						if (
							!['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(
								e.key
							) &&
							currentText.length >= 20
						) {
							e.preventDefault();
						}
					}
				}
			}}
			onInput={e => {
				// 20자 제한 처리
				const text = e.target.innerText;
				if (text.length > 20) {
					e.target.innerText = text.slice(0, 20);
					// 커서를 끝으로 이동
					const range = document.createRange();
					const sel = window.getSelection();
					range.setStart(e.target.childNodes[0], 20);
					range.collapse(true);
					sel.removeAllRanges();
					sel.addRange(range);
				}
				textRef.current = e.target.innerText;
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			suppressContentEditableWarning
			spellCheck={false}
			onBlur={e => {
				if (enableEdit) {
					setIsEdit(false);

					if (e.target.innerText !== data) {
						console.log('textRef.current : ', e.target.innerText, data);

						onUpdate(e.target.innerText);
					}
				}
			}}
		>
			{text}
			{/* {textRef.current} */}
			{enableEdit && !isEdit && isHover && (
				<CancelIcon
					onClick={e => {
						e.stopPropagation();

						onDelete();
					}}
					sx={{
						width: 18,
						height: 18,
						position: 'absolute',
						right: 0,
						top: -5,
						zIndex: 9999,
						color: '#A9AFB3',
						cursor: 'pointer',
					}}
				/>
			)}
		</div>
	);
};

export default DataChip;
