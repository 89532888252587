// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attendee-chip-container {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	border-radius: 99px;
	font-size: 18px;
	font-weight: 500;
	position: relative;
	flex-shrink: 0;
}

.attendee-chip-container:focus {
	outline: none;
}

.attendee-chip-container.keyword {
	padding: 8px 12px 8px 25px;
}

.attendee-chip-container.keyword[contentEditable]::before {
	content: '# ';
	position: absolute;
	left: 10px;
	pointer-events: none;
	color: rgb(65, 126, 234);
	font-size: 18px;
	font-weight: 500;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
	background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/ContentV2.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;CACnB,eAAe;CACf,gBAAgB;CAChB,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,UAAU;CACV,oBAAoB;CACpB,wBAAwB;CACxB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".attendee-chip-container {\n\tdisplay: inline-flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 8px 12px;\n\tborder-radius: 99px;\n\tfont-size: 18px;\n\tfont-weight: 500;\n\tposition: relative;\n\tflex-shrink: 0;\n}\n\n.attendee-chip-container:focus {\n\toutline: none;\n}\n\n.attendee-chip-container.keyword {\n\tpadding: 8px 12px 8px 25px;\n}\n\n.attendee-chip-container.keyword[contentEditable]::before {\n\tcontent: '# ';\n\tposition: absolute;\n\tleft: 10px;\n\tpointer-events: none;\n\tcolor: rgb(65, 126, 234);\n\tfont-size: 18px;\n\tfont-weight: 500;\n}\n\n.popup-overlay {\n\tbackground: rgba(0, 0, 0, 0.5);\n}\n\n[data-popup='tooltip'].popup-overlay {\n\tbackground: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
