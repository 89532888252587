import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimbloLogo from './img/sk-poc-logo.png';
import { HyperLink, Input, Label, LoginContainer, LoginHeader, LoginWrapper, Logo, LogoImage } from './LoginComponents';
import TimbloCheckboxItem from '../../components/common/CheckBox';
import ConfirmButton from '../../components/button/ConfirmButton';
import SocialLoginButton from './SocialLoginButton';
import AppleIcon from './img/apple-icon.svg';
import GoogleIcon from './img/google-icon.svg';
import axios from 'axios';
import EmailJoinForm from './join/EmailJoinFormV2';
import ProfileFormV2 from './join/ProfileFormV2';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Rectangle } from '@mui/icons-material';
import EmailJoinFormV2 from './join/EmailJoinFormV2';
import { Position } from 'ckeditor5';
import { getSafeUrl } from '../../util/Util';

const JoinPageV2 = () => {
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const [agreeRequired, setAgreeRequired] = useState(false);
	const [email, setEmail] = useState(urlParams.get('email'));
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [step, setStep] = useState(1); //default : 1
	const [accessToken, setAccessToken] = useState(null);
	const [password, setPassword] = useState('');

	const [terms, setTerms] = useState([
		{
			id: 'a(uuid)',
			required: true,
			label: 'AI회의록 이용약관 동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			description: null,
			checked: false,
		},
		{
			id: 'b(uuid)',
			required: true,
			label: '개인정보 수집 및 이용동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			checked: false,
		},
	]);

	useEffect(() => {
		let uncheckedCnt = 0;
		terms.map(item => {
			if (item.required && !item.checked) {
				uncheckedCnt++;
			}
		});

		setAgreeRequired(!uncheckedCnt);
	}, [terms]);

	useEffect(() => {
		console.log('accessToken:', accessToken);
	}, [accessToken]);

	useEffect(() => {
		// Step이 변경될 때 히스토리 상태를 추가
		if (step > 1) {
			window.history.pushState({ step }, `Step ${step}`, `#step${step}`);
		}

		// 뒤로가기 이벤트 핸들러
		const handlePopState = () => {
			if (step > 1) {
				setStep(prevStep => Math.max(prevStep - 1, 1));
			} else {
				window.history.back(); // 혹은 기본 동작만 유지
			}
		};

		// popstate 이벤트 리스너 등록
		window.addEventListener('popstate', handlePopState);

		return () => {
			// 컴포넌트 언마운트 시 이벤트 리스너 제거
			window.removeEventListener('popstate', handlePopState);
		};
	}, [step, setStep]);

	const drawProgress = () => {
		return (
			<Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
				{[1, 2, 3, 4, 5].map((item, index) => (
					<div
						key={index}
						style={{
							width: '32px',
							height: '4px',
							backgroundColor: item <= step ? '#1C6EFF' : '#E1E4E6',
							border: 'none', // 불필요한 테두리 제거
							padding: '0', // 내부 여백 제거
							margin: '0', // 외부 여백 제거
						}}
					/>
				))}
			</Box>
		);
	};

	const getTitle = () => {
		switch (step) {
			case 1:
				return '이용약관';
			case 2:
				return '아이디 입력';
			case 3:
				return '비밀번호 입력';
			case 4:
				return '인증코드 입력';
			case 5:
				return '프로필 사진 등록 / 닉네임 입력';
			default:
		}
	};

	const drawTitle = () => {
		return (
			<Typography
				sx={{
					fontFamily: 'Noto Sans KR',
					textAlign: 'center',
					color: '#292A2B',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: '26px',
					fontWeight: '700',
					lineHeight: '34px',
					textTransform: 'capitalize',
					margin: '40px',
				}}
			>
				{getTitle()}
			</Typography>
		);
	};

	const drawTerms = () => {
		return (
			<>
				<TimbloCheckboxItem
					label={'약관 전체동의'}
					isMainCheckbox={true}
					onChanged={isChecked => {
						setIsAllChecked(isChecked);
						setTerms(
							terms.map(item => ({
								...item,
								checked: isChecked,
							}))
						);
					}}
					checked={isAllChecked}
					isAll={true}
				/>
				<Stack sx={{ height: '16px' }}></Stack>
				{terms &&
					terms.map((item, index) => {
						return (
							<TimbloCheckboxItem
								key={index}
								{...item}
								onChanged={isChecked => {
									setTerms(
										terms.map(term =>
											term.id === item.id ? { ...term, checked: isChecked } : { ...term }
										)
									);
								}}
							/>
						);
					})}
				<Stack sx={{ height: '24px' }}></Stack>
				<ConfirmButton
					label={'계속하기'}
					isActive={agreeRequired}
					onClick={() => {
						setStep(2);
					}}
				/>
			</>
		);
	};

	const drawSelectJoinType = step => {
		return (
			<>
				{/* <SocialLoginButton icon={GoogleIcon} text='Google로 계속하기' provider='Google' />
				<SocialLoginButton icon={AppleIcon} text='Apple로 계속하기' provider='Apple' /> */}

				{/* 여기 부터 생략 */}
				<EmailJoinFormV2
					email={email}
					setEmail={setEmail}
					accessToken={accessToken}
					setAccessToken={setAccessToken}
					password={password}
					setPassword={setPassword}
					step={step}
					setStep={setStep}
					onComplete={() => {
						navigate(getSafeUrl('/login'));
					}}
				/>
			</>
		);
	};

	const drawProfileForm = () => {
		return (
			<ProfileFormV2
				email={email}
				password={password}
				accessToken={accessToken}
				onComplete={() => {
					navigate(getSafeUrl('/login'));
				}}
			/>
		);
	};

	const drawStep = () => {
		switch (step) {
			case 1:
				return drawTerms();
			case 2:
			case 3:
			case 4:
				return drawSelectJoinType(step);
			case 5:
				return drawProfileForm();
		}
	};

	return (
		<Box>
			<Stack direction='row' alignItems='flex-start'>
				<a href='/login' style={{ display: 'inline-block' }}>
					<LogoImage
						loading='lazy'
						src={TimbloLogo}
						alt='Timblo Logo'
						style={{
							width: '77px',
							height: '44px',
							marginLeft: '48px',
							marginTop: '20px',
							cursor: 'pointer',
						}}
						cursor={'pointer'}
						onClick={() => navigate(getSafeUrl('/'))}
					/>
				</a>
			</Stack>
			<Stack
				sx={{
					marginTop: '140px',
				}}
			>
				<Box
					className='view'
					style={{
						width: '440px',
						height: '800px',
						margin: '0 auto',
						Position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					{drawProgress()}
					{drawTitle()}
					<Box sx={{ width: '320px' }}>{drawStep()}</Box>
				</Box>
			</Stack>
		</Box>
	);
};

export default JoinPageV2;
