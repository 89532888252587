import { Box, Stack, Typography } from '@mui/material';
import Avatar from 'react-avatar';
import { ThreeDots } from 'react-loader-spinner';

import AiBizAvatarImg from '../img/aibizavatar.png'
import ThreeDot from '../img/threedot.gif'

function formatToMeridianTime(date) {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const period = hours < 12 ? '오전' : '오후';
	const hourIn12Format = hours % 12 || 12;
	const formattedMinutes = minutes.toString().padStart(2, '0');
	return `${period} ${hourIn12Format}:${formattedMinutes}`;
}

const Balloon = ({ index, message }) => {
	const drawBalloon = type => {
		switch (type) {
			case 'me':
				return (
					<Stack
						key={`chat-${index}`}
						sx={{
							width: '100%',
							paddingBottom: '16px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
							gap: '8px',
						}}
					>
						<Box
							sx={{
								background: '#1C6EFF',
								borderRadius: '24px 16px 0px 24px',
								display: 'flex',
								padding: '8px 16px',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '8px',
								alignSelf: 'flex-end',
							}}
						>
							<Typography sx={{ color: '#FFFFFF', fontSize: '16px', whiteSpace: 'pre-line' }}>
								{message.text}
							</Typography>
						</Box>
					</Stack>
				);
			case 'bot':
			case 'botWorking':
				return (
					<Stack
						direction={'row'}
						key={`chat-${index}`}
						sx={{
							display: 'flex',
							width: '100%',
							paddingBottom: '16px',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
							gap: '8px',
						}}
					>
						<Box
							sx={{
								width: '32px',
								height: '32px',
								border: '1px solid #CED3D6',
								borderRadius: '50%',
								backgroundColor: '#FFFFFF',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexShrink: 0,
							}}
						>
							<Box
								component='img'
								src={AiBizAvatarImg}
								alt='Avatar'
								sx={{
									width: '18px',
									height: '15px',
									objectFit: 'contain',
								}}
							/>
						</Box>
						<Box
							sx={{
								background: '#F3F5F7',
								borderRadius: '0px 16px 16px 16px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								alignSelf: 'flex-start',
								padding: '8px 16px',
								position: 'relative',
							}}
						>
							{type === 'botWorking' ? (
								<Box
									component='img'
									src={ThreeDot}
									sx={{
										width: '38px',
										height: '23px',
									}}
								/>
							) : (
								<Typography sx={{ color: '#000000', fontSize: '16px', whiteSpace: 'pre-line' }}>
									{message.text}
								</Typography>
							)}
						</Box>
						<Typography
							sx={{
								flexShrink: 0,
								color: '#878D91',
								fontWeight: '400',
								fontSize: '12px',
								lineHeight: '18px',
								display: 'flex',
								alignSelf: 'flex-end',
							}}
						>
							{message.time && formatToMeridianTime(message.time)}
						</Typography>
					</Stack>
				);
			default:
				return <></>;
		}
	};

	return drawBalloon(message.type);
};

export default Balloon;
