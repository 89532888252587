export const convert = (templateData, content) => {
	const templates = templateData.template;
	const basicModel = [
		{
			alias: '{{title}}',
			value: content ? content.meta.title : '{{title}}',
		},
		{
			alias: '{{tasks}}',
			value: content ? content.aiResult.tasks?.join(', ') || '' : '{{tasks}}',
		},
		{
			alias: '{{topics}}',
			value: content ? content.aiResult.topics?.join(', ') || '' : '{{topics}}',
		},
		{
			alias: '{{keywords}}',
			value: content ? content.aiResult.keywords?.join(', ') || '' : '{{keywords}}',
		},
		{
			alias: '{{speakerInfo}}',
			value: content ? content.speakerInfo.map(speaker => speaker.name).join(', ') || '' : '{{speakerInfo}}',
		},
		{
			alias: '{{summary}}',
			value: content ? content.aiResult.summary?.join(', ') || '' : '{{summary}}',
		},
		{
			alias: '{{issues}}',
			value: content ? content.aiResult.issues?.join(', ') || '' : '{{issues}}',
		},
	];

	const loopModel = [
		{ alias: '{{st_topic}}', target: 'topic', isArray: false },
		{ alias: '{{st_summary}}', target: 'summary', isArray: true },
		{ alias: '{{st_issues}}', target: 'issues', isArray: true },
		{ alias: '{{st_tasks}}', target: 'tasks', isArray: true },
	];

	const extractCurlyBracedText = input => {
		const matches = input.match(/{{(.*?)}}/g); // {{ }} 패턴에 매칭되는 모든 문자열 찾기
		return matches ? matches.map(match => match.slice(2, -2)) : []; // 중괄호 제거 후 반환
	};

	const replaceBasicModel = target => {
		let temp = target;
		basicModel.map(model => {
			temp = temp.replace(model.alias, model.value);
		});
		return temp;
	};

	const replaceLoopModel = (target, summary) => {
		try {
			let temp = target;
			loopModel.map(model => {
				// target이 배열일 경우, content 키의 값을 join('\n') 방식으로 결합
				temp = temp.replace(
					model.alias,
					model.isArray
						? summary[model.target].map(item => item.content).join('\n') // 각 항목의 content 값을 join
						: summary[model.target]
				);
				console.log('temp after', temp);
			});
			return temp;
		} catch (e) {
			console.error('Cannot convert data (maybe old data is there)');
			console.error(e);
			return '';
		}
	};

	let result = '';
	templates &&
		templates.map(template => {
			template.items.map(item => {
				if ('basic' === item.type) {
					result += replaceBasicModel(item.value);
				} else if ('loop' === item.type) {
					//실제 컨텐츠로 반복
					if (content) {
						content.summaryTime &&
							content.summaryTime.map((summary, index) => {
								result += replaceLoopModel(item.value, summary);
								//add new line
								result += '\n';
							});
					} else {
						//컨텐츠가 없을떄는 미리보기로 처리해야한다.
						//일단은 나중에...
						//TODO
						result += '\n';
					}
				}
				//add new line
				result += '\n';
			});
		});
	return result;
};
