import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useCookies } from 'react-cookie';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useContentsStore from '../../../store/ContentsStore';

const TruncateButton = ({
	size = 40,
	confirmText = '확인',
	cancelText = '취소',
	contentId,
	onConfirm,
	onCancel,
	onError,
}) => {
	const [open, setOpen] = React.useState(false);
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { truncateContents } = useContentsStore();

	const handleOpen = e => {
		e.stopPropagation();
		setOpen(true);
	};

	const handleCancel = () => {
		onCancel && onCancel();
		setOpen(false);
	};

	const handleConfirm = () => {
		truncateContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId)
			.then(() => {
				onConfirm && onConfirm();
				setOpen(false);
			})
			.catch(code => {
				onError && onError(code);
				setOpen(false);
			});
	};

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					minWidth: `${size}px`,
					width: `${size}px`,
					height: `${size}px`,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: '1px solid #E1E4E6',
					borderRadius: '8px',
					padding: 0,
				}}
			>
				<DeleteOutlineOutlinedIcon />
			</IconButton>
			<Dialog open={open} onClose={handleCancel} sx={{ borderRadius: '16px' }}>
				<DialogContent sx={{ padding: '24px 44px' }}>
					<DialogTitle
						sx={{
							fontSize: '20px',
							fontWeight: 600,
							textAlign: 'center',
							color: 'var(--Dark-grey700, #292A2B)',
							paddingBottom: '4px',
							width: '100%',
						}}
					>
						영구삭제 할까요?
					</DialogTitle>
					<DialogContentText
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							textAlign: 'center',
							color: 'var(--Dark-grey400, #878D91)',
							marginLeft: '32px',
							marginRight: '32px',
							marginBottom: '16px',
						}}
					>
						회의록이 영구적으로 삭제되어 복구할 수 없습니다.
					</DialogContentText>
					<DialogActions sx={{ justifyContent: 'center', marginBottom: '12px' }}>
						<Button
							sx={{
								background: 'var(--Light-light300, #E1E4E6)',
								color: 'var(--Dark-grey700, #292A2B)',
								width: '100px',
								height: '48px',
							}}
							onClick={handleCancel}
						>
							{cancelText}
						</Button>
						<Button
							sx={{
								background: 'var(--Blue-primary700, #1C6EFF)',
								color: 'white',
								width: '100px',
								height: '48px',
							}}
							onClick={handleConfirm}
							autoFocus
						>
							{confirmText}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default TruncateButton;
