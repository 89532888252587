import { Stack, Typography, List, ListItem } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../img/arrowUp.svg';
import { ReactComponent as ArrowUpIcon } from '../img/arrowDown.svg';
import { ReactComponent as ComboCheckIcon } from '../img/combocheck.svg';
import { useEffect, useRef, useState } from 'react';

const CollectSelectBox = ({ defaultOption, options, onSelectedOptions, displayKey }) => {
	const [openCombo, setOpenCombo] = useState(false);
	const [allCheckStatus, setAllCheckStatus] = useState(true);
	const [selectedOptions, setSelectedOptions] = useState([]);

	const optionsRef = useRef([]);

	useEffect(() => {
		if (optionsRef.current.length) {
			optionsRef.current = options.map((option, idx) => ({
				option,
				checked: optionsRef.current[idx]?.checked ?? true,
			}));
		} else {
			optionsRef.current = options.map(option => ({ option, checked: true }));
		}
	}, [options]);

	const comboBoxRef = useRef(null);

	const handleOpenComboClick = () => {
		setOpenCombo(prev => !prev);
	};

	const handleDefaultOptionClick = () => {
		const isAllSelected = !allCheckStatus;
		setAllCheckStatus(isAllSelected);

		const updateOptions = optionsRef.current.map(opt => ({
			...opt,
			checked: isAllSelected,
		}));

		optionsRef.current = updateOptions;

		const selected = isAllSelected ? options : [];
		setSelectedOptions(selected);
		onSelectedOptions(selected);
	};

	const handleOptionClick = clickedOption => {
		const updateOptions = optionsRef.current.map(opt =>
			opt.option === clickedOption ? { ...opt, checked: !opt.checked } : opt
		);

		const isAllSelected = updateOptions.every(opt => opt.checked);
		setAllCheckStatus(isAllSelected);

		optionsRef.current = updateOptions;

		const selected = updateOptions.filter(opt => opt.checked).map(opt => opt.option);
		setSelectedOptions(selected);
		onSelectedOptions(selected); // 이제 전체 객체가 전달됩니다
	};

	const getDisplayText = option => {
		return typeof displayKey === 'function' ? displayKey(option) : option;
	};

	const getComboText = () => {
		if (allCheckStatus) {
			return defaultOption;
		} else if (selectedOptions.length > 1) {
			return `${getDisplayText(selectedOptions[0])} 외 ${selectedOptions.length - 1}`;
		} else if (selectedOptions.length === 1) {
			return `${getDisplayText(selectedOptions[0])}`;
		} else {
			return '선택 없음';
		}
	};

	useEffect(() => {
		const handleClickOutside = event => {
			if (comboBoxRef.current && !comboBoxRef.current.contains(event.target)) {
				setOpenCombo(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<Stack ref={comboBoxRef} sx={{ position: 'relative', minWidth: 170 }}>
			<Stack
				spacing={1}
				direction={'row'}
				sx={theme => ({
					px: 1.25,
					py: 1,

					backgroundColor: openCombo ? theme.palette.primary[0] : theme.palette.light.white,
					border: openCombo
						? `1px solid ${theme.palette.primary[700]}`
						: `1px solid ${theme.palette.light[400]}`,
					borderRadius: 1,
					boxSizing: 'border-box',
					height: 40,
					justifyContent: 'space-between',
				})}
				onClick={handleOpenComboClick}
			>
				<Typography
					sx={theme => ({
						...theme.typography.title7,
						color: openCombo ? theme.palette.primary[700] : theme.palette.grey[700],
						pointerEvents: 'none',
					})}
				>
					{getComboText()}
				</Typography>
				{openCombo ? <ArrowUpIcon cursor={'pointer'} /> : <ArrowDownIcon cursor={'pointer'} />}
			</Stack>

			{openCombo && (
				<List
					sx={theme => ({
						position: 'absolute',
						top: 40,
						left: 0,
						zIndex: 10,
						width: '100%',
						maxHeight: 'calc(44px * 5 + 18px)',
						overflowY: 'auto',

						border: `1px solid ${theme.palette.light[400]}`,
						borderRadius: 1,
						py: 1,
						backgroundColor: theme.palette.light.white,
						opacity: 1,
						boxShadow: '2px 2px 8px 0px hsla(0, 0%, 0%, 0.08)',
					})}
				>
					<ListItem
						onClick={handleDefaultOptionClick}
						sx={theme => ({
							display: 'flex',
							justifyContent: 'space-between',
							p: 1,
							minHeight: 44,
							maxHeight: 44,
							':hover': {
								backgroundColor: theme.palette.light[200],
							},
						})}
					>
						<Typography sx={theme => ({ ...theme.typography.body5, color: theme.palette.grey[700] })}>
							{defaultOption}
						</Typography>
						{allCheckStatus && <ComboCheckIcon />}
					</ListItem>

					{optionsRef.current.map((opt, index) => (
						<ListItem
							key={index}
							onClick={() => handleOptionClick(opt.option)}
							sx={theme => ({
								display: 'flex',
								justifyContent: 'space-between',
								p: 1,
								minHeight: 44,
								maxHeight: 44,
								':hover': {
									backgroundColor: theme.palette.light[200],
								},
							})}
						>
							<Typography sx={theme => ({ ...theme.typography.body5, color: theme.palette.grey[700] })}>
								{getDisplayText(opt.option)}
							</Typography>
							{opt.checked && <ComboCheckIcon />}
						</ListItem>
					))}
				</List>
			)}
		</Stack>
	);
};

export default CollectSelectBox;
