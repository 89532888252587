import React, { useEffect } from 'react';

import { ReactComponent as NoDataImg } from './img/bookmark_no_data.svg';
import { Avatar, Box, IconButton, Paper, Stack, SvgIcon, Typography } from '@mui/material';
import BookmarkItem from './BookmarkItem';
import DataChip from '../DataChip';
import { getAvatarColor, getBlockInfo, getBlockTitle } from '../../../util/Util';

const BookmarkList = ({ contentId, data = [], refreshContent }) => {
	const drawBookmarkItem = bookmark => {
		const key = bookmark['key'];
		const value = bookmark['data'];

		switch (key) {
			case 'speakerInfo': {
				return (
					<Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1.5 }}>
						{value.map((item, index) => (
							<DataChip text={item.displayName ?? item.name} index={index} type={key} />
						))}
					</Stack>
				);
			}

			case 'keywords': {
				return (
					<Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1.5 }}>
						{value.map((item, index) => (
							<DataChip text={`${item}`} index={index} type={key} />
						))}
					</Stack>
				);
			}

			case 'segments':
			case 'mergedSegments': {
				const thumbnail = null;

				const convertTimeFormat = milliseconds => {
					let seconds = Math.floor(milliseconds / 1000);
					let minutes = Math.floor(seconds / 60);
					let hours = Math.floor(minutes / 60);

					seconds = seconds % 60;
					minutes = minutes % 60;

					const pad = num => num.toString().padStart(2, '0');

					return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
				};

				return value.map(segment => (
					<Stack
						spacing={1.25}
						direction={'row'}
						className={`stt-row-container`}
						sx={{
							borderRadius: 1,
						}}
					>
						<Avatar
							src={thumbnail || null}
							sx={theme => ({
								width: 32,
								height: 32,
								bgcolor: thumbnail ? 'transparent' : getAvatarColor(segment.speakerId).background,
								color: thumbnail ? 'inherit' : getAvatarColor(segment.speakerId).color,
								fontSize: 16,
								fontWeight: 700,
								fontFamily: 'Noto Sans KR',
							})}
						>
							{segment.name.substring(0, 1)}
						</Avatar>
						<Stack spacing={1} sx={{ flex: 1 }}>
							<Stack spacing={1} direction={'row'} alignItems={'center'} sx={{ height: 24 }}>
								<Typography
									sx={{ color: '#28323C', fontSize: 18, fontWeight: 600, fontFamily: 'Noto Sans KR' }}
								>
									{segment.name}
								</Typography>

								<Typography
									sx={{
										color: '#878D91',
										fontSize: 16,
										fontWeight: 400,
										fontFamily: 'Noto Sans KR',
										cursor: 'pointer',
									}}
								>
									{convertTimeFormat(segment.startTime)}
								</Typography>
							</Stack>

							<Box className='stt-result' suppressContentEditableWarning spellCheck={false}>
								{segment.text}
							</Box>
						</Stack>
					</Stack>
				));
			}

			case 'summaryTime': {
				return (
					<Stack direction={'column'} spacing={4}>
						{value.map((summaryTime, index) => (
							<Paper
								sx={{
									boxShadow: 'none',
									background: 'inherit',
								}}
							>
								<Stack spacing={1}>
									<Typography
										sx={{
											fontSize: 18,
											fontWeight: 600,
											color: '#4D5256',
											fontFamily: 'Noto Sans KR',
											display: 'flex',
										}}
									>
										{summaryTime.index}. {summaryTime.topic}
									</Typography>

									{summaryTime.summary.map(summary => (
										<Typography
											sx={{
												fontSize: 18,
												fontWeight: 500,
												fontFamily: 'Noto Sans KR',
												color: '#4D5256',
											}}
										>
											ㆍ{typeof summary === 'object' ? summary.content : summary}
										</Typography>
									))}

									{summaryTime.issues &&
										summaryTime.issues.map(issue => (
											<Stack spacing={1} direction='row' alignItems={'center'}>
												<Box
													sx={{
														fontSize: 18,
														fontWeight: 500,
														fontFamily: 'Noto Sans KR',
														color: '#fff',
														background: '#35B54F',
														padding: `2px 8px`,
														borderRadius: '8px',
														marginLeft: '10px !important',
														flexShrink: 0,
													}}
												>
													이슈
												</Box>
												<Typography
													sx={{
														fontSize: 18,
														fontWeight: 500,
														fontFamily: 'Noto Sans KR',
														color: '#4D5256',
													}}
												>
													{typeof issue === 'object' ? issue.content : issue}
												</Typography>
											</Stack>
										))}

									{summaryTime.tasks &&
										summaryTime.tasks.map(task => (
											<Stack spacing={1} direction='row' alignItems={'center'}>
												<Box
													sx={{
														fontSize: 18,
														fontWeight: 500,
														fontFamily: 'Noto Sans KR',
														color: '#fff',
														background: '#FF7E29',
														padding: `2px 8px`,
														borderRadius: '8px',
														marginLeft: '10px !important',
														flexShrink: 0,
													}}
												>
													할일
												</Box>
												<Typography
													sx={{
														fontSize: 18,
														fontWeight: 500,
														fontFamily: 'Noto Sans KR',
														color: '#4D5256',
													}}
												>
													{typeof task === 'object' ? task.content : task}
												</Typography>
											</Stack>
										))}
								</Stack>
							</Paper>
						))}
					</Stack>
				);
			}

			default:
				return value.map(text => (
					<Typography sx={{ fontSize: 18, fontWeight: 500, color: '#4D5256', fontFamily: 'Noto Sans KR' }}>
						{text}
					</Typography>
				));
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: data.length ? 'flex-start' : 'center',
				alignItems: 'center',
				gap: '24px',
				overflow: 'auto',
				paddingTop: '32px',
				boxSizing: 'border-box',
			}}
		>
			{data && data.length ? (
				data.map(item => (
					<BookmarkItem
						title={getBlockTitle(item.key)}
						info={getBlockInfo(item.key)}
						data={item}
						contentId={contentId}
						refreshContent={refreshContent}
					>
						{drawBookmarkItem(item)}
					</BookmarkItem>
				))
			) : (
				<Stack spacing={1} justifyContent={'center'} alignItems={'center'}>
					<SvgIcon inheritViewBox sx={{ width: 96, height: 96 }}>
						<NoDataImg />
					</SvgIcon>
					<Typography
						sx={{
							fontSize: 24,
							fontWeight: 700,
							fontFamily: 'Noto Sans KR',
							color: '#28323C',
							marginTop: '24px !important',
						}}
					>
						북마크가 없습니다.
					</Typography>
					<Typography sx={{ fontSize: 18, fontWeight: 400, fontFamily: 'Noto Sans KR', color: '#858C99' }}>
						다시 보고 싶은 부분이 있다면, <br />
						북마크 기능을 활용해 보세요.
					</Typography>
				</Stack>
			)}
		</Box>
	);
};

export default BookmarkList;
