import styled from 'styled-components';

export const LoginContainer = styled.main`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--white, #fff);
	/* padding: 180px 80px 278px; */
	overflow: hidden;

	@media (max-width: 991px) {
		padding: 100px 20px;
	}
`;

export const LoginBorder = styled.section`
	display: flex;
	height: 616px;
	padding: 24px 104px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 80px;
	border-radius: 16px;
	border: 1px solid var(--Light-light300, #e1e4e6);
	box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.02);
`;

export const LoginWrapper = styled.section`
	display: flex;
	width: 320px;
	max-width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	gap: 12px;
`;

export const Logo = styled.div`
	align-self: center;
	display: flex;
	width: 50%;
`;

export const LogoImage = styled.img`
	/* aspect-ratio: 3.85; */
	object-fit: contain;
	object-position: center;
	width: 100%;
`;

export const LoginHeader = styled.h1`
	margin-top: 20px;
	color: var(--grey-grey700, #28323c);
	text-align: center;
	font: 700 24px/1 Noto Sans, sans-serif;
	padding: 10px 0;
`;

export const Divider = styled.hr`
	background-color: #c5c8ce;
	height: 1px;
	margin: 10px 0;
	border: none;
`;

export const CheckBox = styled.input`
	&:type(checkbox) {
	}
`;

export const Label = styled.label`
	font-size: 14px;
	line-height: 1;
	margin-bottom: 10px;
	color: ${({ color }) => color};
`;

export const Input = styled.input`
	border-radius: 5px;
	font-size: 16px;
	line-height: 1;
	padding: 10px;
	box-sizing: border-box;
	border: 1px solid var(--grey-grey400, #858c99);
	width: 100%;
`;

export const HyperLink = styled.label`
	color: var(--primary-primary800, #005cff);
	/* Body/Body5_14_regular */
	font-family: 'Noto Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	text-decoration-line: underline;
	cursor: pointer;
`;

export const ProfileUpload = styled.div`
	display: flex;
	margin-top: 20px;
	flex-direction: column;
	align-items: center;
	color: #28323c;
	text-align: center;
	font: 400 14px/1 Roboto, sans-serif;
`;

export const ProfileImage = styled.img`
	width: 120px;
	aspect-ratio: 1;
	object-fit: contain;
`;
