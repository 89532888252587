import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Popup from 'reactjs-popup';
import Avatar from '@mui/material/Avatar';
import {
	Box,
	Button,
	ButtonGroup,
	MenuItem,
	Menu,
	IconButton,
	Paper,
	Popover,
	Stack,
	SvgIcon,
	Tooltip,
	Typography,
} from '@mui/material';

import { getAvatarColor } from '../../../../util/Util';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../../../store/AuthStore';
import SpeakerEdit from '../../../popup/content/speakerEdit/SpeakerEdit';
import { getHighlightText } from '../../common/HighlightText';
import { ReactComponent as ReplaceWordIcon } from '../img/replace.svg';
import { ReactComponent as SeekIcon } from '../img/seek.svg';
import { ReactComponent as SeekPlayIcon } from '../img/seekPlay.svg';
import { ReactComponent as SeekPauseIcon } from '../img/seekPause.svg';

const SEEK_TIME = 5;

const EditableRow = ({
	contentId,
	index,
	data,
	onChangeText,
	speakerInfo,
	searchResult,
	matchIndices,
	highlightedData,
	keyword,
	onRefresh,
	playing,
	getCurrentPlayTime = () => {},
	onPasteKeyword = () => {},
	onSeekTime = () => {},
	onTogglePlay = () => {},
}) => {
	const boxRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { auth } = useAuthStore();
	const [thumbnail, setThumbnail] = useState(null);
	const [menuPosition, setMenuPosition] = useState(null);
	const [timer, setTimer] = useState(null); // 타이머 상태

	const [selectionPopup, setSelectionPopup] = useState({
		open: false,
		position: { top: 0, left: 0 },
		selectedText: '',
	});

	const handleMouseUp = event => {
		const selection = window.getSelection();
		if (selection && selection.toString()) {
			// 드래그된 텍스트가 있을 경우, 0.5초 뒤에 메뉴 표시
			if (timer) {
				clearTimeout(timer); // 이전 타이머 취소
			}

			const newTimer = setTimeout(() => {
				const { clientX, clientY } = event;
				setMenuPosition({ x: clientX, y: clientY }); // 드래그 끝난 위치에 메뉴 표시
			}, 200); // 0.5초 지연 후 메뉴 표시

			setTimer(newTimer); // 타이머 저장
		}
	};

	const handleCloseMenu = () => {
		setMenuPosition(null); // 메뉴 닫기
	};

	const handleMenuAction = action => {
		const selection = window.getSelection();
		const selectedText = selection ? selection.toString() : '';

		onPasteKeyword(selectedText);

		handleCloseMenu();
	};

	useLayoutEffect(() => {
		if (data && data.speakerId) {
			setThumbnail(getThumbnail(data.speakerId));
		}
	}, []);

	const getThumbnail = speakerId => {
		const speaker = speakerInfo && speakerInfo.find(item => item.speakerId === speakerId);
		if (speaker && speaker.thumbnailUrl) {
			if (auth && auth.user && auth.user.profile && auth.user.profile.email === speaker.email) {
				return auth.user.profile.thumbnailUrl;
			} else {
				return speaker.thumbnailUrl;
			}
		} else {
			return null;
		}
	};

	const getSpeakerName = speakerId => {
		if (speakerInfo) {
			const target = speakerInfo.find(speaker => speaker.speakerId === speakerId);

			if (target) {
				return target.displayName ?? target.name;
			}
		} else {
			return 'Unknown';
		}
	};

	const convertTimeFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		const pad = num => num.toString().padStart(2, '0');

		return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
	};

	const handlePaste = e => {
		e.preventDefault();

		const text = e.clipboardData.getData('text/plain');
		document.execCommand('insertText', false, text);
	};

	const generateHighlightedHTML = (data, index) => {
		return data
			.map((part, partIndex) => {
				if (!part) return '';
				const isCurrentMatch =
					matchIndices[searchResult.currentIndex] &&
					matchIndices[searchResult.currentIndex].dataIndex === index &&
					matchIndices[searchResult.currentIndex].partIndex === partIndex;

				const isMatch = keyword && part.toLowerCase() === keyword.toLowerCase();

				return `<span style="background-color: ${
					isMatch ? (isCurrentMatch ? 'orange' : 'yellow') : 'inherit'
				};">${part}</span>`;
			})
			.join('');
	};

	return (
		<Stack
			spacing={1.25}
			direction={'row'}
			className={`stt-row-container ${index}`}
			sx={{
				borderRadius: 1,
				marginTop: index === 0 ? '15px' : 0,

				'&:hover .seek-buttons': {
					opacity: 1,
					visibility: 'visible',
				},
			}}
		>
			<Avatar
				src={thumbnail || null}
				sx={theme => ({
					width: 32,
					height: 32,
					bgcolor: thumbnail ? 'transparent' : getAvatarColor(data.speakerId).background,
					color: thumbnail ? 'inherit' : getAvatarColor(data.speakerId).color,
					fontSize: 16,
					fontWeight: 700,
					fontFamily: 'Noto Sans KR',
				})}
			>
				{!thumbnail && getSpeakerName(data.speakerId).substring(0, 1)}
			</Avatar>
			{/* <Popup
				trigger={
					<Avatar
						src={thumbnail || null}
						sx={theme => ({
							width: 32,
							height: 32,
							bgcolor: thumbnail ? 'transparent' : getAvatarColor(data.speakerId).background,
							color: thumbnail ? 'inherit' : getAvatarColor(data.speakerId).color,
							fontSize: 16,
							fontWeight: 700,
							fontFamily: 'Noto Sans KR',
							cursor: 'pointer',
						})}
					>
						{!thumbnail && getSpeakerName(data.speakerId).substring(0, 1)}
					</Avatar>
				}
				keepTooltipInside='.main-content-v2'
			>
				{close => (
					<SpeakerEdit
						contentId={contentId}
						segment={data}
						onChangeSegmentSpeaker={onRefresh}
						speakerId={data.speakerId}
						speakerList={speakerInfo}
						onClose={close}
					/>
				)}
			</Popup> */}
			<Stack spacing={1} sx={{ flex: 1 }}>
				<Stack spacing={1} direction={'row'} alignItems={'center'} sx={{ height: 24, position: 'relative' }}>
					<Typography
						sx={{
							color: '#28323C',
							fontSize: 18,
							fontWeight: 600,
							fontFamily: 'Noto Sans KR',
						}}
					>
						{getSpeakerName(data.speakerId)}
					</Typography>
					{/* <Popup
						trigger={
							<Typography
								sx={{
									color: '#28323C',
									fontSize: 18,
									fontWeight: 600,
									fontFamily: 'Noto Sans KR',
									cursor: 'pointer',
								}}
							>
								{getSpeakerName(data.speakerId)}
							</Typography>
						}
						keepTooltipInside='.main-content-v2'
					>
						{close => (
							<SpeakerEdit
								contentId={contentId}
								segment={data}
								onChangeSegmentSpeaker={onRefresh}
								speakerId={data.speakerId}
								speakerList={speakerInfo}
								onClose={close}
							/>
						)}
					</Popup> */}

					<Typography
						sx={{
							color: '#878D91',
							fontSize: 16,
							fontWeight: 400,
							fontFamily: 'Noto Sans KR',
							cursor: 'pointer',
						}}
						onClick={() => onSeekTime(data.startTime / 1000)}
					>
						{convertTimeFormat(data.startTime)}
					</Typography>

					<ButtonGroup
						disableRipple
						className='seek-buttons'
						variant='outlined'
						size='small'
						sx={{
							opacity: 0,
							visibility: 'hidden',
							transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
							'& .MuiButton-root:first-of-type': {
								borderTopLeftRadius: '10px',
								borderBottomLeftRadius: '10px',
							},
							'& .MuiButton-root:last-of-type': {
								borderTopRightRadius: '10px',
								borderBottomRightRadius: '10px',
							},
						}}
					>
						<Button
							sx={{
								width: 32,
								height: 24,
								padding: 0,
								borderRadius: `2px`,
								minWidth: `0 !important`,
								borderColor: '#CED3D6',
								position: 'relative',
							}}
							onClick={() => onSeekTime(getCurrentPlayTime() - SEEK_TIME)}
						>
							<SeekIcon />
							<Typography sx={{ fontSize: 13, fontWeight: 600, color: '#374553', position: 'absolute' }}>
								{SEEK_TIME}
							</Typography>
						</Button>
						<Button
							sx={{ width: 32, height: 24, padding: 0, minWidth: `0 !important`, borderColor: '#CED3D6' }}
							onClick={() => {
								if (playing) {
									onTogglePlay();
								} else {
									onSeekTime(data.startTime / 1000);
								}
							}}
						>
							{playing ? <SeekPauseIcon /> : <SeekPlayIcon />}
						</Button>
						<Button
							sx={{
								width: 32,
								height: 24,
								padding: 0,
								borderRadius: `2px`,
								minWidth: `0 !important`,
								borderColor: '#CED3D6',
								position: 'relative',
							}}
							onClick={() => onSeekTime(getCurrentPlayTime() + SEEK_TIME)}
						>
							<SeekIcon style={{ transform: `scaleX(-1)` }} />
							<Typography sx={{ fontSize: 13, fontWeight: 600, color: '#374553', position: 'absolute' }}>
								{SEEK_TIME}
							</Typography>
						</Button>
					</ButtonGroup>
				</Stack>

				<Box
					ref={boxRef}
					className='stt-result'
					suppressContentEditableWarning
					dangerouslySetInnerHTML={{
						__html: generateHighlightedHTML(highlightedData[index], index),
					}}
					spellCheck={false}
					id={data.segmentId}
					start-time={data.startTime}
					contentEditable
					onPaste={handlePaste}
					onMouseUp={handleMouseUp}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							e.preventDefault();
							e.target.blur();
						}
					}}
					onBlur={e => {
						const selection = window.getSelection();

						if (selection && selection.toString()) {
						} else {
							try {
								const newText = e.target.innerText || e.target.textContent;
								if (newText !== data.text) {
									onChangeText(newText);
								}
							} catch (error) {
								console.error('Error in handleBlur:', error);
								if (boxRef.current) {
									boxRef.current.innerText = data.text;
								}
							}
						}
					}}
					sx={{
						outline: 'none',
						userSelect: 'text',
						whiteSpace: 'pre-wrap',
						color: '#4D5256',
						fontWeight: 500,
						fontSize: 16,
					}}
				>
					{/* {!isEditing && keyword && highlightedData && highlightedData[index] ? (
						<span style={{ color: '#4D5256', fontWeight: 500, fontSize: 16 }}>
							{highlightedData[index].map((part, partIndex) => {
								if (!part) return null;
								const isCurrentMatch =
									matchIndices[searchResult.currentIndex] &&
									matchIndices[searchResult.currentIndex].dataIndex === index &&
									matchIndices[searchResult.currentIndex].partIndex === partIndex;

								const isMatch = keyword && part.toLowerCase() === keyword.toLowerCase();

								return (
									<span
										key={`${index}-${partIndex}`}
										style={{
											backgroundColor: isMatch
												? isCurrentMatch
													? 'orange'
													: 'yellow'
												: 'inherit',
										}}
										onDragStart={e => e.preventDefault()} // 드래그 방지
									>
										{part}
									</span>
								);
							})}
						</span>
					) : (
						<span>{data.text}</span>
					)} */}
				</Box>

				{/* 커스텀 메뉴 */}
				{menuPosition && (
					<Menu
						open={Boolean(menuPosition)}
						onClose={handleCloseMenu}
						anchorReference='anchorPosition'
						anchorPosition={menuPosition ? { top: menuPosition.y, left: menuPosition.x } : undefined}
						sx={{
							paddingTop: 0,
							paddingBottom: 0,
							'& .MuiMenu-list': {
								padding: 0,
							},
						}}
					>
						<MenuItem
							sx={{
								zIndex: 9999,
								backgroundColor: '#fff',
								borderRadius: '8px',
								padding: '8px 16px',
								border: `1px solid #CED3D6`,
								boxSizing: 'border-box',
								cursor: 'pointer',
								width: 160,
								height: 40,
							}}
							onClick={() => handleMenuAction('Option 1')}
						>
							<Stack direction='row' spacing={1}>
								<ReplaceWordIcon />
								<Typography sx={{ fontSize: 16, fontWeight: 500, color: '#292A2B' }}>
									단어 변경하기
								</Typography>
							</Stack>
						</MenuItem>
					</Menu>
				)}

				{/* {selectionPopup.open && (
					<Box
						sx={{
							position: 'fixed',
							top: selectionPopup.position.top - 60,
							left: selectionPopup.position.left - 400,
							transform: 'translateX(-50%)',
							zIndex: 9999,
							backgroundColor: '#fff',
							borderRadius: '8px',
							padding: '8px 16px',
							border: `1px solid #CED3D6`,
							boxSizing: 'border-box',
							cursor: 'pointer',
							width: 160,
							height: 40,
						}}
					>
						<Stack direction='row' spacing={1}>
							<ReplaceWordIcon />
							<Typography sx={{ fontSize: 16, fontWeight: 500, color: '#292A2B' }}>
								단어 변경하기
							</Typography>
						</Stack>
					</Box>
				)} */}
			</Stack>
		</Stack>
	);
};

export default React.memo(EditableRow);
