import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import {
	Autocomplete,
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Paper,
	Stack,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useCookies } from 'react-cookie';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import useContentsStore from '../../../store/ContentsStore';
import Share from '../../popup/share/Share';
import { getAvatarColor } from '../../../util/Util';
import HtmlTooltip from '../../tooltip/HtmlTooltip';

const ShareButton = ({ size = 40, content, onConfirm, onCancel, onError, isContent }) => {
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const [AutocompleteOpen, setAutocompleteOpen] = React.useState(false);

	const handleOpen = e => {
		e.stopPropagation();
		setOpen(true);
	};
	const handleCancel = () => {
		onCancel && onCancel();
		setOpen(false);
	};

	return (
		<>
			<HtmlTooltip
				title={
					content && content.shareUsers && content.shareUsers.filter(user => user.nickName).length ? (
						<Box
							sx={{
								maxWidth: 500,
								maxHeight: 150,
								borderRadius: '8px',
								boxSizing: 'border-box',
								display: 'flex',
								flexDirection: 'column',
								gap: '15px',
							}}
						>
							<Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Noto Sans KR' }}>
								공유자{' '}
								<span style={{ color: '#1C6EFF', fontSize: 16, fontWeight: 600 }}>
									{content.shareUsers.filter(user => user.nickName).length}
								</span>
							</Typography>
							<Stack spacing={1} direction='row' flexWrap={'wrap'} sx={{ flex: 1 }}>
								{content.shareUsers
									.filter(user => user.nickName)
									.map((shareUser, index) => (
										<Chip
											key={index}
											label={shareUser.nickName}
											sx={{
												flexShrink: 0,
												border: '1px solid #ECEDEF', // 테두리
												backgroundColor: getAvatarColor(index).background, // 배경색
												color: getAvatarColor(index).color, // 글자색
												fontWeight: 'regular',
											}}
										/>
									))}
							</Stack>
						</Box>
					) : (
						<></>
					)
				}
				placement='top-end'
				slotProps={{
					popper: {
						modifiers: [
							{
								name: 'offset',
								options: {
									// offset: [-80, 0],
								},
							},
						],
					},
				}}
			>
				<Badge
					sx={{
						'& .MuiBadge-badge': {
							width: `6px !important`,
							background: '#1C6EFF',
							position: 'absolute',
							top: 6,
							right: 10,
						},
					}}
					badgeContent={content?.shareUsers?.length}
					invisible={content && content.shareUsers && content.shareUsers.length ? false : true}
					variant='dot'
				>
					<IconButton
						onClick={handleOpen}
						sx={{
							minWidth: `${size}px`,
							width: `${size}px`,
							height: `${size}px`,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid #E1E4E6',
							borderRadius: '8px',
							padding: 0,
						}}
					>
						<ShareOutlinedIcon />
					</IconButton>
				</Badge>
			</HtmlTooltip>

			<Dialog open={open} onClose={handleCancel} sx={{ borderRadius: '16px' }}>
				<Share
					contentId={content.contentId}
					permission={content.userAccessRole}
					data={content.shareUsers ?? []}
				/>

				{/* <DialogTitle
					sx={{
						fontSize: '20px',
						fontWeight: 600,
						textAlign: 'center',
						color: 'var(--Dark-grey700, #292A2B)',
						paddingBottom: '4px',
					}}
				>
					회의록 공유
				</DialogTitle>
				<DialogContent
					sx={{
						maxHeight: '640px', // 최대 높이를 지정 (필요에 따라 조정)
						overflowY: 'auto', // 내용이 넘칠 경우 스크롤바를 표시
					}}
				>
					<Autocomplete
						sx={{
							width: 408,
							height: 36,
							marginBottom: '40px',
							borderRadius: '8px',
							background: 'var(--Light-light200, #F9FAFB)',
						}}
						open={AutocompleteOpen}
						options={options}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								label='공유할 팀원 이름, 이메일을 입력해 주세요.'
								slotProps={{
									input: {
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ?
													<CircularProgress color='inherit' size={20} />
												:	null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									},
								}}
							/>
						)}
					/>
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							lineHeight: '10px',
							color: 'var(--Dark-grey400, #858C99)',
							marginBottom: '16px',
						}}
					>
						공유 사용자
					</Typography>
					<Stack className='share-list'>{drawShareList()}</Stack>
				</DialogContent>

				<DialogActions sx={{ marginBottom: '12px', marginLeft: '16px', marginRight: '16px' }}>
					<Button
						sx={{
							background: 'var(--Blue-primary700, #1C6EFF)',
							color: 'white',
							width: '120px',
							height: '48px',
						}}
						onClick={handleLinkCopy}
						autoFocus
					>
						<InsertLinkOutlinedIcon />
						링크복사
					</Button>
				</DialogActions> */}
			</Dialog>
		</>
	);
};

export default ShareButton;
