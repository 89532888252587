// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rmdp-input {
	width: 100% !important;
	height: 38px !important;
	border: 1px solid rgba(225, 228, 230, 1) !important;
	padding: 0 0 0 55px !important;
	margin: 0 !important;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: rgba(41, 42, 43, 1);
	cursor: pointer;
	border-radius: 8px !important;
	box-sizing: border-box;
}

.rmdp-input:focus {
	border: none !important;
	outline: none !important;
}

.rmdp-week {
	font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/DateRangePicker.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,uBAAuB;CACvB,mDAAmD;CACnD,8BAA8B;CAC9B,oBAAoB;CACpB,aAAa;CACb,mBAAmB;CACnB,eAAe;CACf,0BAA0B;CAC1B,eAAe;CACf,6BAA6B;CAC7B,sBAAsB;AACvB;;AAEA;CACC,uBAAuB;CACvB,wBAAwB;AACzB;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".rmdp-input {\n\twidth: 100% !important;\n\theight: 38px !important;\n\tborder: 1px solid rgba(225, 228, 230, 1) !important;\n\tpadding: 0 0 0 55px !important;\n\tmargin: 0 !important;\n\tdisplay: flex;\n\talign-items: center;\n\tfont-size: 16px;\n\tcolor: rgba(41, 42, 43, 1);\n\tcursor: pointer;\n\tborder-radius: 8px !important;\n\tbox-sizing: border-box;\n}\n\n.rmdp-input:focus {\n\tborder: none !important;\n\toutline: none !important;\n}\n\n.rmdp-week {\n\tfont-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
