import { Box, Paper, SvgIcon, Typography } from '@mui/material';
import React, { useState } from 'react';
import useFilterStore from '../../../store/FilterStore';

import { ReactComponent as CheckIcon } from './img/check.svg';

const Order = () => {
	const { ORDER_LIST, orderType, onChangeOrderType } = useFilterStore();

	return (
		<Box
			sx={{
				width: 148,
				// height: 200,
				boxShadow: 'rgba(0, 0, 0, 0.1)',
				borderRadius: '8px',
				border: '1px solid rgba(206, 211, 214, 1)',
				position: 'relative',
				zIndex: 9999,
				background: '#fff',
			}}
		>
			{ORDER_LIST.map((order, index) => (
				<Paper
					sx={{
						height: 40,
						padding: '10px',
						border: 'none',
						boxShadow: 'none',
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
						boxSizing: 'border-box',
						borderRadius: '8px',

						'&:hover': {
							background: 'rgba(249, 250, 251, 1)',
						},
					}}
				>
					<Typography
						sx={{
							width: '100%',
							fontSize: 14,
							fontWeight: 500,
							color: 'rgba(40, 50, 60, 1)',
							fontFamily: 'Noto Sans KR',
						}}
						onClick={() => onChangeOrderType(order.value)}
					>
						{order.label}
					</Typography>
					{orderType === order.value && (
						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
							<CheckIcon />
						</SvgIcon>
					)}
				</Paper>
			))}
		</Box>
	);
};

export default Order;
