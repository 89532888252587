import axios from 'axios';
import { create } from 'zustand';
import { ToastError, ToastInfo } from '../components/common/toast/Toast';

const useFunctionStore = create(set => ({
	updateSegmentSpeaker: async (accessToken, contentId, startTime, speakerId, optionIndex, callback) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const options = ['onlyHere', 'fromHere', 'all'];

			const response = await axios.patch(
				`${process.env.REACT_APP_API_URL}/contents/${contentId}/transcription/${startTime}/speaker`,
				{
					newSpeakerId: speakerId,
					range: options[optionIndex],
				},
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode === 200) {
				callback && callback(response.data.data.segments);
			}
		} catch (e) {}
	},

	updateSegments: async (accessToken, contentId, segments, onSuccess, onError) => {
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		const response = await axios.patch(
			`${process.env.REACT_APP_API_URL}/contents/${contentId}/transcription/segments/text`,
			{
				segments,
			},
			{
				headers,
			}
		);

		if (response && response.data) {
			const code = response.data.httpCode;

			switch (code) {
				case 200:
					ToastInfo('수정되었습니다.');
					onSuccess && onSuccess();
					break;

				default:
					const isNoNeedChange = response.data.result.includes('변경');

					if (isNoNeedChange) {
						ToastInfo('변경된 문장이 없습니다.');
					} else {
						ToastError(response.data.code, response.data.result);
					}

					onError && onError();
					break;
			}
		}
	},
	resetSegments: async (accessToken, contentId, callback) => {
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/contents/${contentId}/transcription/reset`,
			{},
			{
				headers,
			}
		);

		if (response && response.data) {
			const code = response.data.httpCode;

			switch (code) {
				case 200:
					ToastInfo('음성 기록이 초기화 되었습니다.');
					callback && callback();
					break;

				default:
					ToastError(code);
					break;
			}
		}
	},
}));

export default useFunctionStore;
