import React from 'react';
import { styled, Tooltip, tooltipClasses } from '@mui/material';
import { BorderBottom } from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#fff',
			fontSize: 14,
			border: '1px solid #CED3D6',
			borderRadius: '8px',
			color: '#000',
			maxWidth: '600px',
			display: 'flex',
			flexWrap: 'wrap',
			gap: '8px',
			fontSize: '1.3rem',
			padding: `12px 16px`,
			boxSizing: 'border-box',
			display: 'flex',
			alignItems: 'center',

			'& .MuiTooltip-arrow': {
				'&::before': {
					content: '""',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					border: '1px solid #CED3D6', // 테두리 색상
					clipPath: 'polygon(50%, 50%, 0% 50%, 85% 95%)', // 정확한 삼각형 경로
					zIndex: 1,
					backgroundColor: `#fff`,
				},
			},
		},
	})
);

export default HtmlTooltip;
