import React, { useRef, useState, useEffect } from 'react';
import HtmlTooltip from '../../tooltip/HtmlTooltip';

const OverFlowItem = ({ tooltipItem, item, columnSize, type }) => {
	const itemRef = useRef(null); // Typography DOM 요소 참조
	const [isOverflow, setIsOverflow] = useState(false); // 오버플로우 상태 관리

	const itemWithRef = React.isValidElement(item) ? React.cloneElement(item, { ref: itemRef }) : item;

	useEffect(() => {
		const checkOverflow = () => {
			if (itemRef.current) {
				// 오버플로우 상태 확인
				setIsOverflow(itemRef.current.scrollWidth > itemRef.current.clientWidth);
			}
		};
		checkOverflow();
		window.addEventListener('resize', checkOverflow); // 창 크기 변경 시 재확인
		return () => window.removeEventListener('resize', checkOverflow);
	}, [columnSize]);

	return (
		<HtmlTooltip
			title={isOverflow ? tooltipItem : <></>}
			disableHoverListener={!isOverflow}
			placement='top-start'
			slotProps={{
				popper: {
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [-10, type === 'keyword' ? -30 : 0],
							},
						},
					],
				},
			}}
		>
			{itemWithRef}
		</HtmlTooltip>
	);
};

export default OverFlowItem;
