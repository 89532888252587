import React, { useRef, useState, useEffect } from 'react';
import { Typography, Tooltip } from '@mui/material';
import HtmlTooltip from '../../tooltip/HtmlTooltip';

const OverflowTypography = ({ text, highlightText }) => {
	const textRef = useRef(null); // Typography DOM 요소 참조
	const [isOverflow, setIsOverflow] = useState(false); // 오버플로우 상태 관리

	const parts = text.split(new RegExp(`(${highlightText})`, 'gi'));

	useEffect(() => {
		const checkOverflow = () => {
			if (textRef.current) {
				// 오버플로우 상태 확인
				setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
			}
		};
		checkOverflow();
		window.addEventListener('resize', checkOverflow); // 창 크기 변경 시 재확인
		return () => window.removeEventListener('resize', checkOverflow);
	}, []);

	return (
		<HtmlTooltip
			title={
				isOverflow ? (
					<div
						style={{
							color: '#28323C',
							fontSize: '16px',
							fontWeight: 600,
							fontFamily: 'Noto Sans KR',
						}}
					>
						{text}
					</div>
				) : (
					<></>
				)
			}
			disableHoverListener={!isOverflow}
			placement='top-start'
			slotProps={{
				popper: {
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [-10, 0],
							},
						},
					],
				},
			}}
		>
			<Typography
				ref={textRef}
				sx={{
					fontWeight: 'bold',
					display: 'block',
					width: '100%',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					fontFamily: 'Noto Sans KR',
					fontSize: '16px',
					color: '#28323C',
				}}
			>
				{parts.map((part, index) =>
					part.toLowerCase() === highlightText.toLowerCase() ? (
						<span key={index} style={{ backgroundColor: 'yellow' }}>
							{part}
						</span>
					) : (
						part
					)
				)}
			</Typography>
		</HtmlTooltip>
	);
};

export default OverflowTypography;
