// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-content:empty::before {
	content: attr(data-placeholder);
	color: #888;
	cursor: text;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/transcription/Modal/AiCorrect.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,WAAW;CACX,YAAY;AACb","sourcesContent":[".editable-content:empty::before {\n\tcontent: attr(data-placeholder);\n\tcolor: #888;\n\tcursor: text;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
