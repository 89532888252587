import { alpha } from '@mui/material';

const black = 'hsl(0, 0%, 0%)';
const white = {
	100: 'hsla(0, 0%, 100%, 1)',
	200: 'hsla(210, 20%, 98%, 1)',
};
const primary = {
	800: 'hsl(218, 100%, 50%)',
	700: 'hsl(218, 100%, 55%)',
	600: 'hsl(218, 100%, 62%)',
	500: 'hsl(218, 100%, 67%)',
	400: 'hsl(219, 100%, 75%)',
	300: 'hsl(218, 100%, 82%)',
	200: 'hsl(218, 100%, 94%)',
	100: 'hsl(219, 100%, 98%)',
	0: 'hsl(212, 100%, 97%)',
};
const light = {
	400: 'hsl(203, 9%, 82%)',
	300: 'hsl(204, 9%, 89%)',
	200: 'hsl(210, 20%, 98%)',
	100: 'hsl(180, 17%, 95%)',
	0: 'hsl(0, 0%, 98%)',
	white: white[100],
};
const grey = {
	800: 'hsl(210, 20%, 96%)', // figma 정의안된 회색
	700: 'hsl(210, 2%, 16%)',
	600: 'hsl(200, 5%, 22%)',
	500: 'hsl(207, 6%, 32%)',
	400: 'hsl(204, 4%, 55%)',
	300: 'hsl(204, 6%, 68%)',
	200: 'hsl(203, 9%, 82%)',
	100: 'hsl(204, 9%, 89%)',
};
const dark = {
	300: 'hsl(210, 20%, 20%)',
	200: 'hsl(219, 9%, 56%)',
	100: 'hsl(214, 14%, 90%)',
	black: black,
};
const red = {
	500: 'hsl(349, 100%, 46%)', // figma 정의안된 빨간색
	400: 'hsl(3, 69%, 49%)',
	300: 'hsl(3, 94%, 60%)',
	200: 'hsl(4, 90%, 58%)',
	100: 'hsl(351, 100%, 96%)',
};
const status = {
	alert: 'hsl(4, 94%, 60%)',
	caution: 'hsl(29, 95%, 60%)',
	positive: 'hsl(136, 52%, 50%)',
};
const green = {
	500: 'hsl(168, 97%, 38%)',
	400: 'hsl(148, 54%, 54%)',
	300: 'hsl(148, 54%, 54%)',
	200: 'hsl(144, 63%, 56%)',
	100: 'hsl(125, 39%, 94%)',
	0: 'hsl(124, 100%, 89%)',
	// Figma에서 추가된 색상
	600: 'hsl(101, 100%, 32%)', // Figma 짙은 초록 추가
	700: 'hsl(157, 84%, 42%)', // Figma 중간 초록 추가
	800: 'hsl(172, 100%, 37%)', // Figma 청록 추가
};
const purple = {
	300: 'hsl(252, 86%, 63%)',
	200: 'hsl(252, 86%, 63%)',
	100: 'hsl(264, 45%, 94%)',
};
const yellow = {
	200: 'hsl(34, 100%, 50%)',
	100: 'hsl(46, 100%, 94%)',
};
const blue = {
	700: 'hsl(228, 100%, 58%)',
	500: 'hsl(212, 99%, 56%)', // Figma 중간 파랑 추가
	100: 'hsl(212, 100%, 93%)', // Figma 연한 파랑 추가
};

// figma에 정의 안된 색상 정의

const teal = {
	100: 'hsl(177, 41%, 91%)', // Figma 청록빛 회색 추가
};
const mint = {
	100: 'hsl(102, 42%, 94%)', // Figma 민트색 추가
};
const beige = {
	300: 'hsl(3, 94%, 60%)', // Figma 밝은 베이지 추가
};
const orange = {
	500: 'hsl(29, 100%, 50%)', // 오렌지 추가
};

const pink = {
	0: 'hsla(0, 100%, 95%, 1)',
};

const fontFamily = ['Noto Sans KR'];

export const getDesignTokens = mode => ({
	palette: {
		mode,
		primary: {
			800: primary[800],
			700: primary[700],
			600: primary[600],
			500: primary[500],
			400: primary[400],
			300: primary[300],
			200: primary[200],
			100: primary[100],
			0: primary[0],
			...(mode === 'dark' && {
				800: primary[800],
				700: primary[700],
				600: primary[600],
				500: primary[500],
				400: primary[400],
				300: primary[300],
				200: primary[200],
				100: primary[100],
				0: primary[0],
			}),
		},
		light: {
			400: light[400],
			300: light[300],
			200: light[200],
			100: light[100],
			0: light[0],
			white: light.white,
			...(mode === 'dark' && {
				400: light[400],
				300: light[300],
				200: light[200],
				100: light[100],
				0: light[0],
				white: light.white,
			}),
		},
		grey: {
			800: grey[800], // figma 정의안된 회색
			700: grey[700],
			600: grey[600],
			500: grey[500],
			400: grey[400],
			300: grey[300],
			200: grey[200],
			100: grey[100],
			...(mode === 'dark' && {
				800: grey[800], // figma 정의안된 회색
				700: grey[700],
				600: grey[600],
				500: grey[500],
				400: grey[400],
				300: grey[300],
				200: grey[200],
				100: grey[100],
			}),
		},
		dark: {
			300: dark[300],
			200: dark[200],
			100: dark[100],
			black: black,
			...(mode === 'dark' && {
				300: dark[300],
				200: dark[200],
				100: dark[100],
				black: black,
			}),
		},
		red: {
			500: red[500],
			400: red[400],
			300: red[300],
			200: red[200],
			100: red[100],
			...(mode === 'dark' && {
				500: red[500],
				400: red[400],
				300: red[300],
				200: red[200],
				100: red[100],
			}),
		},
		status: {
			alert: status.alert,
			caution: status.caution,
			positive: status.positive,
			...(mode === 'dark' && {
				alert: status.alert,
				caution: status.caution,
				positive: status.positive,
			}),
		},
		green: {
			500: green[500],
			400: green[400],
			300: green[300],
			200: green[200],
			100: green[100],
			0: green[0],
			// Figma에서 추가된 색상
			600: green[600], // Figma 짙은 초록 추가
			700: green[700], // Figma 중간 초록 추가
			800: green[800], // Figma 청록 추가
			...(mode === 'dark' && {
				500: green[500],
				400: green[400],
				300: green[300],
				200: green[200],
				100: green[100],
				0: green[0],
				// Figma에서 추가된 색상
				600: green[600], // Figma 짙은 초록 추가
				700: green[700], // Figma 중간 초록 추가
				800: green[800], // Figma 청록 추가
			}),
		},
		purple: {
			300: purple[300],
			200: purple[200],
			100: purple[100],
			...(mode === 'dark' && {
				300: purple[300],
				200: purple[200],
				100: purple[100],
			}),
		},
		yellow: {
			200: yellow[200],
			100: yellow[100],
			...(mode === 'dark' && {
				200: yellow[200],
				100: yellow[100],
			}),
		},
		blue: {
			700: blue[700],
			500: blue[500], // Figma 중간 파랑 추가
			100: blue[100],

			...(mode === 'dark' && {
				700: blue[700],
				500: blue[500], // Figma 중간 파랑 추가
				100: blue[100],
			}),
		},
		teal: {
			100: teal[100],
			...(mode === 'dark' && {
				100: teal[100],
			}),
		},
		mint: {
			100: mint[100],
			...(mode === 'dark' && {
				100: mint[100],
			}),
		},
		beige: {
			300: beige[300],

			...(mode === 'dark' && {
				300: beige[300],
			}),
		},
		orange: {
			500: orange[500],

			...(mode === 'dark' && {
				500: orange[500],
			}),
		},
		pink: {
			0: pink[0],

			...(mode === 'dark' && {
				0: pink[0],
			}),
		},

		divider: mode === 'dark' ? alpha(grey[700], 0.6) : alpha(grey[300], 0.4),
	},
	typography: {
		display: {
			fontFamily,
			fontSize: '36px',
			fontWeight: 400,
			lineHeight: '48px',
		},
		headline1: {
			fontFamily,
			fontSize: '28px',
			fontWeight: 700,
			lineHeight: '38px',
		},
		headline2: {
			fontFamily,
			fontSize: '24px',
			fontWeight: 700,
			lineHeight: '32px',
		},
		headline3: {
			fontFamily,
			fontSize: '18px',
			fontWeight: 700,
			lineHeight: '24px',
		},
		headline4: {
			fontFamily,
			fontSize: '16px',
			fontWeight: 700,
			lineHeight: '24px',
		},
		title1: {
			fontFamily,
			fontSize: '20px',
			fontWeight: 600,
			lineHeight: '26px',
		},
		title2: {
			fontFamily,
			fontSize: '18px',
			fontWeight: 600,
			lineHeight: '24px',
		},
		title3: {
			fontFamily,
			fontSize: '16px',
			fontWeight: 600,
			lineHeight: '22px',
		},
		title4: {
			fontFamily,
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '22px',
		},

		title7: {
			fontFamily,
			fontSize: '18px',
			fontWeight: 500,
			lineHeight: '24px',
		},
		title8: {
			fontFamily,
			fontSize: '20px',
			fontWeight: 700,
			lineHeight: '27.24px',
		},
		body1: {
			fontFamily,
			fontSize: '18px',
			fontWeight: 400,
			lineHeight: '24px',
		},
		body2: {
			fontFamily,
			fontSize: '16px',
			fontWeight: 500,
			lineHeight: '22px',
		},
		body3: {
			fontFamily,
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '22px',
		},
		body4: {
			fontFamily,
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '20px',
		},
		body5: {
			fontFamily,
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '20px',
		},
		body6: {
			fontFamily,
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '20px',
		},
		caption1: {
			fontFamily,
			fontSize: '12px',
			fontWeight: 600,
			lineHeight: '18px',
		},
		caption2: {
			fontFamily,
			fontSize: '12px',
			fontWeight: 400,
			lineHeight: '18px',
		},
	},
	shape: {
		borderRadius: 8,
	},
	shadows: [
		'none',
		...(mode === 'dark'
			? 'hsla(220, 30%, 5%, 0.7) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.8) 0px 8px 16px -5px'
			: 'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px'),
	],
});

export const getCustomTheme = mode => {
	return getDesignTokens(mode);
};
