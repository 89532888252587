import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './AiCorrect.css';
import { Box, Button, Stack, Typography, Tooltip, Divider, Checkbox, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Virtuoso } from 'react-virtuoso';

import SearchBox from '../SearchBox';
import ReactLoading from 'react-loading';
import CollectSelectBox from './CollectSelectBox';

import { ReactComponent as HintInfoIcon } from '../img/noti.svg';
import { ReactComponent as DisableCheckIcon } from '../img/disablecheck.svg';
import { ReactComponent as AbleCheckIcon } from '../img/ablecheck.svg';
import EditableRow from './EditableRow';
import ModalConfirm from '../../../common/modal/ModalConfirm';
import { ToastInfo } from '../../../common/toast/Toast';
import { useCookies } from 'react-cookie';
import useFunctionStore from '../../../../store/FunctionStore';

const INPUT_STYLE = {
	width: 200,
	height: 40,
	borderRadius: '8px',
	border: '1px solid #E1E4E6',
	padding: `8px 12px`,
	outline: 'none',
	fontSize: 16,
	color: '#28323C',
	background: '#fff',
	fontWeight: 400,
	lineHeight: '22px',
	boxSizing: 'border-box',
	fontFamily: 'Noto Sans KR',
};

const AiCorrect = ({
	contentId,
	data = [],
	speakerInfo = [],
	playing = false,
	onClose = () => {},
	getCurrentPlayTime = () => {},
	onSeekTime = () => {},
	onTogglePlay = () => {},
	onRefresh = () => {},
}) => {
	const replaceRef = useRef();
	const virtualRef = useRef(null);
	const newWordInputRef = useRef(null);

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [saveAnchorEl, setSaveAnchorEl] = useState(null);
	const [resetAnchorEl, setResetAnchorEl] = useState(null);
	const [editedData, setEditedData] = useState([...data]);

	// 찾기
	const [keyword, setKeyword] = useState('');
	const [searchResult, setSearchResult] = useState({
		currentIndex: 0,
		total: 0,
	});

	// 단어바꾸기
	const [newWord, setNewWord] = useState('');
	const [isCheckAllReplace, setIsCheckAllReplace] = useState(false);
	const [isComposing, setIsComposing] = useState(false);

	const { updateSegments, resetSegments } = useFunctionStore();
	const [filteredSpeakers, setFilteredSpeakers] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setEditedData([...data]);
	}, [data]);

	useEffect(() => {
		if (!keyword) {
			setSearchResult({ currentIndex: 0, total: 0 });
		}
	}, [keyword]);

	const highlightedData = useMemo(() => {
		if (!keyword) {
			return editedData.map(item => [item.text]);
		}

		const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
		const regex = new RegExp(`(${escapedKeyword})`, 'gi');

		return editedData.map(item => {
			return item.text.split(regex);
		});
	}, [editedData, keyword]);

	const matchIndices = useMemo(() => {
		if (!keyword) return [];
		let indices = [];
		highlightedData.forEach((parts, dataIndex) => {
			parts.forEach((part, partIndex) => {
				if (part.toLowerCase() === keyword.toLowerCase()) {
					indices.push({ dataIndex, partIndex });
				}
			});
		});

		return indices;
	}, [highlightedData, keyword]);

	useEffect(() => {
		if (virtualRef.current && matchIndices[searchResult.currentIndex]) {
			onScrollToIndex(matchIndices[searchResult.currentIndex].dataIndex, 'auto');
		}
	}, [searchResult, matchIndices]);

	const totalMatches = matchIndices.length;

	const onScrollToIndex = (index, behavior = 'smooth') => {
		if (virtualRef.current) {
			virtualRef.current.scrollToIndex({
				index,
				align: 'center',
				behavior,
			});
		}
	};

	const handleOnChangeText = (text, index) => {
		setEditedData(prev => prev.map((item, idx) => (idx === index ? { ...item, text, isEdit: true } : item)));
	};

	const onComboBoxOptionsSelected = selectedOptions => {
		setFilteredSpeakers(selectedOptions);
	};

	const filteredData = useCallback(() => {
		if (filteredSpeakers === null) {
			return editedData;
		} else {
			const targets = filteredSpeakers.map(speaker => speaker.speakerId);

			if (targets && targets.length) {
				return editedData.filter(data => targets.some(targetSpeaker => targetSpeaker === data.speakerId));
			} else {
				return [];
			}
		}
	}, [editedData, filteredSpeakers]);

	const handleClose = event => {
		const hasEdits = editedData.some(item => item.isEdit);

		if (hasEdits) {
			setSaveAnchorEl(event.currentTarget);
		} else {
			onClose();
		}
	};

	const onUpdateSegments = (afterClose = false) => {
		const editedSegments = editedData.filter(data => data.isEdit);

		if (editedSegments && editedSegments.length) {
			setIsLoading(true);
			updateSegments(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				contentId,
				editedSegments,
				() => {
					onRefresh();
					setEditedData(prev => prev.map(data => ({ ...data, isEdit: false })));

					setIsLoading(false);
					afterClose && onClose();
				},
				() => {
					setIsLoading(false);
				}
			);
		} else {
			ToastInfo('변경된 문장이 없습니다.');
		}
	};

	const onResetSegments = (afterClose = false) => {
		setIsLoading(true);
		resetSegments(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, () => {
			onRefresh();
			setIsLoading(false);
			afterClose && onClose();
		});
	};

	const onChangeWord = () => {
		if (isCheckAllReplace) {
			console.log(editedData);

			setEditedData(
				editedData.map(data => {
					return {
						...data,
						text: data.text.replaceAll(keyword, newWordInputRef.current.textContent),
						isEdit: true,
					};
				})
			);
		} else {
			if (0 < totalMatches) {
				let count = 0;
				setEditedData(
					editedData.map(item => {
						// 현재 아이템 복사
						const newItem = { ...item, isEdit: true };
						const regex = new RegExp(keyword, 'g'); // 전역 검색 정규식
						let match;

						// n번째 교체를 위해 text를 순차적으로 처리
						newItem.text = newItem.text.replace(regex, match => {
							count++;
							if (count === searchResult.currentIndex + 1) {
								return newWordInputRef.current.textContent; // 3번째에서만 교체
							}
							return match; // 나머지는 그대로 유지
						});

						return newItem; // 수정된 객체 반환
					})
				);
			} else {
				console.log('[AiCorrect] Anything to change');
			}
		}
	};

	return (
		<Box
			sx={{
				width: 1100,
				height: 838,
				borderRadius: '8px',
				border: `1px solid #E1E4E6`,
				boxSizing: `border-box`,
				display: 'flex',
				flexDirection: 'column',
				background: '#fff',
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}
		>
			<Box sx={{ width: '100%', height: 88, padding: '24px 32px', boxSizing: 'border-box' }}>
				<Stack direction='row' alignItems={'center'} spacing={2}>
					<Stack spacing={'5px'} direction={'row'} alignItems={'center'}>
						<Typography sx={{ fontSize: 24, fontWeight: 700, fontFamily: 'Noto Sans KR' }}>교정</Typography>
						<CustomTooltip title='사용자가 직접 음성 기록을 편집할 수 있습니다.' placement='bottom-start'>
							<HintInfoIcon />
						</CustomTooltip>
					</Stack>

					<Stack
						spacing={1}
						direction={'row'}
						sx={theme => ({
							alignItems: 'center',
							width: 462,
							height: '40px',
							border: `1px solid #E1E4E6`,
							backgroundColor: `#F9FAFB`,
							borderRadius: '8px',
						})}
					>
						<SearchBox
							keyword={keyword}
							setKeyword={setKeyword}
							searchResult={searchResult}
							setSearchResult={setSearchResult}
							totalMatches={totalMatches}
							type='standard'
						/>
					</Stack>

					<Stack sx={{ marginLeft: 'auto !important' }} direction={'row'} alignItems={'center'} spacing={1}>
						<Typography
							sx={{ paddingRight: 2, cursor: 'pointer' }}
							onClick={event => setResetAnchorEl(event.currentTarget)}
						>
							초기화
						</Typography>
						<Button
							sx={{
								width: 78,
								height: 40,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: '#fff',
								background: '#1C6EFF',
								borderRadius: '8px',
								boxSizing: 'border-box',
							}}
							onClick={() => onUpdateSegments(false)}
							disabled={isLoading}
						>
							{isLoading ? <ReactLoading type='spin' color={'red'} width={20} height={20} /> : '저장'}
						</Button>
						<Button
							sx={{
								width: 78,
								height: 40,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: '#292A2B',
								borderRadius: '8px',
								border: '1px solid #CED3D6',
								boxSizing: 'border-box',
							}}
							onClick={handleClose}
						>
							취소
						</Button>
					</Stack>
				</Stack>
			</Box>
			<Divider />

			<Box sx={{ height: 88, padding: '24px 32px' }}>
				<Stack spacing={2} direction={'row'} alignItems={'center'}>
					<Typography sx={{ fontSize: 20, fontWeight: 600, fontFamily: 'Noto Sans KR', color: '#28323C' }}>
						음성 기록
					</Typography>
					<CollectSelectBox
						defaultOption={'전체 참석자'}
						options={speakerInfo}
						displayKey={speaker => speaker.displayName ?? speaker.name}
						onSelectedOptions={onComboBoxOptionsSelected}
					/>

					<Stack spacing={1} sx={{ marginLeft: 'auto !important' }} direction={'row'} alignItems={'center'}>
						<Typography sx={{ color: '#28323C', fontSize: 18, fontWeight: 500, paddingRight: 1 }}>
							단어 변경
						</Typography>

						<Stack
							spacing={1}
							direction={'row'}
							sx={theme => ({
								alignItems: 'center',
								width: 200,
								height: '40px',
								border: `1px solid #E1E4E6`,
								backgroundColor: `#fff`,
								borderRadius: '8px',
							})}
						>
							<SearchBox
								keyword={keyword}
								setKeyword={setKeyword}
								searchResult={searchResult}
								setSearchResult={setSearchResult}
								totalMatches={totalMatches}
								placeholder={'변경 전 단어'}
								type={'replace'}
								// style={INPUT_STYLE}
							/>
						</Stack>

						<div
							ref={newWordInputRef}
							contentEditable
							style={{
								...INPUT_STYLE,
								whiteSpace: 'nowrap',
								outline: 'none',
								overflow: 'hidden',
							}}
							className='editable-content'
							data-placeholder='변경 후 단어'
							suppressContentEditableWarning={true}
						/>

						{/* <input
							type='text'
							style={INPUT_STYLE}
							value={newWord}
							onChange={e => {
								setNewWord(e.target.value);
							}}
							onCompositionStart={() => setIsComposing(true)}
							onCompositionEnd={() => setIsComposing(false)}
							// onBlur 이벤트 핸들러 제거
							placeholder='변경 후 단어'
						/> */}

						<Button
							sx={{
								background: '#F9FAFB',
								color: '#4D5256',
								width: 78,
								height: 40,
								fontFamily: 'Noto Sans KR',
								fontSize: 16,
								fontWeight: 400,
								borderRadius: '4px',
								border: '1px solid #CED3D6',
								boxSizing: 'border-box',

								':disabled': {
									background: '#E7E8E9',
									color: '#A9AFB3',
								},
							}}
							disabled={!keyword}
							onClick={() => {
								onChangeWord();
							}}
						>
							바꾸기
						</Button>
						<Stack
							spacing={1}
							direction={'row'}
							alignItems={'center'}
							sx={{ marginLeft: '24px !important' }}
						>
							<Checkbox
								checked={isCheckAllReplace}
								icon={<DisableCheckIcon />}
								checkedIcon={<AbleCheckIcon />}
								onChange={event => setIsCheckAllReplace(event.target.checked)}
								sx={{ width: 24, height: 24, p: 0 }}
							/>
							<Typography>전체 적용</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Box>

			<Box sx={{ flex: 1, flexShrink: 0, overflow: 'auto', padding: `0 32px`, boxSizing: 'border-box' }}>
				<Virtuoso
					ref={virtualRef}
					style={{ height: '100%' }}
					totalCount={filteredData().length}
					itemContent={index => (
						<EditableRow
							contentId={contentId}
							data={filteredData()[index]}
							onChangeText={text => handleOnChangeText(text, index)}
							index={index}
							speakerInfo={speakerInfo}
							searchResult={searchResult}
							matchIndices={matchIndices}
							highlightedData={highlightedData}
							keyword={keyword}
							getCurrentPlayTime={getCurrentPlayTime}
							onSeekTime={startTime => onSeekTime(startTime)}
							onTogglePlay={onTogglePlay}
							playing={playing}
							onPasteKeyword={selectedText => setKeyword(selectedText)}
						/>
					)}
				/>
			</Box>

			<ModalConfirm
				title={'교정을 종료할까요?'}
				description={
					<>
						<div>변경된 내용이 있습니다.</div>
						<div>저장하지 않으면 내용이 사라집니다.</div>
					</>
				}
				isOpen={saveAnchorEl}
				customButtons={
					<>
						<Button
							sx={{
								width: 70,
								height: 48,
								borderRadius: '4px',
								background: '#E1E4E6',
								color: '#4D5256',
								fontSize: 18,
								fontWeight: 500,
								fontFamily: 'Noto Sans KR',
							}}
							onClick={() => setSaveAnchorEl(null)}
						>
							취소
						</Button>
						<Button
							sx={{
								width: 113,
								height: 48,
								border: `1px solid #CED3D6`,
								color: '#292A2B',
								fontSize: 18,
								fontWeight: 500,
								fontFamily: 'Noto Sans KR',
							}}
							onClick={() => {
								setSaveAnchorEl(null);
								onClose();
							}}
						>
							그냥 종료
						</Button>
						<Button
							sx={{
								width: 113,
								height: 48,
								fontSize: 18,
								fontWeight: 500,
								fontFamily: 'Noto Sans KR',
								background: '#1C6EFF',
								color: '#fff',
								display: 'flex',
								alignItems: 'center',
							}}
							disabled={isLoading}
							onClick={() => onUpdateSegments(true)}
						>
							{isLoading ? (
								<ReactLoading type='spin' color={'red'} width={20} height={20} />
							) : (
								'저장 후 종료'
							)}
						</Button>
					</>
				}
			/>

			<ModalConfirm
				title={'교정을 초기화할까요?'}
				description={'모든 변경 사항이 사라지고, 처음 상태로 돌아갑니다.'}
				isOpen={resetAnchorEl}
				customButtons={
					<>
						<Button
							sx={{
								width: 152,
								height: 48,
								borderRadius: '4px',
								background: '#E1E4E6',
								color: '#4D5256',
								fontSize: 18,
								fontWeight: 500,
								fontFamily: 'Noto Sans KR',
							}}
							onClick={() => setResetAnchorEl(null)}
						>
							취소
						</Button>

						<Button
							sx={{
								width: 152,
								height: 48,
								fontSize: 18,
								fontWeight: 500,
								fontFamily: 'Noto Sans KR',
								background: '#1C6EFF',
								color: '#fff',
								display: 'flex',
								alignItems: 'center',
							}}
							disabled={isLoading}
							onClick={() => onResetSegments(true)}
						>
							{isLoading ? <ReactLoading type='spin' color={'red'} width={20} height={20} /> : '확인'}
						</Button>
					</>
				}
			/>
		</Box>
	);
};

export default AiCorrect;

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .MuiTooltip-tooltip`]: {
			...theme.typography.caption2,
			backgroundColor: theme.palette.grey[700],
			color: theme.palette.light.white,
			padding: '0px 4px 1px 4px',
			borderRadius: '4px',
			whiteSpace: 'nowrap',
			maxWidth: 'none',
			overflow: 'visible',
		},
	})
);
