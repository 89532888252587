import React, { useEffect, useLayoutEffect, useState } from 'react';
import dayjs, { Dayjs, startOfMonth, endOfMonth } from 'dayjs';
import 'dayjs/locale/ko';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import useContentsStore from '../../../store/ContentsStore';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { ReactComponent as ArrowIcon } from '../img/arrow.svg';
import { ReactComponent as CalendarIcon } from '../img/calendar.svg';
import { useCookies } from 'react-cookie';
import { drawContentTypeV2 } from '../../../components/common/ContentIcon/ContentIcon';
import useAuthStore from '../../../store/AuthStore';
import { useNavigate } from 'react-router-dom';
import { getSafeUrl } from '../../../util/Util';

const CALENDAR_HEADER_HEIGHT = 62;
const CALENDAR_DAY_WEEK_HEIGHT = 30;
const CALENDAR_FOOTER_HEIGHT = 40;

const Item = styled(Paper)(({ theme }) => ({
	width: '100%',
	height: '48px',
	// margin: '0 20px',
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	border: 'none',
	boxShadow: 'none',
	gap: '15px',
	cursor: 'pointer',
	flexShrink: 0,
}));

const CustomCalendarHeader = props => {
	const { currentMonth, onMonthChange, onViewChange, view, selectedDate, setSelectedDate } = props;

	const isToday = dayjs().isSame(selectedDate, 'day');
	const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
	const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');

	const handleHeaderClick = () => {
		onViewChange(view === 'month' ? 'day' : 'month');
	};

	return (
		<Box
			display='flex'
			sx={{
				width: '100%',
				height: CALENDAR_HEADER_HEIGHT,
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: '30px',
				padding: '0 20px',
				boxSizing: 'border-box',
				position: 'relative',
			}}
		>
			<Typography
				style={{
					// width: '100%',
					height: '66px',
					display: 'flex',
					alignItems: 'center',
					fontSize: '20px',
					fontWeight: 600,
					color: '#292A2B',
					boxSizing: 'border-box',
					cursor: 'pointer',
				}}
				onClick={handleHeaderClick}
			>
				{currentMonth.format('YYYY년 MM월')}
			</Typography>

			<Button
				variant='outlined'
				size='small'
				sx={{
					position: 'absolute',
					left: '44%',
					border: '1px solid #CED3D6',
					padding: '4px 12px',
					borderRadius: '4px',

					color: isToday ? '#E1E4E6' : '#292A2B',
					background: isToday ? '#F9FAFB' : '#fff',
					minWidth: 'unset',
					width: '54px',
					height: '30px',
				}}
				disabled={isToday ? true : false}
				onClick={() => setSelectedDate(dayjs())}
			>
				오늘
			</Button>

			<Stack spacing={0.5} direction='row' sx={{ marginLeft: 'auto' }}>
				<IconButton sx={{ width: '25px' }} onClick={selectPreviousMonth} title='Previous month'>
					<ChevronLeft />
				</IconButton>
				<IconButton sx={{ width: '25px' }} onClick={selectNextMonth} title='Next month'>
					<ChevronRight />
				</IconButton>
			</Stack>
		</Box>
	);
};

const CustomDay = props => {
	const { day, outsideCurrentMonth, today, selected, ...other } = props;
	const date = dayjs(day);

	const { contents } = useContentsStore();

	const isExistContentsFromDay = () => {
		let result = false;

		if (contents && contents.calendar) {
			const isExist = contents.calendar.some(content => dayjs(content.meetingStartTime).isSame(day, 'day'));

			if (isExist) {
				result = true;
			}
		}

		return result;
	};

	return (
		<PickersDay
			{...other}
			day={day}
			outsideCurrentMonth={outsideCurrentMonth}
			sx={{
				height: '100%',
				opacity: outsideCurrentMonth ? 0.5 : 1,
				color: selected ? '#fff !important' : date.day() === 0 ? 'red !important' : 'inherit',
				borderColor: today ? '#1C6EFF' : 'transparent',
				borderWidth: 1,
				borderStyle: 'solid',
				backgroundColor: selected ? '#1C6EFF' : 'transparent',
				position: 'relative',
				boxSizing: 'border-box',

				'&:focus': {
					backgroundColor: '#1C6EFF',
					position: 'relative',
				},
			}}
		>
			{day.format('D')}
			{isExistContentsFromDay() && (
				<div
					style={{
						position: 'absolute',
						bottom: -10,
						width: 6,
						height: 6,
						borderRadius: '50%',
						backgroundColor: '#FA9538',
					}}
				/>
			)}
		</PickersDay>
	);
};

const HomeCalendar = ({ onClickContent }) => {
	const navigate = useNavigate();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { getCalendarContents } = useContentsStore();
	const [personName, setPersonName] = React.useState([]);
	const [selectedDate, setSelectedDate] = useState(dayjs());
	const { auth } = useAuthStore();
	const { contents } = useContentsStore();
	const [isOpenList, setIsOpenList] = useState(false);

	useLayoutEffect(() => {
		handleMonthChange(dayjs());
	}, []);

	const names = [
		'Oliver Hansen',
		'Van Henry',
		'April Tucker',
		'Ralph Hubbard',
		'Omar Alexander',
		'Carlos Abbott',
		'Miriam Wagner',
		'Bradley Wilkerson',
		'Virginia Andrews',
		'Kelly Snyder',
	];

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const handleMonthChange = month => {
		const startDate = month.startOf('month').subtract(7, 'day').format('YYYYMMDD');
		const endDate = month.endOf('month').add(7, 'day').format('YYYYMMDD');

		getCalendarContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, startDate, endDate);
	};

	const isExistContentInDay = () => {
		if (contents.calendar) {
			return contents.calendar.some(content => dayjs(content.meetingStartTime).isSame(selectedDate, 'day'));
		} else {
			return false;
		}
	};

	const drawContentList = () => {
		const list = contents.calendar.filter(content => dayjs(content.createAt).isSame(selectedDate, 'day'));

		return (
			<>
				<Box>
					<Stack spacing={1} sx={{ maxHeight: 270, overflow: 'auto' }}>
						{list.slice(0, isOpenList ? list.length : 1).map((item, index) => (
							<Item
								key={index}
								onClick={() => {
									onClickContent(item);
									navigate(getSafeUrl(`/content/${item.contentId}`));
								}}
							>
								<div
									style={{ width: '4px', height: '100%', background: '#FA9538', borderRadius: '2px' }}
								/>
								<Typography
									variant='h6'
									style={{
										width: '80px',
										fontWeight: '500',
										color: '#4D5256',
										fontSize: '16px',
										fontFamily: 'Noto Sans KR',
									}}
								>
									{dayjs(item.createAt).locale('ko').format('LT')}
								</Typography>
								{drawContentTypeV2(auth, item, 24)}
								<Typography
									variant='h6'
									style={{
										fontWeight: '500',
										color: '#28323C',
										fontSize: '16px',
										fontFamily: 'Noto Sans KR',
										marginRight: 'auto',
										display: 'block',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										flex: 1,
									}}
								>
									{item.editedTitle ?? item.title}
								</Typography>
							</Item>
						))}
					</Stack>
				</Box>
				{!isOpenList ? (
					list.length > 1 ? (
						<Button
							variant='contained'
							style={{
								backgroundColor: '#fff',
								color: '#1C6EFF',
								fontSize: '16px',
								fontWeight: 600,
								fontFamily: 'Noto Sans KR',
								display: 'flex',
								gap: '15px',
							}}
							onClick={() => setIsOpenList(prev => !prev)}
						>
							회의록 펼쳐보기 <KeyboardArrowDownIcon style={{ rotate: '270deg' }} stroke='#1C6EFF' />
						</Button>
					) : (
						<Typography
							variant='h6'
							style={{
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								fontWeight: '500',
								color: '#878D91',
								fontSize: '14px',
								fontFamily: 'Noto Sans KR',
							}}
						>
							더 등록된 회의록이 없습니다.
						</Typography>
					)
				) : (
					<Button
						variant='contained'
						style={{
							backgroundColor: '#fff',
							color: '#1C6EFF',
							fontSize: '16px',
							fontWeight: 600,
							fontFamily: 'Noto Sans KR',
							display: 'flex',
							gap: '15px',
						}}
						onClick={() => setIsOpenList(prev => !prev)}
					>
						회의록 접기 <KeyboardArrowDownIcon style={{ rotate: '90deg' }} stroke='#1C6EFF' />
					</Button>
				)}
			</>
		);
	};

	const drawContentListV2 = () => {
		const list = contents.calendar.filter(content => dayjs(content.meetingStartTime).isSame(selectedDate, 'day'));

		return (
			<>
				<Box
					sx={{
						width: '100%',
						maxHeight: isOpenList ? '350px' : `${CALENDAR_FOOTER_HEIGHT}px`,
						fontSize: 16,
						fontWeight: 700,
						fontFamily: 'Noto Sans KR',
						color: '#28323C',
						display: 'flex',
						flexDirection: 'column',
						// justifyContent: 'space-between',
						// alignItems: 'center',
						overflow: 'hidden', // 추가
						transition: 'max-height 0.3s ease-in-out',
						background: '#fff',
					}}
				>
					<Stack
						sx={{ width: '100%', height: '100%', boxSizing: 'border-box', cursor: 'pointer' }}
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
						onClick={() => setIsOpenList(prev => !prev)}
					>
						{list.length}개의 회의록이 있습니다.
						<KeyboardArrowDownIcon
							style={{ rotate: isOpenList ? '180deg' : '0deg', cursor: 'pointer' }}
							stroke='#292A2B'
						/>
					</Stack>
					{isOpenList && (
						<Box sx={{ flex: 1, overflow: 'auto' }}>
							{list.slice(0, isOpenList ? list.length : 1).map((item, index) => (
								<Item
									key={index}
									onClick={() => {
										onClickContent(item);
										navigate(getSafeUrl(`/content/${item.contentId}`));
									}}
								>
									{drawContentTypeV2(auth, item, 24)}
									<Typography
										variant='h6'
										style={{
											fontWeight: '500',
											color: '#28323C',
											fontSize: '16px',
											fontFamily: 'Noto Sans KR',
											marginRight: 'auto',
											display: 'block',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											flex: 1,
										}}
									>
										{item.editedTitle ?? item.title}
									</Typography>

									<Typography
										variant='h6'
										style={{
											width: '80px',
											fontWeight: '500',
											color: '#4D5256',
											fontSize: '16px',
											fontFamily: 'Noto Sans KR',
										}}
									>
										{dayjs(item.createAt).locale('ko').format('LT')}
									</Typography>
								</Item>
							))}
						</Box>
					)}
				</Box>
			</>
		);
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
				<DateCalendar
					value={selectedDate}
					onChange={newDate => setSelectedDate(newDate)}
					onMonthChange={handleMonthChange}
					slots={{
						calendarHeader: props => (
							<CustomCalendarHeader
								{...props}
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
							/>
						),
						day: CustomDay,
					}}
					dayOfWeekFormatter={date => {
						const day = dayjs(date).day();
						return (
							<span
								style={{
									display: 'inline-block',
									width: '44px',
									height: '30px',
									fontSize: '16px',
									fontWeight: 500,
									color: day === 0 ? 'red' : 'inherit',
								}}
							>
								{date.format('dd')}
							</span>
						);
					}}
					showDaysOutsideCurrentMonth
					sx={{
						width: '100%',
						height: `calc(100% - ${CALENDAR_FOOTER_HEIGHT}px)`,
						maxHeight: 'none',

						'& .MuiMonthCalendar-root': {
							width: '100% !important',
							height: '100% !important',
						},

						'& .MuiYearCalendar-root': {
							width: '100% !important',
							height: '100% !important',
							maxHeight: '100%',
						},

						'& .MuiDayCalendar-root': {
							height: '100%',
						},

						'& .MuiDateCalendar-viewTransitionContainer': {
							height: `calc(100% - ${CALENDAR_HEADER_HEIGHT}px)`,
						},

						'& .MuiDateCalendar-viewTransitionContainer > div': {
							height: '100%',
						},

						'& .MuiPickersYear-yearButton.Mui-selected': {
							color: '#fff',
						},

						'& .MuiPickersMonth-root': {
							height: '50px !important',
						},

						'& .MuiPickersSlideTransition-root': {
							height: `calc(100% - (${CALENDAR_DAY_WEEK_HEIGHT}px + 20px))`,
							minHeight: '0',
							overflow: 'hidden',
						},

						'& .MuiDayCalendar-header': {
							height: CALENDAR_DAY_WEEK_HEIGHT,
							justifyContent: 'space-around',
						},

						'& .MuiDayCalendar-weekDayLabel': {
							height: CALENDAR_DAY_WEEK_HEIGHT,
						},

						'& .MuiPickersDay-root': {
							width: '30px',
							height: '30px !important',
							margin: '9px',
							// padding: '10px !important',
							fontSize: '16px',
							fontWeight: '500',
							boxSizing: 'border-box',
							position: 'relative',
							fontSize: `16px !important`,
							flexShrink: 0,
						},
						'& .MuiPickersMonth-monthButton': {
							width: '75px',
							height: '75px',
						},
						'& .MuiPickersMonth-monthButton.Mui-selected': {
							color: 'white', // 선택된 텍스트 색상
						},
					}}
				/>
			</LocalizationProvider>
			{/* <Divider /> */}
			<Box
				sx={{
					width: `calc(100% - 20px)`,
					background: '#fff',
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					margin: `0px 10px`,
					border: '1px solid #CED3D6',
					boxSizing: 'border-box',
					overflow: 'hidden',
					position: 'absolute',
					top: `calc(100% - (${CALENDAR_FOOTER_HEIGHT}px + 10px))`,
					zIndex: 1,
					borderRadius: '4px',
					boxSizing: 'border-box',
					alignItems: 'flex-start',
					justifyContent: 'center',
					// padding: `0 10px`,
				}}
			>
				<Stack spacing={1} sx={{ width: '100%' }}>
					{isExistContentInDay() ? (
						drawContentListV2()
					) : (
						<Box
							sx={{
								width: '100%',
								fontSize: 16,
								fontWeight: 700,
								fontFamily: 'Noto Sans KR',
								color: '#28323C',
								// display: 'flex',
								// flexDirection: 'column',
								// alignItems: 'center',
								// justifyContent: 'center',
								// gap: '5px',
							}}
						>
							등록된 회의록이 없습니다.
							{/* <CalendarIcon width='32px' height='32px' />
							<Typography
								variant='h6'
								style={{
									fontWeight: '700',
									color: '#28323C',
									fontSize: '18px',
									fontFamily: 'Noto Sans KR',
								}}
							>
								회의록이 없습니다.
							</Typography>
							<Typography
								variant='p'
								style={{
									fontWeight: '500',
									color: '#878D91',
									fontSize: '14px',
									fontFamily: 'Noto Sans KR',
								}}
							>
								선택된 날짜에 등록된 회의록이 없습니다.
							</Typography> */}
						</Box>
					)}
				</Stack>
			</Box>
		</Box>
	);
};

export default HomeCalendar;
