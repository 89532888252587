import React, { useEffect } from 'react';
import { Box, Button, Divider, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { formatDateToModern, formatMillisecondsToTime } from '../../util/Util';

import { ReactComponent as AddIcon } from './img/inbox_add.svg';
import { ReactComponent as DeleteIcon } from './img/inbox_delete.svg';
import { ReactComponent as EditIcon } from './img/inbox_edit.svg';
import { ReactComponent as ErrorIcon } from './img/inbox_error.svg';
import { ReactComponent as ImportIcon } from './img/inbox_import.svg';
import { ReactComponent as RestoreIcon } from './img/inbox_restore.svg';

import { ReactComponent as ItemDeleteIcon } from './img/item_delete.svg';

import { drawContentTypeV2 } from '../../components/common/ContentIcon/ContentIcon';
import useAuthStore from '../../store/AuthStore';
import ModalConfirm from '../../components/common/modal/ModalConfirm';

const InboxItem = ({ item, onClick, onChanged }) => {
	const { auth } = useAuthStore();

	const drawTime = time => (
		<Typography
			variant='p'
			style={{ fontWeight: 'normal', color: '#4D5256', fontSize: '18px', fontFamily: 'Noto Sans KR' }}
		>
			{formatDateToModern(time)}
		</Typography>
	);

	const drawTitle = title => (
		<Typography
			variant='p'
			style={{ fontWeight: 'normal', color: '#878D91', fontSize: '18px', fontFamily: 'Noto Sans KR' }}
		>
			{title}
		</Typography>
	);

	const drawValue = value => (
		<Typography
			variant='p'
			style={{ fontWeight: 'normal', color: '#4D5256', fontSize: '18px', fontFamily: 'Noto Sans KR' }}
		>
			{value}
		</Typography>
	);

	const getActionIcon = () => {
		try {
			if (item) {
				if (item.captureAction.includes('UPDATE_')) {
					return <EditIcon />;
				} else {
					switch (item.captureAction.toUpperCase()) {
						case 'CREATE':
							return <AddIcon />;

						case 'RECYCLE':
							return <DeleteIcon />;

						case 'RESTORE':
							return <RestoreIcon />;

						case 'SHARED':
							return <ImportIcon />;

						case 'RECOG':
						case 'LLM':
							return <ErrorIcon />;
					}
				}
			}
		} catch (e) {
			console.error(e);
			return <>Exception</>;
		}
	};

	const getActionTitle = () => {
		try {
			if (item) {
				if (item.captureAction.includes('UPDATE_')) {
					return '회의록이 수정되었습니다.';
				} else {
					switch (item.captureAction.toUpperCase()) {
						case 'CREATE':
							return '회의록이 생성되었습니다.';

						case 'RECYCLE':
							return '회의록이 삭제되었습니다.';

						case 'RESTORE':
							return '회의록이 복구되었습니다.';

						case 'SHARED':
							return '회의록을 공유받았습니다.';

						case 'RECOG':
						case 'LLM':
							return '음성인식 오류가 발생했습니다.';
					}
				}
			}
		} catch (e) {
			console.error(e);
			return <>Exception</>;
		}
	};

	return (
		<Box
			sx={{
				cursor: 'pointer',
			}}
		>
			<Stack spacing={1} direction='row' onClick={() => onClick && onClick(item)}>
				<Box
					sx={{
						width: 44,
						height: '100%',
						opacity: item.isRead ? 0.5 : 1,
						transition: 'opacity 0.3s',
					}}
				>
					<SvgIcon
						component='svg'
						inheritViewBox
						sx={{
							width: 35,
							height: 32,
						}}
					>
						{getActionIcon()}
					</SvgIcon>
				</Box>
				<Box sx={{ flex: 1, height: '100%', opacity: item.isRead ? 0.5 : 1, transition: 'opacity 0.3s' }}>
					<Stack spacing={1} direction={'row'} sx={{ flex: 1 }}>
						<Typography
							sx={{
								fontSize: 20,
								fontWeight: 600,
								color: '#28323C',
								fontFamily: 'Noto Sans KR',
								lineHeight: 1,
							}}
						>
							{getActionTitle()}
						</Typography>
						<Typography
							sx={{
								fontSize: 16,
								fontWeight: 500,
								color: '#878D91',
								fontFamily: 'Noto Sans KR',
								textAlign: 'center',
								display: 'flex',
								// lineHeight: 1.3,
								paddingLeft: '16px',
							}}
						>
							{formatDateToModern(item.createAt)}
						</Typography>
					</Stack>

					<Stack spacing={1} direction='row' sx={{ marginTop: '12px' }}>
						{drawTitle('회의록명')}
						{drawContentTypeV2(auth, { ...item, type: item.contentType }, 20, false, true)}
						{drawValue(item.title)}
						<Divider orientation='vertical' flexItem />
						{drawTitle('소요 시간')}
						{drawValue(formatMillisecondsToTime(item.duration))}
						<Divider orientation='vertical' flexItem />
						{drawTitle('참석자')}
						{drawValue(`${item.attendeeCount || 0}명`)}
					</Stack>
				</Box>
				<ModalConfirm
					title={'알림 메시지를 삭제할까요?'}
					description='알림메시지 목록에서 삭제됩니다.'
					onConfirm={() => onChanged && onChanged({ type: 'delete', item })}
				>
					<IconButton
						sx={{
							width: '40px',
							height: '40px',
							border: '1px solid #E1E4E6',
							borderRadius: '8px',
						}}
						onClick={e => e.stopPropagation()}
					>
						<ItemDeleteIcon />
					</IconButton>
				</ModalConfirm>
			</Stack>
		</Box>
	);
};

export default InboxItem;
