import { Box, Stack, Typography } from '@mui/material';

const Welcome = () => {
	return (
		<>
			<Stack
				direction='row' // 한 줄로 배치
				sx={{
					paddingBottom: '16px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						padding: '16px',
						justifyContent: 'space-between',
						alignItems: 'center', // 수직 정렬
						alignSelf: 'stretch',
						borderRadius: '16px',
						background: '#F3F6FB ',
						height: '132px',
					}}
				>
					<Box
						className='logo'
						sx={{
							display: 'flex',
							alignItems: 'center', // 수직 가운데 정렬
							justifyContent: 'center', // 수평 가운데 정렬
							width: '80px', // 고정 너비
							height: '100%', // 높이 영역 차지
						}}
					>
						<Box
							component='img'
							src='/asset/images/aibiz.png' // 이미지 파일 경로
							alt='AI Biz'
							sx={{
								width: '54px', // 이미지 너비 조정
								height: '45px', // 이미지 높이 조정
							}}
						/>
					</Box>
					<Box className='text' sx={{ flex: 1 }}>
						<Typography
							sx={{
								color: '#292A2B',
								fontFamily: 'Noto Sans KR',
								fontSize: '16px',
								fontStyle: 'normal',
								fontWeight: '700',
								lineHeight: '24px',
							}}
						>
							안녕하세요.
						</Typography>
						<Typography sx={{ display: 'flex' }}>
							<Typography
								sx={{
									color: '#171EA9',
									fontFamily: 'Noto Sans KR',
									fontSize: '16px',
									fontStyle: 'normal',
									fontWeight: '700',
									lineHeight: '24px',
								}}
							>
								A.Biz 회의록 챗봇
							</Typography>
							<Typography
								sx={{
									color: '#292A2B',
									fontFamily: 'Noto Sans KR',
									fontSize: '16px',
									fontStyle: 'normal',
									fontWeight: '700',
									lineHeight: '24px',
								}}
							>
								입니다.
							</Typography>
						</Typography>
						<Typography
							sx={{
								color: '#292A2B',
								fontFamily: 'Noto Sans KR',
								fontSize: '16px',
								fontStyle: 'normal',
								fontWeight: '700',
								lineHeight: '24px',
							}}
						>
							무엇을 도와드릴까요?
						</Typography>
						<Typography
							sx={{
								marginTop: '8px',
								color: '#4D5256',
								fontFamily: 'Noto Sans KR',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: '500',
								lineHeight: '20px',
								letterSpacing: `-0.1px`,
							}}
						>
							회의록에 대해 궁금한 내용을 입력해 주세요
						</Typography>
					</Box>
				</Box>
			</Stack>
		</>
	);
};

export default Welcome;
