import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalessPopup = ({ isOpen, onClose, children }) => {
	const [position, setPosition] = useState({ x: 0, y: 0 }); // 초기값은 (0, 0)
	const [isDragging, setIsDragging] = useState(false);
	const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

	// 팝업 초기 위치를 브라우저 우측 하단으로 설정
	useEffect(() => {
		const initialX = window.innerWidth - 480; // 팝업 너비(390px) + 여백(50px)
		const initialY = window.innerHeight - 780; // 팝업 높이(690px) + 여백(50px)
		setPosition({ x: initialX, y: initialY });
	}, []);

	if (!isOpen) return null;

	// 팝업 스타일
	const popupStyle = {
		position: 'fixed',
		left: `${position.x}px`,
		top: `${position.y}px`,
		width: '390px',
		height: '720px',
		background: '#fff',
		boxShadow: '2px 4px 28px 0px rgba(0, 0, 0, 0.15)',
		borderRadius: '16px',
		zIndex: 1000,
		padding: '12px 16px',
		cursor: isDragging ? 'grabbing' : 'grab',
	};

	const handleMouseDown = e => {
		setIsDragging(true);
		setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
	};

	const handleMouseMove = e => {
		if (!isDragging) return;
		setPosition({ x: e.clientX - startPosition.x, y: e.clientY - startPosition.y });
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	return ReactDOM.createPortal(
		<div
			style={popupStyle}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseUp} // 마우스가 팝업 바깥으로 나갈 때 드래그 중지
		>
			{children}
		</div>,
		document.body // 팝업을 부모 DOM 외부로 렌더링
	);
};

export default ModalessPopup;
