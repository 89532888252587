import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimbloLogo from './img/sk-poc-logo.png';
import { LogoImage } from './LoginComponents';
import ConfirmButton from '../../components/button/ConfirmButton';
import axios from 'axios';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import Input from './components/Input/Input';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getSafeUrl } from '../../util/Util';

const FindPassword = () => {
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const [email, setEmail] = useState(urlParams.get('email'));
	const [step, setStep] = useState(1); //default : 1
	const [accessToken, setAccessToken] = useState(null);
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [emailCertifyCode, setEmailCertifyCode] = useState(null);
	const [errorCode, setErrorCode] = useState(null);
	const [isResend, setResend] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const [isEmailError, setEmailError] = useState(false);
	const [isCodeError, setCodeError] = useState(false);

	function isValidEmail(email) {
		// 이메일 형식을 검증하기 위한 정규 표현식
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	useEffect(() => {
		console.log('accessToken:', accessToken);
	}, [accessToken]);

	useEffect(() => {
		setEmailError(false);
		setErrorCode(null);
	}, [email]);

	useEffect(() => {
		// Step이 변경될 때 히스토리 상태를 추가
		if (step > 1) {
			window.history.pushState({ step }, `Step ${step}`, `#step${step}`);
		}

		// 뒤로가기 이벤트 핸들러
		const handlePopState = () => {
			if (step > 1) {
				setStep(prevStep => Math.max(prevStep - 1, 1));
			} else {
				// step이 1일 때 뒤로가기를 허용하지 않음
				window.history.back(); // 혹은 기본 동작만 유지
			}
		};

		// popstate 이벤트 리스너 등록
		window.addEventListener('popstate', handlePopState);

		return () => {
			// 컴포넌트 언마운트 시 이벤트 리스너 제거
			window.removeEventListener('popstate', handlePopState);
		};
	}, [step, setStep]);

	const onEmailCertifyRequest = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/timblo/certified?t=${Date.now()}`,
					{ email },
					{
						headers: {
							'Content-Type': 'application/json',
							'x-referer': '/account/recovery',
						},
					}
				);

				const { httpCode, data } = response.data;
				if (200 === httpCode) {
					setAccessToken(response.data.data.accessToken);
				}

				console.log('[onEmailCertifyRequest]', response.data);
				resolve(httpCode);
			} catch (error) {
				console.error(error);
				resolve(400);
			}
		});
	};

	const onResendCertifyCode = async () => {
		if (accessToken) {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/timblo/recertified`,
					{},
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
							'x-referer': '/account/recovery',
						},
					}
				);

				if (response.data.httpCode !== 200) {
					switch (response.data.httpCode) {
						case 429:
							setErrorCode(`인증코드 재발송 횟수를 초과하였습니다.\n5분 후에 다시 시도해 주세요.`);
							break;
						default:
							setErrorCode(`코드 발송이 실패하였습니다. (${response.data.httpCode})`);
							break;
					}
				} else {
					setResend(true);
				}
			} catch (error) {
				console.error('Resend failed', error);
				setErrorCode('코드 발송이 실패하였습니다. (-300)');
			}
		} else {
			console.error('accessToken not found');
			setErrorCode('코드 발송이 실패하였습니다. (-400)');
		}
	};

	const onEmailCertify = async () => {
		return new Promise(async (resolve, reject) => {
			if (accessToken) {
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_URL}/auth/timblo/verification`,
						{ code: emailCertifyCode },
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${accessToken}`,
							},
						}
					);

					const { httpCode } = response.data;
					resolve(httpCode);
				} catch (error) {
					console.error(error);
					resolve(400);
				}
			}
		});
	};

	const passwordChange = async () => {
		return new Promise(async (resolve, reject) => {
			if (accessToken) {
				try {
					const response = await axios.put(
						`${process.env.REACT_APP_API_URL}/auth/timblo/change`,
						{ pass: password, passConfirm: passwordConfirm },
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${accessToken}`,
							},
						}
					);
					resolve(response.data.httpCode);
				} catch (e) {
					resolve(400);
				}
			} else {
				reject();
			}
		});
	};

	const drawProgress = () => {
		return (
			<Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
				{[1, 2, 3, 4].map((item, index) => (
					<div
						key={index}
						style={{
							width: '32px',
							height: '4px',
							backgroundColor: item <= step ? '#1C6EFF' : '#E1E4E6',
							border: 'none', // 불필요한 테두리 제거
							padding: '0', // 내부 여백 제거
							margin: '0', // 외부 여백 제거
						}}
					/>
				))}
			</Box>
		);
	};

	const isValidPassword = password => {
		// 조건: 8~20자, 국문/영문, 숫자, 특수문자를 포함
		const lengthRegex = /^.{8,20}$/; // 길이가 8~20자인지 확인
		const combinationRegex = /(?=.*[A-Za-z가-힣])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`_+=-])/; // 영문/한글, 숫자, 특수문자 포함 여부

		// 모든 조건을 만족하는지 확인
		const isValid = lengthRegex.test(password) && combinationRegex.test(password);

		return isValid;
	};

	const drawTitle = () => {
		let first, second;
		switch (step) {
			case 1:
				if (email && isValidEmail(email)) {
					first = '이메일 인증을 요청해 주세요.';
					second = '입력하신 이메일 주소로 인증코드가 전송됩니다.';
				} else {
					first = '비밀번호를 찾으려는';
					second = '아이디(이메일)를 입력해 주세요.';
				}
				break;
			case 2:
				first = '이메일로 전송된 인증코드를 입력해 주세요.';
				second = '인증이 완료되면 비밀번호 재설정을 진행합니다.';
				break;
			case 3:
				first = '비밀번호 재설정을 위해';
				second = '새 비밀번호를 입력해 주세요.';
				break;
		}

		return (
			<>
				<Stack sx={{ height: '40px' }}></Stack>
				{4 === step ? (
					<>
						<CheckCircleIcon
							sx={{
								color: '#1C6EFF',
								width: '96px',
								height: '96px',
								marginTop: '24px',
								marginBottom: '16px',
							}}
						/>
						<Typography
							sx={{
								textAlign: 'center',
								color: '#292A2B',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontSize: '26px',
								fontWeight: '700',
								lineHeight: '34px',
								textTransform: 'capitalize',
							}}
						>
							{'비밀번호가 성공적으로 변경되었습니다!'}
						</Typography>
						<Stack sx={{ height: '24px' }} />
					</>
				) : (
					<>
						{' '}
						<Typography
							sx={{
								textAlign: 'center',
								color: '#292A2B',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontSize: '26px',
								fontWeight: '700',
								lineHeight: '34px',
								textTransform: 'capitalize',
							}}
						>
							{first}
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								color: '#292A2B',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontSize: '26px',
								fontWeight: '700',
								lineHeight: '34px',
								textTransform: 'capitalize',
							}}
						>
							{second}
						</Typography>
						<Stack sx={{ height: '24px' }} />
					</>
				)}
			</>
		);
	};

	const drawFirstStep = () => {
		return (
			<Stack>
				<Stack>
					<Typography
						sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#374553' }}
					>
						아이디
					</Typography>
				</Stack>
				<Stack sx={{ height: '48px' }}>
					<Input
						sx={{ flex: 1 }}
						type='text'
						value={email}
						placeholder={'이메일을 입력해 주세요.'}
						setValue={setEmail}
						error={isEmailError}
					/>
				</Stack>
				{'' !== errorCode && (
					<>
						<Stack sx={{ height: '12px' }} />
						<Stack>
							<Typography
								sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: 'red' }}
							>
								{errorCode}
							</Typography>
						</Stack>
					</>
				)}
				<Stack sx={{ height: '24px' }}></Stack>
				<Stack>
					<ConfirmButton
						label={'이메일 인증 요청하기'}
						isActive={email && isValidEmail(email)}
						onClick={async () => {
							try {
								const code = await onEmailCertifyRequest();
								if (200 === code) {
									//성공
									setStep(2);
									setErrorCode('');
								} else if (201 === code) {
									setEmailError(true);
									setErrorCode('가입된 이메일 계정이 없습니다.');
								}
							} catch (e) {
								console.error(e);
								setErrorCode('인증 요청 중 오류가 발생했습니다. (-400)');
							}
						}}
					/>
				</Stack>
			</Stack>
		);
	};

	const drawSecondStep = () => {
		return (
			<Stack>
				<Stack sx={{}}>
					<Stack>
						<Typography
							sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#374553' }}
						>
							이메일
						</Typography>
					</Stack>
					<Input
						sx={{ flex: 1 }}
						type='text'
						value={email}
						setValue={setEmail}
						placeholder={'이메일을 입력해 주세요.'}
						disabled={true}
					/>
				</Stack>
				<Stack sx={{ height: '24px' }}></Stack>
				<Stack>
					<Typography
						sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#374553' }}
					>
						인증코드
					</Typography>
				</Stack>

				<Stack sx={{ gap: '8px' }} direction={'row'}>
					<Input
						sx={{ fontFamily: 'Noto Sans KR', flex: 1 }}
						type='text'
						error={isCodeError}
						value={emailCertifyCode}
						setValue={setEmailCertifyCode}
						placeholder={'인증코드를 입력해 주세요.'}
					/>
					<Button
						sx={{
							width: '80px',
							background: '#1C6EFF',
							color: '#FFFFFF',
							padding: '8px',
							display: 'flex',
							gap: '8px',
							borderRadius: '8px',
							fontSize: '14px',
							marginTop: '8px',
							marginBottom: '8px',
						}}
						onClick={() => {
							onResendCertifyCode();
						}}
					>
						{`재발송`}
					</Button>
				</Stack>

				{('' !== errorCode || isResend) && <Stack sx={{ height: '12px' }} />}

				{errorCode && (
					<Stack>
						<Typography
							sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#F9463B' }}
						>
							{errorCode}
						</Typography>
					</Stack>
				)}
				{isResend && (
					<Stack>
						<Typography
							sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#1C6EFF' }}
						>
							이메일로 인증코드를 발송했습니다.
						</Typography>
					</Stack>
				)}
				<Stack sx={{ height: '24px' }}></Stack>
				<Stack>
					<ConfirmButton
						label={'인증코드 인증하기'}
						isActive={emailCertifyCode}
						onClick={async () => {
							try {
								//
								const code = await onEmailCertify();
								switch (code) {
									case 200:
										setStep(3);
										setErrorCode('');
										break;
									case 201:
										setCodeError(true);
										setErrorCode('인증코드를 다시 확인해 주세요.');
										break;
									default:
										alert(`장애가 발생하였습니다. (${code})`);
										break;
								}
							} catch (e) {
								console.error(e);
								setErrorCode('인증 요청 중 오류가 발생했습니다. (-500)');
							}
						}}
					/>
				</Stack>
			</Stack>
		);
	};

	const drawThirdStep = () => {
		return (
			<Stack>
				<Stack sx={{}}>
					<Stack>
						<Typography
							sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#374553' }}
						>
							새 비밀번호
						</Typography>
					</Stack>
					<Input
						sx={{ flex: 1 }}
						type='password'
						value={password}
						setValue={setPassword}
						error={'' !== password && !isValidPassword(password)}
						placeholder={'비밀번호를 입력해 주세요.'}
					/>
				</Stack>

				<Stack>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#878D91',
							fontFamily: 'Noto Sans KR',
						}}
					>
						{'*국문/영문, 숫자, 특수문자를 조합하여 8~20자로 입력해주세요.'}
					</Typography>
				</Stack>

				{'' !== password && !isValidPassword(password) && (
					<Stack sx={{ marginTop: '12px' }}>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{'비밀번호를 다시 확인해 주세요.'}
						</Typography>
					</Stack>
				)}

				<Stack sx={{ height: '24px' }}></Stack>
				<Stack sx={{}}>
					<Stack>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#374553', fontFamily: 'Noto Sans KR' }}
						>
							새 비밀번호 확인
						</Typography>
					</Stack>
					<Input
						sx={{ flex: 1 }}
						type='password'
						value={passwordConfirm}
						error={isValidPassword(password) && '' !== passwordConfirm && password !== passwordConfirm}
						setValue={setPasswordConfirm}
						placeholder={'비밀번호를 한 번 더 입력해 주세요.'}
					/>
				</Stack>

				{isValidPassword(password) && '' !== passwordConfirm && password !== passwordConfirm && (
					<Stack sx={{ marginTop: '12px' }}>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{'비밀번호가 일치하지 않습니다.'}
						</Typography>
					</Stack>
				)}

				{'' !== errorCode && (
					<Stack>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{errorCode}
						</Typography>
					</Stack>
				)}
				<Stack sx={{ height: '24px' }}></Stack>
				<Stack>
					<ConfirmButton
						label={'비밀번호 변경하기'}
						isActive={emailCertifyCode}
						isLoading={isLoading}
						onClick={async () => {
							try {
								setLoading(true);
								const code = await passwordChange();
								if (200 === code) {
									//성공
									setStep(4);
								} else {
									setErrorCode(`비밀번호 변경이 실패하였습니다. (${code})`);
								}
								setLoading(false);
							} catch (e) {
								console.error(e);
								setErrorCode('인증 요청 중 오류가 발생했습니다. (-600)');
								setLoading(false);
							}
						}}
					/>
				</Stack>
			</Stack>
		);
	};

	const drawLastStep = () => {
		return (
			<Stack>
				<ConfirmButton
					label={'로그인 하기'}
					isActive={true}
					onClick={() => {
						navigate(getSafeUrl('/login?email=') + email);
					}}
				/>
			</Stack>
		);
	};

	const drawStep = () => {
		switch (step) {
			case 1:
				return drawFirstStep();
			case 2:
				return drawSecondStep();
			case 3:
				return drawThirdStep();
			case 4:
				return drawLastStep();
		}
	};

	return (
		<Box>
			<Stack direction='row' alignItems='flex-start'>
				<a href='/login' style={{ display: 'inline-block' }}>
					<LogoImage
						loading='lazy'
						src={TimbloLogo}
						alt='Timblo Logo'
						style={{
							width: '77px',
							height: '44px',
							marginLeft: '30px',
							marginTop: '20px',
							cursor: 'pointer',
						}}
					/>
				</a>
			</Stack>
			<Stack
				sx={{
					marginTop: '140px',
				}}
			>
				<Box
					className='view'
					style={{
						width: '600px',
						height: '800px',
						margin: '0 auto',
						Position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					{drawProgress()}
					{drawTitle()}
					<Box sx={{ width: '320px' }}>{drawStep()}</Box>

					<Stack sx={{ height: '12px' }}></Stack>
					<Stack
						sx={{
							width: '320px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between', // 왼쪽과 오른쪽 정렬
							alignItems: 'center', // 수직 정렬
						}}
					>
						{4 !== step && (
							<>
								<Typography
									sx={{
										fontFamily: 'Noto Sans KR',
										fontSize: '14px',
										fontWeight: '500',
										color: '#4D5256',
									}}
								>
									비밀번호가 기억나셨나요?
								</Typography>
								<Typography
									component='a'
									href='/login' // 원하는 링크 주소
									sx={{
										fontFamily: 'Noto Sans KR',
										fontSize: '14px',
										fontWeight: '500',
										color: '#1C6EFF',
										textDecoration: 'underline', // 밑줄 추가
										cursor: 'pointer', // 클릭 가능한 포인터 표시
									}}
								>
									로그인
								</Typography>
							</>
						)}
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default FindPassword;
