import moment from 'moment';
import './RecorderV2.css';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';

import { ReactComponent as PauseV2Icon } from './img/pause_v2.svg';
import { Toast, ToastComponent, ToastError, ToastInfo } from '../../common/toast/Toast';
import { ReactComponent as RecordButtonIcon } from './img/record_button_icon.svg';
import Popup from 'reactjs-popup';
import Confirm from '../confirm/Confirm';
import Loading from 'react-loading';

import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { audioBufferToWav } from '../../../util/Util';
import BackupRecorder from './BackupRecorder';
import useAuthStore from '../../../store/AuthStore';

const BAR_COLOR = '#01A4FF';
const LEVEL_BAR_COUNT = 60;
const FORMAT = 'wav';
const LOG_GROUP = 'Recorder';
const CIRCLE_COUNT = 3;

const RecorderV2 = ({ onClose = () => {}, onRecordComplete = () => {}, children }, ref) => {
	const buttonRef = useRef();
	const recorderControls = useVoiceVisualizer();
	const [isUploading, setIsUploading] = useState(false);
	const [mediaStream, setMediaStream] = useState(null); // stream 상태 추가
	const [isMicDisconnected, setIsMicDisconnected] = useState(false);
	const audioContextRef = useRef(null);
	const [isSaveRequested, setIsSaveRequested] = useState(false);
	const [lostTime, setLostTime] = useState(0);
	const [buffers, setBuffers] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [containerPosition, setContainerPosition] = useState({ top: 0, left: 0 });
	const [startRecordTime, setStartRecordTime] = useState(null);
	const [backupState, setBackupState] = useState(0); //0: standby, 1: recording, 99: saved
	const { auth } = useAuthStore();

	const initializeAudioContext = () => {
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
		}
	};

	const initializeBuffer = () => {
		console.info('Initialize buffer', LOG_GROUP);
		setIsMicDisconnected(false);
		setBuffers([]);
		setLostTime(0);
		setIsSaveRequested(false);
		setStartRecordTime(null);
	};

	const concatenateBuffers = buffers => {
		// Ensure the audioContext is initialized before proceeding
		initializeAudioContext();

		const totalLength = buffers.reduce((sum, buffer) => sum + buffer.length, 0);
		const sampleRate = buffers[0].sampleRate; // Assuming all buffers have the same sample rate
		const combinedBuffer = audioContextRef.current.createBuffer(1, totalLength, sampleRate);

		let offset = 0;
		buffers.forEach(buffer => {
			combinedBuffer.copyToChannel(buffer.getChannelData(0), 0, offset);
			offset += buffer.length;
		});

		return combinedBuffer;
	};

	useEffect(() => {}, [auth]);

	useEffect(() => {
		if (isVisible && buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect();

			if (rect) {
				setContainerPosition({
					top: rect.bottom,
					left: rect.left,
				});
			}
		}
		console.info(`Visible changed (${isVisible})`, LOG_GROUP);
	}, [isVisible]);

	useEffect(() => {
		if (recorderControls.bufferFromRecordedBlob) {
			if (isSaveRequested) {
				console.info(`Save buffer to file`, LOG_GROUP);
				setIsUploading(true);

				// Combine the current buffer with the previously recorded buffers
				const allBuffers = [...buffers, recorderControls.bufferFromRecordedBlob];

				// Create a single audio buffer by concatenating all buffers
				const combinedBuffer = concatenateBuffers(allBuffers);

				const fileName = `A.Biz_w_rec_${dayjs(startRecordTime).format('YYYYMMDD_HHmmss')}.${FORMAT}`;
				// const pad = num => num.toString().padStart(2, '0');
				// const fileName = `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(
				// 	date.getHours()
				// )}${pad(date.getMinutes())}${pad(date.getSeconds())}_Recording.${FORMAT}`;

				convertBufferToWav(combinedBuffer)
					.then(file => {
						onRecordComplete(file, fileName);
					})
					.catch(() => {
						// Fall back to saving the original blob in case of failure
						console.error(`convert buffer to wav failed`, LOG_GROUP);
						FileSaver.saveAs(recorderControls.recordedBlob, fileName);
					})
					.finally(() => {
						console.info(`convert buffer to wav finished`, LOG_GROUP);
						recorderControls.clearCanvas();
						initializeBuffer();
						onClose();
						setIsVisible(false);
						setIsUploading(false);
						setBackupState(99);
					});
			} else {
				console.info(`Save buffer to memory`, LOG_GROUP);
				setBuffers(prevBuffers => [...prevBuffers, recorderControls.bufferFromRecordedBlob]);
			}
		}
	}, [recorderControls.bufferFromRecordedBlob]);

	// 마이크 끊김 감지 및 사용자 확인 로직
	useEffect(() => {
		if (mediaStream) {
			const tracks = mediaStream.getAudioTracks();

			// 이벤트 핸들러 정의
			const handleTrackEnded = () => {
				console.info(`Mic device disconnected`, LOG_GROUP);
				setIsMicDisconnected(true);

				mediaStream.getTracks().forEach(track => track.stop());

				// 녹음을 일시 정지
				setLostTime(lostTime + recorderControls.recordingTime);
				if (recorderControls.isRecordingInProgress && !recorderControls.isPausedRecording) {
					recorderControls.togglePauseResume();
				}
				recorderControls.stopRecording();
			};

			// 각 트랙에 이벤트 리스너 추가
			tracks.forEach(track => track.addEventListener('ended', handleTrackEnded));

			// 정리(cleanup) 함수에서 이벤트 리스너 제거
			return () => {
				tracks.forEach(track => track.removeEventListener('ended', handleTrackEnded));
			};
		}
	}, [mediaStream, recorderControls]);

	// 장치 변경 감지 및 재연결 처리
	useEffect(() => {
		const handleDeviceChange = async () => {
			if (isMicDisconnected) {
				console.info(`Device change detected, attempting reconnection`, LOG_GROUP);

				const devices = await navigator.mediaDevices.enumerateDevices();
				const hasMic = devices.some(device => device.kind === 'audioinput');

				if (hasMic) {
					ToastInfo('마이크가 다시 연결되었습니다. 녹음을 이어갑니다.');
					console.info(`Device reconnected`, LOG_GROUP);
					setIsMicDisconnected(false);

					handleRecord(true);
				}
			}
		};

		if (isMicDisconnected) {
			ToastError(0, '마이크 연결이 끊어졌습니다.');
		}

		navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

		return () => {
			navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
		};
	}, [isMicDisconnected]);

	const convertBufferToWav = async audioBuffer => {
		try {
			const wavArrayBuffer = await audioBufferToWav(audioBuffer);
			const wavBlob = new Blob([wavArrayBuffer], { type: 'audio/wav' });

			return wavBlob;
		} catch {
			ToastInfo('파일 변환에 실패하여 원본 파일을 다운로드 합니다.');
		}
	};

	const getDurationFormat = seconds => {
		seconds = Math.floor(seconds / 1000);
		const hours = Math.floor(seconds / 3600);
		seconds %= 3600;
		const minutes = Math.floor(seconds / 60);
		seconds %= 60;

		const hoursFormatted = String(hours).padStart(2, '0');
		const minutesFormatted = String(minutes).padStart(2, '0');
		const secondsFormatted = String(seconds).padStart(2, '0');

		// const result = `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
		const result = (
			<>
				<span>{hoursFormatted}</span>
				<span>:</span>
				<span>{minutesFormatted}</span>
				<span>:</span>
				<span>{secondsFormatted}</span>
			</>
		);
		return result;
	};

	const drawLevelBar = () => {
		return Array.from({ length: LEVEL_BAR_COUNT }).map((_, idx) => (
			<div key={idx} className={`bar`} style={{ background: BAR_COLOR }} />
		));
	};

	const handleRecord = (force = false) => {
		if (!force && recorderControls.isRecordingInProgress) {
			console.info('Pause/Resume recording', LOG_GROUP);
			recorderControls.togglePauseResume();
		} else {
			console.info(`Recording start`, LOG_GROUP);
			setBackupState(1);
			initializeAudioContext(); // Initialize audio context here
			setStartRecordTime(dayjs());

			navigator.mediaDevices
				.getUserMedia({
					audio: {
						noiseSuppression: false,
						echoCancellation: true,
						autoGainControl: false,
					},
				})
				.then(stream => {
					const audioContext = audioContextRef.current;
					const source = audioContext.createMediaStreamSource(stream);

					// GainNode creation (for volume control)
					const gainNode = audioContext.createGain();
					source.connect(gainNode);
					recorderControls.startRecording();
				})
				.catch(error => {
					ToastError(0, '마이크 접근 권한이 없습니다. 녹음을 시작할 수 없습니다.');
					console.error(`Mic stream access failed (${error})`, LOG_GROUP);
				});
		}
	};

	const onSaveRecord = () => {
		if (recorderControls.isRecordingInProgress) {
			setIsUploading(true);

			// MediaStream 트랙 중지 및 초기화
			if (mediaStream) {
				mediaStream.getTracks().forEach(track => track.stop());
				setMediaStream(null); // MediaStream 초기화
			}

			// AudioContext 닫기
			if (audioContextRef.current) {
				audioContextRef.current
					.close()
					.then(() => (audioContextRef.current = null))
					.catch(error => {
						console.error(`Audio context close failed (${error})`, LOG_GROUP);
					});
			}

			setBackupState(0);
			recorderControls.stopRecording();
			setIsSaveRequested(true);
		} else {
			ToastInfo('녹음된 파일이 없습니다.');
		}
	};

	const drawRecordIcon = () => {
		return (
			<div className='white-circle' onClick={() => !isUploading && handleRecord()}>
				<div className='red-circle' />
			</div>
		);
	};

	return (
		<>
			{/* <BackupRecorder state={backupState} setState={setBackupState} id={auth && auth.member.id} /> */}
			<div
				ref={buttonRef}
				id='record-v2-button'
				className='record-v2-button'
				onClick={() => setIsVisible(prev => !prev)}
			>
				{children ? (
					children
				) : (
					<>
						{recorderControls.isRecordingInProgress &&
							Array.from({ length: CIRCLE_COUNT }).map((_, index) => (
								<div
									key={index}
									className='circle'
									style={{ animationDelay: `${index * 0.3}s` }} // 원마다 애니메이션 딜레이 설정
								></div>
							))}
						<div className={`icon`}>
							<RecordButtonIcon />
						</div>
						<div className='text'>녹음</div>
					</>
				)}
			</div>
			<div
				className='record-v2-overlay'
				onClick={() => setIsVisible(false)}
				style={{ zIndex: isVisible ? 999 : -1 }}
			/>
			<div
				className='record-v2-container'
				style={{
					position: 'absolute',
					top: `${containerPosition.top}px`,
					left: `${containerPosition.left - 25}px`,
					zIndex: 1000,
					visibility: isVisible ? 'visible' : 'hidden',
				}}
			>
				<div className='title'>새로운 녹음</div>
				{/* <div className='date'>{currentDate && moment(currentDate).locale('ko').format('YYYY.MM.DD  HH:mm')}</div> */}
				<div className='duration'>
					<div className='circle' />
					<div className='time'>{getDurationFormat(lostTime + recorderControls.recordingTime)}</div>
				</div>
				<div className='wave-form'>
					{recorderControls.isRecordingInProgress ? (
						<VoiceVisualizer
							fullscreen
							controls={recorderControls}
							height={80}
							isControlPanelShown={false}
							isDefaultUIShown={false}
							mainBarColor={BAR_COLOR}
						/>
					) : (
						<div className='level-bar'>{drawLevelBar()}</div>
					)}
				</div>

				<div className='record'>
					{(recorderControls.isRecordingInProgress || isMicDisconnected) && (
						<Popup
							trigger={<button className='cancel'>취소</button>}
							position={'top left'}
							arrow={false}
							nested
						>
							{close => (
								<Confirm
									title={'녹음 파일을 삭제하시겠습니까?'}
									onCancelText='취소'
									onConfirmText='삭제'
									onCancel={() => {
										console.info(`Recording cancel button (cancle)`, LOG_GROUP);
										close();
									}}
									onConfirm={() => {
										console.info(`Recording cancel button (confirm)`, LOG_GROUP);
										recorderControls.clearCanvas();
										close();

										if (mediaStream) {
											const tracks = mediaStream.getTracks();
											tracks.forEach(track => track.stop()); // 각 트랙 중지
										}

										// Reset the audio context reference if necessary
										audioContextRef.current.close();
										audioContextRef.current = null;
										recorderControls.stopRecording();

										// mediaStream도 초기화
										setMediaStream(null);

										initializeBuffer();
									}}
								/>
							)}
						</Popup>
					)}
					{isUploading ? (
						<Loading type='spin' color={'red'} width={48} height={48} />
					) : recorderControls.isRecordingInProgress ? (
						recorderControls.isPausedRecording ? (
							drawRecordIcon()
						) : (
							<PauseV2Icon onClick={() => !isUploading && handleRecord()} />
						)
					) : (
						drawRecordIcon()
					)}
					{(recorderControls.isRecordingInProgress || isMicDisconnected) && (
						<Popup
							trigger={<button className='save'>저장</button>}
							position={'top left'}
							arrow={false}
							nested
						>
							{close => (
								<Confirm
									type='question'
									title={'녹음 파일을 저장하시겠습니까?'}
									onCancelText='취소'
									onConfirmText='저장'
									buttonStyle={{ background: '#005CFF' }}
									onCancel={() => {
										console.info(`Recording save button (cancel)`, LOG_GROUP);
										initializeBuffer();
										close();
									}}
									onConfirm={() => {
										console.info(`Recording save button (confirm)`, LOG_GROUP);
										onSaveRecord();
										close();
									}}
								/>
							)}
						</Popup>
					)}
				</div>
			</div>
		</>
	);
};

export default RecorderV2;
