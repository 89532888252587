import axios from 'axios';
import { debounce } from 'lodash';
import { create } from 'zustand';

const CorrectionStore = create((set, get) => {
	const debouncedSetSaveData = debounce((index, updatedTexts) => {
		const { editedCorrectTextData, saveData, setRequestDataForm } = get();

		const pushEditData = {
			segmentId: editedCorrectTextData[index]?.segmentId,
			text: updatedTexts,
		};
		const existingIndex = saveData.findIndex(item => item.segmentId === pushEditData.segmentId);

		if (existingIndex !== -1) {
			saveData[existingIndex].text = updatedTexts;
		} else {
			saveData.push(pushEditData);
		}

		console.log('saveData Debounce 진행', saveData);
		set({ saveData });
		setRequestDataForm(saveData);
	}, 300);

	return {
		resetCorrectionStore: () => {
			set({
				correctModalOriginData: [],
				correctModalSpeakerInfo: [],
				initCollectModalData: [],
				correctModalData: [],
				allCheckStatus: true,
				originSplitTextData: [],
				splitTextData: [],
				filteredData: [],
				editedCorrectTextData: [],
				filteredSplitTextData: [],
				sttRowCorrectData: [],
				aiCorrectListData: [],
				history_version: '',
				getResponseData: [],
				sttRowOriginData: [],
				correctEditorOriginData: [],
				sttRowFilteredData: [],
				correctEditorFilteredData: [],
				isCorrectingData: [],
				requestDataForm: [],
				saveData: [],
				searchKeyword: '',
				matchIndices: [],
				searchKeywordResult: {
					currentIndex: 0,
					total: 0,
				},
				dictionaryData: '',
			});
		},

		//******** 최초 데이터 Start */
		// Modal 원본
		correctModalOriginData: [],
		setCorrectModalOriginData: data => set({ correctModalOriginData: data }),

		// 원본 SpeakerInfo
		correctModalSpeakerInfo: [],
		setCorrectModalSpeakerInfo: speakerInfo => set({ correctModalSpeakerInfo: speakerInfo }),

		// Modal 원본 + 속성 추가 데이터
		initCollectModalData: [],
		setInitCollectModalData: data => {
			const initModalData = data.map(item => ({ ...item, collectChecked: true, filtered: true }));
			set({ initCollectModalData: initModalData });
			const {
				setSplitTextData,
				setSttRowCorrectData,
				splitTextByCorrectRanges,
				setCorrectModalData,
				setEditedCorrectTextData,
			} = get();
			setCorrectModalData(initModalData);
			setSplitTextData(splitTextByCorrectRanges(initModalData, []));
			setSttRowCorrectData(splitTextByCorrectRanges(initModalData, []));
			setEditedCorrectTextData(initModalData);
		},
		// 수정가능한 Modal 원본 + 속성 추가 데이터 -----> 주 데이터
		correctModalData: [],
		setCorrectModalData: data => {
			const { setFilteredData, setAllCheckStatus, setSearchTextData } = get();
			set({ correctModalData: data });
			setSearchTextData(data);

			setFilteredData(data.filter(item => item.filtered));
			setAllCheckStatus(data.every(item => item.collectChecked));
		},

		//******** 최초데이터 END */

		//******** 수정Data Start */
		// allCheck유무
		allCheckStatus: true,
		setAllCheckStatus: state => set({ allCheckStatus: state }),
		// Ai 버튼으로 요청 받은데이터를 해당 데이터에 넣는다...(초기화용 데이터)
		originSplitTextData: [],
		setOriginSplitTextData: data => {
			console.log('originSplitTextData', data);
			set({ originSplitTextData: data });
		},
		// Modal 원본 + 속성 &&{해당 속성 데이터 변경}추가 데이터 배열로 분리
		// 추후 Ai교정 res데이터 받아서 적용함함
		splitTextData: [],
		setSplitTextData: data => {
			const { setFilteredSplitTextData } = get();
			set({ splitTextData: data });
			setFilteredSplitTextData(data);
		},

		// 좌측에서
		filteredData: [],
		setFilteredData: data => set({ filteredData: data }),

		editedCorrectTextData: [],
		setEditedCorrectTextData: data => set({ editedCorrectTextData: data }),

		// 실제 필터된 데이터
		filteredSplitTextData: [],
		setFilteredSplitTextData: data => set({ filteredSplitTextData: data }),

		// Modal 원본 + 속성 추가 데이터 ==> sttRow 수정데이터
		// 추후 Ai교정 res데이터 받아서 적용함함
		sttRowCorrectData: [],
		setSttRowCorrectData: data => {
			set({ sttRowCorrectData: data });
		},

		// AI교정 Res데이터 -> Editor로 전달달
		aiCorrectListData: [],
		setAiCorrectListData: data => {
			console.trace('aiCorrectListData store', data);
			set({ aiCorrectListData: data });
		},

		history_version: '',
		setHistory_version: data => set({ history_version: data }),

		// 무시 하는 경우 AI data 적용 함수
		ignoreAiCorrectData: (data, chunk_idx, chunk_correction_idx) => {
			return data.map(chunk => {
				if (Number(chunk.chunk_idx) === Number(chunk_idx)) {
					return {
						...chunk,
						corrections: chunk.corrections.map((correction, idx) =>
							Number(idx) !== Number(chunk_correction_idx)
								? correction
								: { ...correction, isIgnored: true }
						),
					};
				}
				return chunk;
			});
		},

		// 무시 하는 경우 SplitText data 적용 함수
		ignoreSplitTextData: (data, chunk_idx, chunk_correction_idx) => {
			return data.map(chunk => ({
				...chunk,
				texts: chunk.texts.map(text => ({
					...text,
					isIgnored:
						Number(chunk.chunk_idx) === Number(chunk_idx) &&
						text.type === 'range' &&
						Number(text.arrIndex) === Number(chunk_correction_idx)
							? true
							: text.isIgnored,
					type:
						Number(chunk.chunk_idx) === Number(chunk_idx) &&
						text.type === 'range' &&
						Number(text.arrIndex) === Number(chunk_correction_idx)
							? 'other'
							: text.type,
				})),
			}));
		},

		//******** 수정Data End */

		sttRowOriginData: [],
		correctEditorOriginData: [],

		sttRowFilteredData: [],
		correctEditorFilteredData: [],

		// AI 교정 클릭 후 교정 여부(적용, 미적용)
		isCorrectingData: [],
		setIsCorrectingData: data => set({ isCorrectingData: data }),

		//Get API 데이터 Set
		getResponseData: [],
		setGetResponseData: responseData => {
			console.log('setGEtRepo', responseData);
			const { setAiCorrectListData } = get();
			set({
				getResponseData: responseData.data.data.result.map(chunk => {
					const { correctModalSpeakerInfo, correctModalOriginData } = get(); // 상태 가져오기
					return {
						...chunk,
						name:
							correctModalSpeakerInfo[
								correctModalSpeakerInfo.findIndex(
									spk => spk.speakerId === correctModalOriginData[chunk.chunk_idx].speakerId
								)
							]?.displayName ??
							correctModalSpeakerInfo[
								correctModalSpeakerInfo.findIndex(
									spk => spk.speakerId === correctModalOriginData[chunk.chunk_idx].speakerId
								)
							]?.name,
						corrections: chunk.corrections.map(correction => ({
							...correction,
							isApplied: false,
							isIgnored: false,
							isFocused: false,
							isFiltered: true,
						})),
					};
				}),
			});

			setAiCorrectListData(
				responseData.data.data.result.map(chunk => {
					const { correctModalSpeakerInfo, correctModalOriginData } = get(); // 상태 가져오기
					return {
						...chunk,
						name:
							correctModalSpeakerInfo[
								correctModalSpeakerInfo.findIndex(
									spk => spk.speakerId === correctModalOriginData[chunk.chunk_idx].speakerId
								)
							]?.displayName ??
							correctModalSpeakerInfo[
								correctModalSpeakerInfo.findIndex(
									spk => spk.speakerId === correctModalOriginData[chunk.chunk_idx].speakerId
								)
							]?.name,
						corrections: chunk.corrections.map(correction => ({
							...correction,
							isApplied: false,
							isIgnored: false,
							isFocused: false,
							isFiltered: true,
						})),
					};
				})
			);
		},

		getCorrectionContents: async (accessToken, contentId, correctionData, callback) => {
			try {
				// 실제 API 받아올때 사용
				const { setHistory_version } = get();
				const headers = {
					Authorization: `Bearer ${accessToken}`,
					// Authorization: `Bearer cm51w6s5x00001molpymzg6tm`,
					// 'Content-Type': 'application/json',
				};
				// 수정 Todo : api 주소 변경 필요
				const response = await axios.post(
					`${process.env.REACT_APP_SK_API_HOST}/llm_api/correction`,
					{
						chunks: correctionData,
						meeting_id: contentId,
					},
					{
						headers,
					}
				);
				console.log('Post response', response);
				if (response && response.data && response.data.result === 'SUCCESS') {
					const now = new Date();
					setHistory_version(now);
					console.log('Ai교정 success Get 함수 실행');
					return 'SUCCESS';
				} else if (response && response.data && response.data.result === 'DUPLICATED') {
					console.log('AI 교정 DUPLICATED');
				}
			} catch (e) {
				console.log('1-2 AI교정 Post APi error', e);
				return;
			}
		},

		testGetCorrectionContents: (accessToken, contentId, isOpenModal, retryInterval = 3000) => {
			return new Promise((resolve, reject) => {
				let intervalId = null;
				console.log('contentId', contentId);

				const executeRequest = async () => {
					if (isOpenModal) {
						return;
					}
					try {
						const headers = {
							Authorization: `Bearer ${accessToken}`,
							// Authorization: `Bearer cm51w6s5x00001molpymzg6tm`,
							// 'Content-Type': 'application/json',
						};
						// 수정 Todo : api 주소 변경 필요
						const response = await axios.get(
							`${process.env.REACT_APP_SK_API_HOST}/llm_api/correction`,
							{
								params: { meeting_id: contentId },
							},
							{ headers }
						);

						if (response && response.data && response.data && response.data.status === 'COMPLETED') {
							get().setGetResponseData(response);

							// 성공 시 재시도 중지
							clearInterval(intervalId);
							console.log('Request completed successfully, stopping retries.', response);

							// Promise resolve로 결과 반환
							resolve(response);
						} else if (
							response &&
							response.data &&
							response.data &&
							response.data.status === 'DUPLICATED'
						) {
						}
					} catch (error) {
						console.log('1-2 AI교정 Get APi error', error);

						// Promise reject로 에러 반환
						reject(error);
					}
				};

				// 즉시 한 번 실행
				executeRequest();

				// 지정된 간격으로 실행
				intervalId = setInterval(executeRequest, retryInterval);
			});
		},

		//교정 결과 데이터를 바탕으로 STT TEXT 데이터 구간 분리.
		splitTextByCorrectRanges: (reqData, resData) => {
			const newResData = [...resData];

			return reqData.map((data, index) => {
				let texts = [];
				let prevEnd = 0;
				let arridx = 0;

				if (index === newResData[0]?.chunk_idx) {
					const chunk = newResData.shift();

					chunk.corrections.forEach(correction => {
						if (prevEnd < correction.start_idx) {
							texts.push({
								type: 'other',
								text: data.text.slice(prevEnd, correction.start_idx),
								range: { start_idx: prevEnd, end_idx: correction.start_idx - 1 },
								isApplied: false,
								isIgnored: false,
								isFocused: false,
							});
						}

						texts.push({
							type: 'range',
							text: data.text.slice(correction.start_idx, correction.end_idx + 1),
							range: { start_idx: correction.start_idx, end_idx: correction.end_idx },
							arrIndex: arridx,
							isApplied: false,
							isIgnored: false,
							isFocused: false,
						});

						arridx++;
						prevEnd = correction.end_idx + 1;
					});

					if (prevEnd < data.text.length) {
						texts.push({
							type: 'other',
							text: data.text.slice(prevEnd),
							range: { start_idx: prevEnd, end_idx: data.text.length - 1 },
							isApplied: false,
							isIgnored: false,
							isFocused: false,
						});
					}
				} else {
					texts.push({
						type: 'other',
						text: data.text,
						range: { start_idx: 0, end_idx: data.text.length - 1 },
						isApplied: false,
						isIgnored: false,
						isFocused: false,
					});
				}

				return {
					segmentId: data.segmentId,
					chunk_idx: index,
					filtered: data.filtered,
					collectChecked: data.collectChecked,
					texts,
				};
			});
		},

		saveAiCorrectData: async (accessToken, contentId) => {
			const { aiCorrectListData, history_version } = get();

			//실제 API 요청
			const headers = {
				//Authorization: `Bearer ${accessToken}`,
				// Authorization: `Bearer cm51w6s5x00001molpymzg6tm`,
				'Content-Type': 'application/json',
			};

			// 수정 Todo : api 주소 변경 필요
			const response = await axios.post(
				`${process.env.REACT_APP_SK_API_HOST}/llm_api/correction_history`,
				{
					meeting_id: contentId,
					history_version: history_version,
					result: aiCorrectListData
						.filter(item => item.corrections.length)
						.map(item => ({
							chunk_idx: item.chunk_idx,
							timestamp: item.timestamp,
							corrections: item.corrections.map(correction => ({
								start_idx: correction.start_idx,
								end_idx: correction.end_idx,
								original_text: correction.original_text,
								correction_text: correction.correction_text,
								type: correction.type,
								type_details: correction.type_details,
								action_type:
									correction.isApplied === true
										? 'apply'
										: correction.isIgnored === true
										? 'ignore'
										: '',
							})),
						})),
				},
				{
					headers,
				}
			);

			console.log('저장완료 ', response);
		},

		requestDataForm: [],
		setRequestDataForm: data => {
			const { correctModalData, setSearchTextData } = get();
			const requestData = correctModalData.map(item => {
				const savedItem = data.find(chunk => chunk.segmentId === item.segmentId);

				if (savedItem) {
					return {
						...item,
						text: savedItem.text,
					};
				}
				return item;
			});
			set({ requestDataForm: requestData });
			setSearchTextData(requestData);
		},

		saveData: [],
		setSaveData: (index, updatedTexts) => {
			debouncedSetSaveData(index, updatedTexts);
		},

		saveAllData: async (accessToken, contentId) => {
			const { saveData, correctModalData } = get();
			const mergedArray = saveData.map(savedItem => {
				const originalItem = correctModalData.find(item => item.segmentId === savedItem.segmentId);

				if (originalItem) {
					return {
						segmentId: originalItem.segmentId,
						speakerId: originalItem.speakerId,
						startTime: originalItem.startTime,
						endTime: originalItem.endTime,
						duration: originalItem.duration,
						text: savedItem.text,
					};
				}

				return savedItem;
			});

			const headers = {
				// Authorization: `Bearer ${accessToken}`,
				// Authorization: `Bearer cm51w6s5x00001molpymzg6tm`,
				'Content-Type': 'application/json',
			};

			// 수정 Todo : api 주소 변경 필요
			const response = await axios.patch(
				`${process.env.REACT_APP_SK_API_HOST}/api/contents/${contentId}/transcription/segments/text`,
				{
					segments: mergedArray,
				},
				{
					headers,
				}
			);

			console.log('저장완료 ', response);
		},

		searchTextData: '',
		setSearchTextData: data => {
			const { setHightLightedText } = get();
			set({ searchTextData: data });

			setHightLightedText(data.map(item => [item.text]));
		},

		searchKeyword: '',
		setSearchKeyword: keyword => {
			const {
				searchTextData,
				setHightLightedText,
				setMatchIndices,
				searchKeywordResult,
				setSearchKeywordResult,
			} = get();
			set({ searchKeyword: keyword });

			if (!keyword) {
				setHightLightedText(
					searchTextData.map(item => ({
						segmentId: item.segmentId,
						parts: [item.text],
					}))
				);
				setMatchIndices([]);
				setSearchKeywordResult({
					currentIndex: 0,
					total: 0,
				});
				return;
			}

			const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
			const regex = new RegExp(`(${escapedKeyword})`, 'gi');

			const newHightLightedText = searchTextData.map(item => ({
				segmentId: item.segmentId,
				parts: item.text.split(regex),
			}));
			setHightLightedText(newHightLightedText);

			const indices = newHightLightedText.flatMap(({ parts, segmentId }, dataIndex) => {
				let currentIndex = 0;
				return parts.flatMap((part, partIndex) => {
					const results = [];
					if (part.toLowerCase() === keyword.toLowerCase()) {
						results.push({
							segmentId,
							dataIndex,
							partIndex,
							start: currentIndex,
							end: currentIndex + part.length,
						});
					}
					currentIndex += part.length;
					return results;
				});
			});

			console.log('indices', indices);
			setSearchKeywordResult({ ...searchKeywordResult, total: indices.length }); // 상태를 업데이트하는 방식으로 수정

			setMatchIndices(indices);
		},

		searchKeywordResult: {
			currentIndex: 0,
			total: 0,
		},
		setSearchKeywordResult: data => set({ searchKeywordResult: data }),

		hightLightedText: '',
		setHightLightedText: data => set({ hightLightedText: data }),

		matchIndices: [],
		totalMatches: 0,
		setTotalMatches: data => {
			set({ totalMatches: data });
		},
		setMatchIndices: data => {
			const { setTotalMatches } = get();
			set({ matchIndices: data });
			setTotalMatches(data.length);
		},

		audioSource: null,
		currentAudioRef: null,
		currentSegmentId: null,
		pausedTime: null,

		setAudioSource: source => set({ audioSource: source }),

		setCurrentAudioRef: (audioRef, segmentId) => {
			const { currentAudioRef } = get();

			// 다른 오디오가 재생 중이면 중지
			if (currentAudioRef && currentAudioRef !== audioRef) {
				currentAudioRef.pause();
				set({ pausedTime: null });
			}

			set({ currentAudioRef: audioRef, currentSegmentId: segmentId });
		},

		setPausedTime: time => set({ pausedTime: time }),

		clearPausedTime: () => set({ pausedTime: null }),

		dictionaryData: '',
		setDictionaryData: data => set({ dictionaryData: data }),
	};
});

export default CorrectionStore;
