import { Button } from '@mui/material';

const Keyword = ({ label, onClick }) => {
	return (
		<Button
			onClick={onClick}
			sx={{
				alignSelf: 'flex-start',
				display: 'flex',
				padding: '8px 16px',
				justifyContent: 'center',
				gap: '8px',
				borderRadius: '48px',
				border: '1px solid #ECEDEF',
				background: '#F3F5F7',
				textAlign: 'left',
				fontSize: 16,
				fontWeight: 500,
				fontFamily: 'Noto Sans KR',
				color: '#417EEA',
			}}
		>
			# {label}
		</Button>
	);
};

export default Keyword;
