import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { create } from 'zustand';

const useAuthStore = create(set => ({
	auth: {
		tokens: null,
		user: null,
		member: null,
	},
	login: ({ email, password, lang = 'ko' }, onOk, onError, onFinal) => {
		const headers = {
			'Content-Type': 'application/json',
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/auth/login`,
				{
					lang: lang,
					pass: password,
					email: email,
				},
				{ headers }
			)
			.then(async response => {
				switch (response.data.httpCode) {
					case 200:
						if (response.data.data.tokens.accessToken) {
							const myInfo = await getMyInfo(response.data.data.tokens.accessToken);
							set({ auth: { tokens: response.data.data.tokens, ...myInfo } });
							onOk && onOk(response.data.data.tokens);
						} else {
							onError && onError(401);
						}
						break;
					case 403:
					case 506:
					default:
						onError && onError(response.data.httpCode);
						break;
				}
				onFinal && onFinal();
			})
			.catch(error => {
				console.error(error);
				onError && onError(400);
				onFinal && onFinal();
			});
	},
	refreshAuth: (tokens, onOk, onError, onFinal) => {
		return new Promise((resolve, reject) => {
			console.log('[AuthStore] refreshAuth');
			getMyInfo(tokens.accessToken)
				.then(({ user, member }) => {
					//auth의 user와 member만 교체하는 코드
					set({ auth: { tokens, user, member } });
					onOk && onOk();
					resolve(200);
				})
				.catch(error => {
					console.error(error);
					onError && onError(1008);
					resolve(-100);
				})
				.finally(() => {
					onFinal && onFinal();
				});
		});
	},
	logout: (accessToken, onOk, onError, onFinal) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/auth/logout`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then(response => {
				switch (response.data.httpCode) {
					case 204:
					case 517:
						console.log('logout OK');
						set({ auth: { tokens: null, user: null, member: null } });
						onOk && onOk();
						break;
					default:
						onError && onError(response.data.httpCode);
				}
				onFinal && onFinal();
			})
			.catch(error => {
				onError && onError(400);
				onFinal && onFinal();
			});
	},
	updateNickName: (accessToken, nickName, onOk, onError, onFinal) => {
		return new Promise((resolve, reject) => {
			console.log('[AuthStore] updateNickName');
			axios
				.put(
					`${process.env.REACT_APP_API_URL}/auth/user/nickname`,
					{ nickName: nickName },
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then(res => {
					switch (res.data.httpCode) {
						case 200:
							onOk && onOk();
							resolve(200);
							break;
						case 512:
							//중복됨
							onError && onError(201);
							resolve(201);
							break;
						default:
							onError && onError(res.data.httpCode);
							reject();
					}
				})
				.catch(error => {
					console.error(error);
					onError && onError(1005);
					reject(1005);
				})
				.finally(() => {
					onFinal && onFinal();
				});
		});
	},
	updateSttLang: (accessToken, lang, onOk, onError, onFinal) => {
		return new Promise((resolve, reject) => {
			console.log('[AuthStore] updateSttLang', lang);
			axios
				.put(
					`${process.env.REACT_APP_API_URL}/auth/user/translang`,
					{ lang: lang },
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then(res => {
					switch (res.data.httpCode) {
						case 200:
							onOk && onOk();
							resolve();
							break;
						case 512:
							//중복됨
							onError && onError(201);
							reject(512);
							break;
						default:
							onError && onError(res.data.httpCode);
							reject(1009);
					}
				})
				.catch(error => {
					console.error(error);
					onError && onError(1006);
					reject(1006);
				})
				.finally(() => {
					onFinal && onFinal();
				});
		});
	},
	changePassword: (accessToken, pass, passConfirm, onOk, onError, onFinal) => {
		return new Promise((resolve, reject) => {
			console.log('[AuthStore] changePassword');
			axios
				.put(
					`${process.env.REACT_APP_API_URL}/auth/user/change`,
					{ pass: pass, passConfirm: passConfirm },
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then(res => {
					switch (res.data.httpCode) {
						case 200:
							onOk && onOk(200);
							resolve(200);
							break;
						default:
							onError && onError(res.data.httpCode);
							reject(res.data.httpCode);
					}
				})
				.catch(error => {
					console.error(error);
					onError && onError(1006);
					reject(1006);
				})
				.finally(() => {
					onFinal && onFinal();
				});
		});
	},
	updateThumbnail: (accessToken, file, onOk, onError, onFinal) => {
		return new Promise((resolve, reject) => {
			console.log('[AuthStore] updateThumbnail');
			axios
				.put(
					`${process.env.REACT_APP_API_URL}/auth/user/thumbnail`,
					{ thumbnail: file },
					{
						headers: {
							'Content-Type': 'multipart/form-data; boundary=**',
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then(res => {
					switch (res.data.httpCode) {
						case 200:
							onOk && onOk();
							resolve();
							break;
						default:
							onError && onError(res.data.httpCode);
							reject();
					}
				})
				.catch(error => {
					console.error(error);
					onError && onError(1007);
					reject(1007);
				})
				.finally(() => {
					onFinal && onFinal();
				});
		});
	},
	isSessionValid: async ({ accessToken, refreshToken }) => {
		return new Promise(async (resolve, reject) => {
			const decoded = jwtDecode(accessToken);

			// 현재 시간 (밀리초를 초로 변환)
			const currentTime = Date.now() / 1000;

			// 토큰이 만료되었는지 확인
			if (decoded.exp && decoded.exp < currentTime) {
				//navigate(redirectTo);
				//리프레시 토큰이 있으면 재발급 받는다.
				const tokens = await reloadToken({ accessToken, refreshToken });
				if (tokens) {
					resolve(tokens);
				} else {
					resolve({ accessToken, refreshToken });
				}
			} else {
				resolve({ accessToken, refreshToken });
			}
		});
	},
}));

const reloadToken = ({ accessToken, refreshToken }) => {
	console.log('refresh token');
	return new Promise((resolve, reject) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		};

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/auth/refresh`,
				{
					token: refreshToken,
				},
				headers
			)
			.then(response => {
				if (response.data.httpCode === 200) {
					const time =
						3600 *
						Number(process.env.REACT_APP_COOKIE_EXPIRE_HOUR ? process.env.REACT_APP_COOKIE_EXPIRE_HOUR : 1); //default 1시간
					const expiration = new Date(Date.now() + time * 1000);

					const data = {
						...response.data.data.tokens,
						refreshToken: refreshToken,
					};
					resolve({ code: 200, data: data, expiration: expiration });
				} else {
					resolve(null);
				}
			})
			.catch(error => {
				console.error('refresh token error', error);
				resolve(null);
			});
	});
};

const getMyInfo = accessToken => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log('[Action] getMyInfo');
			const headers = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			};
			const userRes = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user/me`, { headers });
			if (200 === userRes.data.httpCode) {
				const user = {
					...userRes.data.data,
					profile: {
						...userRes.data.data.profile,
						thumbnailUrl: userRes.data.data.profile.thumbnailUrl
							? `${userRes.data.data.profile.thumbnailUrl}?t=${Date.now()}`
							: '',
					},
				};

				const wsRes = await axios.get(`${process.env.REACT_APP_API_URL}/auth/workspace/me`, { headers });
				if (200 === wsRes.data.httpCode) {
					const member = { ...wsRes.data.data };
					resolve({ user: user, member: member });
				} else {
					reject({ code: 200 });
				}
			} else {
				console.log('getMyInfo error', userRes);
				reject({ code: 300 });
			}
		} catch (e) {
			console.error(e);
			reject({ code: 400 });
		}
	});
};

export default useAuthStore;
