import React, { useEffect, useRef, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Paper, Stack, SvgIcon, Tooltip, Typography, Popover, Collapse } from '@mui/material';
import { ReactComponent as CopyIcon } from './img/copy.svg';
import { ReactComponent as PasteIcon } from './img/paste.svg';
import { ReactComponent as HighlightIcon } from './img/highlight.svg';
import { ReactComponent as DeleteIcon } from './img/delete.svg';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DataChip from '../DataChip';
import DeleteButton from '../../common/button/DeleteButton';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { ToastError, ToastInfo } from '../../common/toast/Toast';

const BookmarkItem = ({ title = 'Unknown', info = '', children, data, contentId, refreshContent, isBookmarkPage }) => {
	const boxRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [isOpen, setIsOpen] = useState(true);
	const [isHover, setIsHover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const { deleteBookmark } = useContentsStore();

	return (
		<Box
			ref={boxRef}
			sx={{
				width: '100%',
				flexShrink: 0,
				padding: '24px 16px',
				display: 'inline-flex',
				gap: '24px',
				flexDirection: 'column',
				borderRadius: '8px',
				border: `1px solid #E1E4E6`,
				boxSizing: 'border-box',
				background: isHover ? '#F9FAFB' : '#fff',
				transition: `background 0.3s ease-in-out`,
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<Stack spacing={1} direction='row' sx={{ height: 24, alignItems: 'center' }}>
				<Typography
					sx={{
						fontSize: 20,
						fontWeight: 600,
						color: '#292A2B',
						fontFamily: 'Noto Sans KR',
					}}
				>
					{title}
				</Typography>
				<Tooltip title={info}>
					<SvgIcon inheritViewBox sx={{ width: 20, height: '100%' }}>
						{data.key !== 'mergedSegments' && data.key !== 'segments' && (
							<InfoOutlinedIcon sx={{ color: '#A9AFB3' }} />
						)}
					</SvgIcon>
				</Tooltip>

				<IconButton sx={{ marginLeft: 'auto !important', '&:hover': { background: 'inherit' } }}>
					<DeleteButton
						title={'북마크를 삭제할까요?'}
						description='북마크 목록에서 삭제됩니다.'
						size={40}
						onConfirm={() => {
							deleteBookmark(
								cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
								contentId,
								data.key,
								data.key === 'summaryTime' || data.key === 'mergedSegments' ? -1 : null
							)
								.then(res => {
									switch (res.code) {
										case 200:
											ToastInfo('북마크를 삭제했습니다.', '삭제 완료');
											refreshContent();
											break;
										case 401:
											ToastError(0, '권한이 없습니다.');
											break;
										default:
											ToastError(res.message);
											break;
									}
								})
								.catch(e => {
									ToastError(1016);
								});
						}}
					/>
				</IconButton>
				<IconButton
					// sx={{ marginLeft: 'auto !important' }}
					onClick={event => {
						event.stopPropagation();
						setIsOpen(prev => !prev);
					}}
				>
					{isOpen ? <KeyboardArrowDownIcon sx={{ rotate: '180deg' }} /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</Stack>

			<Collapse in={isOpen} timeout='auto' sx={{ transition: 'all 0.3s ease-in-out' }}>
				<Paper sx={{ background: 'inherit', boxShadow: 'none' }}>{children}</Paper>
			</Collapse>
		</Box>
	);
};

export default BookmarkItem;
