import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Link, Stack, Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import ConfirmButton from '../../../components/button/ConfirmButton';
import Input from '../components/Input/Input';
import { set } from 'lodash';

const EmailJoinFormV2 = ({
	email,
	setEmail,
	accessToken,
	setAccessToken,
	password,
	setPassword,
	onComplete,
	step,
	setStep,
}) => {
	const [isAlreadyJoined, setAlreadyJoined] = useState(false);
	const [joinedEmail, setJoinedEmail] = useState('');

	const [passwordErrorText, setPasswordErrorText] = useState('');
	const [codeErrorText, setCodeErrorText] = useState('');
	const [codeOkText, setCodeOkText] = useState('');
	const [emailCertifyCode, setEmailCertifyCode] = useState(null);
	const [isCodeSent, setCodeSent] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isPwError, setPwError] = useState(false);
	const [isCodeError, setCodeError] = useState(false);

	function isValidEmail(email) {
		// 이메일 형식을 검증하기 위한 정규 표현식
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	useEffect(() => {
		if ('' !== password && !isValidPassword(password)) {
			//setErrorText('비밀번호를 다시 확인해 주세요.');
		} else {
			setPasswordErrorText('');
			setPwError(false);
		}
	}, [password]);

	useEffect(() => {
		if (email !== joinedEmail) {
			setAlreadyJoined(false);
		}
	}, [email]);

	const onEmailCertifyRequest = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/timblo/certified?t=${Date.now()}`,
					{ email },
					{
						headers: {
							'Content-Type': 'application/json',
							'x-referer': '/account/signup',
						},
					}
				);

				const { httpCode, data } = response.data;
				if (200 === httpCode) {
					setAccessToken(response.data.data.accessToken);
					setCodeErrorText('');
					setCodeOkText('이메일로 인증코드를 발송했습니다.');
					setCodeSent(true);
				}

				console.log('[onEmailCertifyRequest]', response.data);
				resolve(httpCode);
			} catch (error) {
				console.error(error);
				resolve(400);
			}
		});
	};

	const onEmailIsJoin = () => {
		return new Promise(async (resolve, reject) => {
			let data = JSON.stringify({
				email: email,
			});

			let config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/auth/email`,
				headers: {
					'Content-Type': 'application/json',
				},
				data: data,
			};
			axios
				.request(config)
				.then(response => {
					switch (response.data.httpCode) {
						case 200:
							resolve(200);
							break;

						default:
							resolve(response.data.httpCode);
							break;
					}
				})
				.catch(error => {
					resolve(400);
					console.log(error);
				});
		});
	};

	const onResendCertifyCode = async () => {
		console.log('onResendCertifyCode', accessToken);
		if (accessToken) {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/timblo/recertified`,
					{},
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
							'x-referer': '/account/signup',
						},
					}
				);

				if (response.data.httpCode !== 200) {
					setCodeOkText('');
					switch (response.data.httpCode) {
						case 429:
							setCodeErrorText(`인증코드 재발송 횟수를 초과하였습니다.\n5분 후에 다시 시도해 주세요.`);
							break;
						default:
							setCodeErrorText(`코드 발송이 실패하였습니다. (${response.data.httpCode})`);
							break;
					}
				} else {
					setCodeErrorText('');
					setCodeSent(true);
					setCodeOkText('이메일로 인증코드를 발송했습니다.');
				}
			} catch (error) {
				console.error('Resend failed', error);
				setCodeErrorText('코드 발송이 실패하였습니다. (-300)');
			}
		} else {
			console.error('accessToken not found');
			setCodeErrorText('코드 발송이 실패하였습니다. (-400)');
		}
	};

	const onEmailCertify = async () => {
		return new Promise(async (resolve, reject) => {
			if (accessToken) {
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_URL}/auth/timblo/verification`,
						{ code: emailCertifyCode },
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${accessToken}`,
							},
						}
					);

					const { httpCode } = response.data;
					resolve(httpCode);
				} catch (error) {
					console.error(error);
					resolve(400);
				}
			}
		});
	};

	const isValidPassword = password => {
		// 조건: 8~20자, 국문/영문, 숫자, 특수문자를 포함
		const lengthRegex = /^.{8,20}$/; // 길이가 8~20자인지 확인
		const combinationRegex = /(?=.*[A-Za-z가-힣])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`_+=-])/; // 영문/한글, 숫자, 특수문자 포함 여부

		// 모든 조건을 만족하는지 확인
		const isValid = lengthRegex.test(password) && combinationRegex.test(password);

		return isValid;
	};

	return (
		<Box>
			<Backdrop open={isLoading} sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: '#fff' }}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<Stack>
				<Typography
					htmlFor='email'
					sx={{ fontSize: '14px', fontWeight: '500', color: '#374553', fontFamily: 'Noto Sans KR' }}
				>
					아이디
				</Typography>
			</Stack>
			<Stack sx={{ paading: '12px 0px', height: '48px' }}>
				<Input
					type='text'
					value={email}
					error={isAlreadyJoined}
					setValue={setEmail}
					placeholder={'이메일을 입력해 주세요.'}
					disabled={step > 2}
				/>
			</Stack>
			{2 === step && <Stack sx={{ height: '12px' }} />}

			{2 === step && isAlreadyJoined && (
				<>
					<Stack sx={{ marginTop: '16px' }}>
						<Typography
							sx={{ fontSize: '12px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{'이미 가입한 이메일 입니다.'}
						</Typography>
						<Typography
							component='a'
							href='/login' // 원하는 링크 경로로 변경
							sx={{
								fontSize: '12px',
								fontWeight: '500',
								color: '#1C6EFF',
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
						>
							{'해당 계정으로 로그인 하시겠어요?'}
						</Typography>
					</Stack>
				</>
			)}

			{step > 2 && (
				<>
					<Stack sx={{ height: '24px' }} />
					<Stack>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#374553', fontFamily: 'Noto Sans KR' }}
						>
							비밀번호
						</Typography>
					</Stack>
					<Stack sx={{ paading: '12px 0px', height: '48px' }}>
						<Input
							type='password'
							value={password}
							setValue={setPassword}
							error={isPwError}
							disabled={step === 4}
							placeholder={'비밀번호를 입력해 주세요.'}
						/>
					</Stack>

					<Stack>
						<Typography
							sx={{
								fontSize: '12px',
								fontWeight: '500',
								fontFamily: 'Noto Sans KR',
								color: '#878D91',
								width: '321px',
							}}
						>
							{'*국문/영문, 숫자, 특수문자를 조합하여 8~20자로 입력해주세요.'}
						</Typography>
					</Stack>

					{isPwError && '' !== password && (
						<Stack sx={{ marginTop: '0px' }}>
							<Typography htmlFor='email' sx={{ fontSize: '12px', fontWeight: '500', color: ' #F9463B' }}>
								{'비밀번호를 다시 확인해 주세요.'}
							</Typography>
						</Stack>
					)}
					<Stack sx={{ height: '12px' }} />
				</>
			)}

			{step > 3 && (
				<>
					<Stack sx={{ height: '12px' }} />
					<Stack>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#374553', fontFamily: 'Noto Sans KR' }}
						>
							인증코드
						</Typography>
					</Stack>
					<Stack sx={{ padding: '0px 0px', gap: '8px', height: '48px', marginTop: '0px' }} direction={'row'}>
						<Input
							sx={{ flex: 1 }}
							type='text'
							value={emailCertifyCode}
							setValue={setEmailCertifyCode}
							error={isCodeError}
							placeholder={'인증코드를 입력해 주세요.'}
						/>
						<Button
							sx={{
								width: '94px',
								background: '#1C6EFF',
								color: '#FFFFFF',
								display: 'flex',
								borderRadius: '8px',
								fontSize: '14px',
								marginTop: '5px',
								marginBottom: '5px',
								fontFamily: 'Noto Sans KR',
								fontWeight: '500',
								fontStyle: 'normal',
							}}
							onClick={() => {
								isCodeSent ? onResendCertifyCode() : onEmailCertifyRequest();
							}}
						>
							{isCodeSent ? `재발송` : `코드 받기`}
						</Button>
					</Stack>
					<Stack sx={{ height: '12px' }} />

					{'' !== codeErrorText && (
						<>
							<Stack>
								<Typography
									color='#F9463B'
									sx={{
										fontSize: '14px',
										fontWeight: '500',
										fontFamily: 'Noto Sans KR',
										whiteSpace: 'pre-wrap',
									}}
								>
									{codeErrorText}
								</Typography>
							</Stack>
							<Stack sx={{ height: '2px' }} />
						</>
					)}

					{codeOkText !== '' && (
						<>
							<Stack>
								<Typography
									color='#1C6EFF'
									sx={{
										fontSize: '14px',
										fontWeight: '500',
										fontFamily: 'Noto Sans KR',
									}}
								>
									{codeOkText}
								</Typography>
							</Stack>
						</>
					)}
				</>
			)}

			<>
				<Stack sx={{ height: '12px' }} />
				<ConfirmButton
					label={step !== 5 ? '계속하기' : '시작하기'}
					onClick={async () => {
						switch (step) {
							case 2:
								switch (await onEmailIsJoin()) {
									case 528:
										setStep(3);
										break;
									case 200:
										setAlreadyJoined(true);
										setJoinedEmail(email);
										break;
									default:
										//장애
										alert('장애가 발생하였습니다.');
										break;
								}

							//setStep(3);
							case 3:
								if (isValidPassword(password)) {
									setStep(4);
								} else {
									setPwError(true);
								}
								break;
							case 4:
								let code = await onEmailCertify();
								switch (code) {
									case 200:
										setStep(5);
										break;
									case 201:
										setCodeError(true);
										setCodeErrorText('인증코드를 다시 확인해 주세요.');
										break;
									default:
										alert(`장애가 발생하였습니다. (${code})`);
										break;
								}

								break;
							default:
								alert('장애가 발생하였습니다.');
								break;
						}
					}}
					isActive={
						step === 2 ? isValidEmail(email) && !isAlreadyJoined : step === 3 ? password : emailCertifyCode
					}
				/>
				{/* <ConfirmButton label='계속하기' onClick={onEmailCertifyRequest} isActive={isValidEmail(email)} /> */}
			</>
		</Box>
	);
};

export default EmailJoinFormV2;
