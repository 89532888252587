import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Paper, Stack, SvgIcon, Typography, Popover, Collapse } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as CopyIcon } from './img/copy.svg';
import { ReactComponent as PasteIcon } from './img/paste.svg';
import { ReactComponent as AddIcon } from './img/add.svg';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContextMenu from '../popup/contextMenu/ContextMenu';

const SummaryBlock = ({
	title = 'Unknown',
	isBookmark,
	info = '',
	children,
	enableContextMenu,
	onToggleBookmark = () => {},
	onCopyClipboard = () => {},
	onPasteNote = () => {},
	isSummaryTimeBlock,
}) => {
	const boxRef = useRef();

	const [isOpen, setIsOpen] = useState(true);
	const [isHover, setIsHover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	return (
		<Box
			ref={boxRef}
			sx={{
				width: '100%',
				flexShrink: 0,
				padding: '24px 16px',
				display: 'inline-flex',
				flexDirection: 'column',
				borderBottom: `0.6px solid #E1E4E6`,
				boxSizing: 'border-box',
				background: isBookmark ? '#F5FEF5 !important' : isHover ? '#F9FAFB' : '#fff',
				transition: `background 0.3s ease-in-out`,
				position: 'relative',
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<Stack spacing={1} direction='row' sx={{ height: 24, alignItems: 'center' }}>
				<Typography sx={{ fontSize: 20, fontWeight: 600, color: '#292A2B', fontFamily: 'Noto Sans KR' }}>
					{title}
				</Typography>
				<NoMaxWidthTooltip
					title={
						<Typography
							sx={{ fontSize: 12, fontFamily: 'Noto Sans KR', whiteSpace: 'nowrap', maxWidth: 'none' }}
						>
							{info}
						</Typography>
					}
					placement='top-start'
					slotProps={{
						popper: {
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, -14],
									},
								},
							],
						},
					}}
					sx={{
						'& .MuiTooltip-tooltip': {
							backgroundColor: '#292A2B',
							color: '#fff',
						},
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 20, height: '100%' }}>
						<InfoOutlinedIcon sx={{ color: '#A9AFB3' }} />
					</SvgIcon>
				</NoMaxWidthTooltip>

				{enableContextMenu && isHover && (
					<Box
						className={isSummaryTimeBlock ? 'summary-time-parent' : ''}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid #E1E4E6',
							borderRadius: '4.8px',
							boxSizing: 'border-box',
							top: -25,
							right: 0,
							position: 'absolute',
						}}
					>
						<ContextMenu
							isBookmark={isBookmark}
							onToggleBookmark={onToggleBookmark}
							onCopyClipboard={() => onCopyClipboard(boxRef.current.innerText)}
							onPasteNote={onPasteNote}
							onClose={() => setAnchorEl(null)}
						/>
					</Box>
				)}
				<IconButton
					sx={{ marginLeft: 'auto !important' }}
					onClick={e => {
						e.stopPropagation();
						setIsOpen(prev => !prev);
					}}
				>
					{isOpen ? <KeyboardArrowDownIcon sx={{ rotate: '180deg' }} /> : <KeyboardArrowDownIcon />}
				</IconButton>

				{/* <Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					anchorOrigin={{
						vertical: 30,
						horizontal: 0,
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 125,
					}}
				>
					<ContextMenu
						isBookmark={isBookmark}
						onToggleBookmark={onToggleBookmark}
						onCopyClipboard={() => onCopyClipboard(boxRef.current.innerText)}
						onPasteNote={onPasteNote}
						onClose={() => setAnchorEl(null)}
					/>
				</Popover> */}
			</Stack>

			<Collapse in={isOpen} timeout='auto' sx={{ transition: 'all 0.3s ease-in-out' }}>
				<Paper sx={{ background: 'inherit', boxShadow: 'none', paddingTop: '25px' }}>{children}</Paper>
			</Collapse>
		</Box>
	);
};

export default SummaryBlock;

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 'none',
	},
});
