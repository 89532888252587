import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ConfirmButton from '../../../components/button/ConfirmButton';

import uploadImage from '../img/profile-upload.svg';
import { Label, ProfileImage, ProfileUpload } from '../LoginComponents';
import axios from 'axios';
import { Box, Button, Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Input from '../components/Input/Input';
import CreateIcon from '@mui/icons-material/Create';

import NeedProfileImg from '../img/needprofile.png'

const ProfileFormV2 = ({ email, password, accessToken, onComplete }) => {
	const fileInputRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [nickName, setNickName] = useState('');
	const [joinResult, setJoinResult] = useState(null);
	const [thumbnail, setThumbnail] = useState(NeedProfileImg);
	const [isNickNameUsable, setNickNameUsable] = useState(-1);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (1 === isNickNameUsable) {
			setNickNameUsable(-1);
		}
	}, [nickName]);

	const onSignUp = () => {
		const formData = new FormData();
		formData.append('pass', password);
		formData.append('name', '');
		formData.append('nickName', nickName);
		formData.append('thumbnail', selectedFile);

		const headers = {
			'Content-Type': 'multipart/form-data; boundary=**',
			Authorization: `Bearer ${accessToken}`,
		};

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/timblo/signup`, formData, {
				headers: headers,
			})
			.then(response => {
				if (200 === response.data.httpCode) {
					onComplete && onComplete(true);
				} else {
					setJoinResult({ code: response.data.httpCode });
				}
			})
			.catch(error => {
				console.error('에러 발생:', error);
				setLoading(false);
				setJoinResult({ code: 400 });
			});
	};

	const onFileChange = event => {
		const file = event.target.files[0];
		if (file) {
			setSelectedFile(file);
			setThumbnail(URL.createObjectURL(file));
		}
	};

	const onNickNameCheck = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		};
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/auth/nickname`,
				{
					nickName: nickName,
				},
				{
					headers: headers,
				}
			)
			.then(response => {
				console.log(nickName);
				console.log(password);
				switch (response.data.httpCode) {
					case 200:
						setNickNameUsable(1);
						break;
					case 512:
						setNickNameUsable(0);
						break;
					default:
						break;
				}
			})
			.catch(error => {
				console.error('에러 발생:', error);
				setJoinResult({ code: 401 });
			});
	};

	return (
		<>
			{!accessToken ? (
				<>
					{' '}
					<Label color={'#F9463B'}>세션이 만료되었습니다.</Label>
				</>
			) : (
				<>
					<Stack sx={{ marginTop: '-24px' }}>
						<ProfileUpload style={{ position: 'relative' }}>
							<ProfileImage
								src={thumbnail}
								alt='Profile'
								style={{
									borderRadius: '50%', // 원형으로 설정
									border: '1px solid #CED3D6',
									objectFit: 'cover', // 이미지가 영역에 맞게 비율 유지
									cursor: 'pointer', // 클릭 가능하도록 포인터 추가
								}}
								onClick={() => {
									!isLoading && fileInputRef.current.click();
								}}
							/>
							<input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileChange} />
							<Stack sx={{ height: '8px' }}></Stack>
							<Typography
								sx={{
									fontFamily: 'Noto Sans KR',
									fontSize: '14px',
									fontWeight: '500',
									color: '#374553',
								}}
							>
								{email}
							</Typography>
							<CreateIcon
								sx={{
									borderRadius: '50%', // 원형으로 설정
									border: '1px solid #CED3D6',
									position: 'absolute',
									background: '#FFFFFF',
									padding: '8px',
									width: '28px',
									height: '28px',
									bottom: '0px',
									marginLeft: '92px',
								}}
							/>
						</ProfileUpload>

						<Stack
							sx={{
								marginTop: '24px',
								marginBottom: '24px',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									width: '100%',
									height: 'auto', // 높이를 컨텐츠에 맞게 변경
									border: '1px solid #E3EFFF',
									borderRadius: '8px',
									background: '#F8FBFF',
									padding: '8px', // 여백 추가
									lineHeight: '18px',
								}}
							>
								<ErrorIcon
									sx={{
										color: '#1C6EFF',
										width: '18px',
										alignSelf: 'flex-start', // 본문 첫 줄의 높이에 위치하도록 설정
										marginTop: '-4px',
										marginLeft: '-6px',
										lineHeight: '18px',
									}}
								/>
								<Typography
									sx={{
										display: 'inline-block', // 본문 줄바꿈 허용
										wordBreak: 'break-word', // 긴 텍스트 자동 줄바꿈
										fontSize: '12px',
										fontWeight: '400',
										color: '#292A2B',
										fontFamily: 'Noto Sans KR',
										marginLeft: '3px',
										marginRight: '-4px',
										lineHeight: '18px',
									}}
								>
									닉네임은 공유하거나 참석자를 등록할 때<br /> 검색에 활용되므로,{' '}
									<Typography
										sx={{
											display: 'inline-block', // 본문 줄바꿈 허용
											wordBreak: 'break-word', // 긴 텍스트 자동 줄바꿈
											fontSize: '12px',
											fontWeight: '400',
											fontFamily: 'Noto Sans KR',
											color: '#1C6EFF',
											lineHeight: '18px',
										}}
									>
										본인의 이름을 사용하는 것이 좋습니다.
									</Typography>
								</Typography>
							</Box>
						</Stack>
						<Stack sx={{ height: '16px' }}></Stack>

						<Stack>
							<Typography
								sx={{
									fontSize: '14px',
									fontWeight: '500',
									color: '#374553',
									fontFamily: 'Noto Sans KR',
								}}
							>
								닉네임
							</Typography>
						</Stack>
						<Stack sx={{ gap: '8px' }} direction={'row'}>
							<Input
								sx={{ flex: 1 }}
								type='text'
								value={nickName}
								//placeholder={'닉네임을 입력해 주세요.'}
								error={0 === isNickNameUsable}
								setValue={setNickName}
							/>
							<Button
								sx={{
									width: '100px',
									height: '36px',
									background: '#1C6EFF',
									color: '#FFFFFF',
									padding: '8px',
									display: 'flex',
									gap: '8px',
									borderRadius: '8px',
									fontSize: '14px',
									alignSelf: 'flex-end', // 본문 첫 줄의 높이에 위치하도록 설정
								}}
								onClick={() => {
									onNickNameCheck();
									// onResendCertifyCode();
								}}
							>
								{`중복확인`}
							</Button>
						</Stack>

						{0 === isNickNameUsable && (
							<>
								<Stack sx={{ height: '12px' }}></Stack>
								<Stack>
									<Typography
										sx={{
											fontSize: '14px',
											color: '#F9463B',
											fontWeight: '500',
											fontFamily: 'Noto Sans KR',
										}}
									>
										사용 중인 닉네임입니다.
									</Typography>
								</Stack>
							</>
						)}
						{1 === isNickNameUsable && (
							<>
								<Stack>
									<Stack sx={{ height: '12px' }}></Stack>
									<Typography
										sx={{
											fontSize: '14px',
											color: '#1C6EFF',
											fontWeight: '500',
											fontFamily: 'Noto Sans KR',
										}}
									>
										사용 가능한 닉네임입니다.
									</Typography>
								</Stack>
							</>
						)}

						<Stack sx={{ height: '24px' }}></Stack>

						<ConfirmButton
							label={'시작하기'}
							isActive={nickName && password && accessToken && 1 === isNickNameUsable}
							onClick={() => {
								onSignUp();
							}}
							isLoading={isLoading}
						/>
					</Stack>
				</>
			)}
		</>
	);
};

export default ProfileFormV2;
