import Datetime from 'react-datetime';
import { ToastError, ToastInfo } from '../common/toast/Toast';

import 'react-datetime/css/react-datetime.css';
import './MeetingTime.css';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../store/AuthStore';
import axios from 'axios';
import { useEffect } from 'react';

import dayjs from 'dayjs';

import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import { Typography } from '@mui/material';

const MeetingTime = ({
	startTime,
	endTime,
	onChangeStartMeetingTime,
	onChangeEndMeetingTime,
	onUpdate,
	isEditable,
}) => {
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { auth } = useAuthStore();

	// useEffect(() => {
	// 	update()
	// 		.then(() => {
	// 			//성공
	// 		})
	// 		.catch(e => {
	// 			ToastError(e, '회의 시간 업데이트에 실패했습니다.');
	// 		});
	// }, [content && content.meta.meetingStartTime, content && content.meta.meetingEndTime]);

	const renderInput = (props, openCalendar) => {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: '1px solid #CED3D6',
					borderRadius: '8px',
					padding: '8px',
					gap: '10px',
					cursor: 'pointer',
					height: 40,
					boxSizing: 'border-box',
				}}
			>
				<CalendarIcon
					style={{
						stroke: '#4D5256',
						fontSize: '20px',
						cursor: 'pointer',
					}}
					onClick={openCalendar}
				/>

				<input
					{...props}
					disabled={!isEditable}
					style={{
						width: 190,
						border: 'none',
						outline: 'none',
						// flex: 1,
						fontSize: '18px',
						fontWeight: 500,
						color: '#4D5256',
						fontFamily: 'Noto Sans KR',
						cursor: 'pointer',
					}}
				/>
			</div>
		);
	};

	return (
		<>
			<Datetime
				locale='ko'
				className='temp-datepicker start'
				value={new Date(startTime)}
				onChange={date => {
					if (!startTime || new Date(date) <= new Date(endTime)) {
						//		setStartDate(new Date(date));
						onChangeStartMeetingTime(date.toISOString());
						// setContent({
						// 	...content,
						// 	meta: {
						// 		...content.meta,
						// 		meetingStartTime: date.toISOString(),
						// 	},
						// });
					} else {
						console.log(startTime, date, new Date(date), new Date(startTime));
						ToastError(0, '시작 시간이 종료 시간보다 늦을 수 없습니다.');
					}
				}}
				renderInput={(props, openCalendar) => renderInput(props, openCalendar)}
				onClose={() => onUpdate && onUpdate()}
			/>
			<Typography sx={{ fontSize: 18, fontWeight: 500, color: '#4D5256', display: 'flex', alignItems: 'center' }}>
				~
			</Typography>
			<Datetime
				className='temp-datepicker end'
				value={new Date(endTime)}
				onChange={date => {
					if (!startTime || new Date(date) >= new Date(startTime)) {
						onChangeEndMeetingTime(date.toISOString());
					} else {
						ToastError(0, '종료 시간이 시작 시간보다 빠를 수 없습니다.');
					}
				}}
				renderInput={(props, openCalendar) => renderInput(props, openCalendar)}
				onClose={() => onUpdate && onUpdate()}
			/>
		</>
	);
};

export default MeetingTime;
