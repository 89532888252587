import { useEffect, useRef, useState } from 'react';
import { openDB } from 'idb'; // npm install idb 필요
import Confirm from '../confirm/Confirm';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import Popup from 'reactjs-popup';
import ModalConfirm from '../../common/modal/ModalConfirm';
import { Typography } from '@mui/material';

const DB_NAME = 'RecorderBackupDB';
const DB_STORE_NAME = 'recordings';
const LOG_GROUP = 'BackupRecorder';
const SAVE_INTERVAL = 6000;

const initializeDatabase = async id => {
	console.info('Initialize database', LOG_GROUP);
	return openDB(id || DB_NAME, 1, {
		upgrade(db) {
			if (!db.objectStoreNames.contains(DB_STORE_NAME)) {
				db.createObjectStore(DB_STORE_NAME, { keyPath: 'id', autoIncrement: true });
			}
		},
	});
};

const initAudio = async () => {
	await register(await connect());
};

const BackupRecorder = ({ id, state, setState }) => {
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const [mediaStream, setMediaStream] = useState(null);
	const [db, setDb] = useState(null);
	const backupTimerRef = useRef(null);
	const [isNeedRecovery, setIsNeedRecovery] = useState(false);

	const start = async () => {
		console.info('Start backup', LOG_GROUP);
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			setMediaStream(stream);

			const mr = new MediaRecorder(stream, {
				mimeType: `audio/wav`,
				audioBitsPerSecond: 128000,
			});
			setMediaRecorder(mr);
			console.info('MediaRecorder initialized', LOG_GROUP);

			mr.ondataavailable = async e => {
				if (db) {
					const transaction = db.transaction(DB_STORE_NAME, 'readwrite');
					const store = transaction.objectStore(DB_STORE_NAME);
					await store.add({ blob: e.data });
					console.info('Chunk saved to IndexedDB', LOG_GROUP);
				} else {
					console.error('DB is not initialized', LOG_GROUP);
				}
			};

			mr.start(SAVE_INTERVAL);
			console.info('Recording started', LOG_GROUP);
		} catch (err) {
			console.error('Failed to initialize MediaRecorder', LOG_GROUP, err);
		}
	};

	const stop = async () => {
		if (mediaRecorder) {
			console.info('Stop backup', LOG_GROUP);
			mediaRecorder.ondataavailable = null;
			mediaRecorder.stop();
			setMediaRecorder(null);
		}
		if (mediaStream) {
			mediaStream.getTracks().forEach(track => track.stop());
			setMediaStream(null);
			console.info('MediaStream released', LOG_GROUP);
		}
		clear();
	};

	const save = () => {
		clear();
		setState(0);
	};

	const clear = async () => {
		const clearTransaction = db.transaction(DB_STORE_NAME, 'readwrite');
		const clearStore = clearTransaction.objectStore(DB_STORE_NAME);
		await clearStore.clear();
		setIsNeedRecovery(false);
		console.info('IndexedDB cleared', LOG_GROUP);
	};

	const getRecoveryData = async () => {
		return new Promise(async resolve => {
			if (db) {
				const transaction = db.transaction(DB_STORE_NAME, 'readonly');
				const store = transaction.objectStore(DB_STORE_NAME);
				const allData = await store.getAll();
				resolve(allData);
			} else {
				resolve([]);
			}
		});
	};

	const download = async () => {
		try {
			const allData = await getRecoveryData();
			if (0 < allData.length) {
				console.info('Download recovery data', LOG_GROUP);
				const allBlobs = allData.map(record => record.blob);
				const finalBlob = new Blob(allBlobs, { type: 'audio/wav' });

				const url = URL.createObjectURL(finalBlob);
				const a = document.createElement('a');
				a.href = url;

				const date = new Date();
				const pad = num => num.toString().padStart(2, '0');
				const fileName = `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}_${pad(
					date.getHours()
				)}${pad(date.getMinutes())}${pad(date.getSeconds())}_recovery.wav`;

				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);

				clear();
			} else {
				console.error('No recovery data', LOG_GROUP);
			}
		} catch (e) {
			console.error(e, LOG_GROUP);
		}
	};

	useEffect(() => {
		switch (state) {
			case 0:
				stop();
				break;
			case 1:
				start();
				break;
			case 99:
				save();
				break;
		}
	}, [state]);

	useEffect(() => {
		initializeDatabase(id).then(setDb).catch(console.error);
		initAudio();
		return () => {
			if (backupTimerRef.current) clearInterval(backupTimerRef.current);
		};
	}, []);

	useEffect(() => {
		if (db) {
			getRecoveryData().then(data => {
				if (0 < data.length) {
					setIsNeedRecovery(true);
				}
			});
		}
	}, [db]);

	return (
		<>
			{isNeedRecovery && (
				<ModalConfirm
					isOpen={isNeedRecovery}
					title={'녹음파일을 복구 할까요?'}
					description={
						<>
							<div>비정상적으로 종료된 녹음파일이 존재합니다.</div>
							<div>삭제된 파일은 영구적으로 복구할 수 없습니다.</div>
						</>
					}
					confirmText={'복구'}
					cancelText={'삭제'}
					onCancel={() => {
						console.info(`Recovery cancel button (cancle)`, LOG_GROUP);
						clear();
						setIsNeedRecovery(false);
					}}
					onConfirm={() => {
						console.info(`Recovery button (confirm)`, LOG_GROUP);
						download();
						setIsNeedRecovery(false);
					}}
				/>

				// <Popup open={isNeedRecovery} position={'top left'} arrow={false} nested>
				// 	{close => (
				// 		<Confirm
				// 			title={
				// 				'비정상적으로 종료된 녹음파일이 존재합니다. 복구 하시겠습니까?\n삭제된 파일은 복구할 수 없습니다.'
				// 			}
				// 			onCancelText='삭제'
				// 			onConfirmText='복구'
				// 			onCancel={() => {
				// 				console.info(`Recovery cancel button (cancle)`, LOG_GROUP);
				// 				close();
				// 			}}
				// 			onConfirm={() => {
				// 				console.info(`Recovery button (confirm)`, LOG_GROUP);
				// 				download();
				// 				close();
				// 			}}
				// 		/>
				// 	)}
				// </Popup>
			)}
		</>
	);
};

export default BackupRecorder;
