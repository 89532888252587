import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './SttRowV2.css';

import Popup from 'reactjs-popup';
import Avatar from '@mui/material/Avatar';
import { Box, IconButton, Paper, Popover, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';

import { getAvatarColor, onCopyText } from '../../../util/Util';
import { ToastError, ToastInfo } from '../../common/toast/Toast';

import { useCookies } from 'react-cookie';
import useContentsStore from '../../../store/ContentsStore';
import useAuthStore from '../../../store/AuthStore';

import { ReactComponent as CopyIcon } from '../img/copy.svg';
import { ReactComponent as PasteIcon } from '../img/paste.svg';
import { ReactComponent as AddIcon } from '../img/add.svg';
import { ReactComponent as HighlightIcon } from '../img/highlight.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SpeakerEdit from '../../popup/content/speakerEdit/SpeakerEdit';
import ContextMenu from '../../popup/contextMenu/ContextMenu';

const SttRowV2 = ({
	contentId,
	index,
	data,
	speakerInfo,
	isBookmark,
	searchResult,
	matchIndices,
	highlightedData,
	keyword,
	onRefresh,
	onSeekTime = () => {},
	onToggleBookmark = () => {},
	onCopyClipboard = () => {},
	onPasteNote = () => {},
}) => {
	const boxRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { selectedContent, onAppendDataToNote, addBookmark, deleteBookmark } = useContentsStore();

	const { auth } = useAuthStore();
	const [thumbnail, setThumbnail] = useState(null);
	const [isHover, setIsHover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	useLayoutEffect(() => {
		if (data && data.speakerId) {
			setThumbnail(getThumbnail(data.speakerId));
		}
	}, []);

	const getThumbnail = speakerId => {
		const speaker = speakerInfo && speakerInfo.find(item => item.speakerId === speakerId);
		if (speaker && speaker.thumbnailUrl) {
			if (auth && auth.user && auth.user.profile && auth.user.profile.email === speaker.email) {
				return auth.user.profile.thumbnailUrl;
			} else {
				return speaker.thumbnailUrl;
			}
		} else {
			return null;
		}
	};

	const getSpeakerName = speakerId => {
		if (speakerInfo) {
			const target = speakerInfo.find(speaker => speaker.speakerId === speakerId);

			if (target) {
				return target.displayName ?? target.name;
			}
		} else {
			return 'Unknown';
		}
	};

	const convertTimeFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		const pad = num => num.toString().padStart(2, '0');

		return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
	};

	return (
		<Stack
			spacing={1.25}
			direction={'row'}
			className={`stt-row-container ${index}`}
			sx={{
				borderRadius: 1,
				background: isBookmark ? '#F6FFF7' : 'inherit',
				marginTop: index === 0 ? '15px' : 0,
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<Popup
				trigger={
					<Avatar
						src={thumbnail || null}
						sx={theme => ({
							width: 32,
							height: 32,
							bgcolor: thumbnail ? 'transparent' : getAvatarColor(data.speakerId).background,
							color: thumbnail ? 'inherit' : getAvatarColor(data.speakerId).color,
							fontSize: 16,
							fontWeight: 700,
							fontFamily: 'Noto Sans KR',
							cursor: 'pointer',
						})}
					>
						{!thumbnail && getSpeakerName(data.speakerId).substring(0, 1)}
					</Avatar>
				}
				keepTooltipInside='.main-content-v2'
			>
				{close => (
					<SpeakerEdit
						contentId={contentId}
						segment={data}
						onChangeSegmentSpeaker={onRefresh}
						speakerId={data.speakerId}
						speakerList={speakerInfo}
						onClose={close}
					/>
				)}
			</Popup>
			<Stack spacing={1} sx={{ flex: 1 }}>
				<Stack spacing={1} direction={'row'} alignItems={'center'} sx={{ height: 24, position: 'relative' }}>
					<Popup
						trigger={
							<Typography
								sx={{
									color: '#28323C',
									fontSize: 18,
									fontWeight: 600,
									fontFamily: 'Noto Sans KR',
									cursor: 'pointer',
								}}
							>
								{getSpeakerName(data.speakerId)}
							</Typography>
						}
						keepTooltipInside='.main-content-v2'
					>
						{close => (
							<SpeakerEdit
								contentId={contentId}
								segment={data}
								onChangeSegmentSpeaker={onRefresh}
								speakerId={data.speakerId}
								speakerList={speakerInfo}
								onClose={close}
							/>
						)}
					</Popup>

					<Typography
						sx={{
							color: '#878D91',
							fontSize: 16,
							fontWeight: 400,
							fontFamily: 'Noto Sans KR',
							cursor: 'pointer',
						}}
						onClick={() => onSeekTime(data.startTime / 1000)}
					>
						{convertTimeFormat(data.startTime)}
					</Typography>

					{isHover && (
						// <IconButton
						// 	sx={{
						// 		width: 24,
						// 		height: 24,
						// 		display: 'flex',
						// 		justifyContent: 'center',
						// 		alignItems: 'center',
						// 		border: '1px solid #E1E4E6',
						// 		borderRadius: '4.8px',
						// 		boxSizing: 'border-box',
						// 		marginLeft: 'auto !important',

						// 		'&:hover': {
						// 			border: '1px solid #5793FF',
						// 		},

						// 		'&:hover > svg': {
						// 			color: '#5793FF',
						// 		},
						// 	}}
						// 	onClick={event => setAnchorEl(event.currentTarget)}
						// >
						// 	<MoreVertIcon fontSize={'small'} />
						// </IconButton>

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								border: '1px solid #E1E4E6',
								borderRadius: '4.8px',
								boxSizing: 'border-box',
								// marginLeft: 'auto !important',
								position: 'absolute',
								right: 0,
								top: -25,
								zIndex: 9999,
							}}
						>
							<ContextMenu
								isBookmark={isBookmark}
								onToggleBookmark={onToggleBookmark}
								onCopyClipboard={() => onCopyClipboard(boxRef.current.innerText)}
								onPasteNote={onPasteNote}
								onClose={() => setAnchorEl(null)}
							/>
						</Box>
					)}
				</Stack>

				<Box
					ref={boxRef}
					className='stt-result'
					suppressContentEditableWarning
					spellCheck={false}
					id={data.segmentId}
					start-time={data.startTime}
				>
					{highlightedData[index].map((part, partIndex) => (
						<span
							key={partIndex}
							style={{
								backgroundColor:
									part.toLowerCase() === keyword.toLowerCase()
										? matchIndices[searchResult.currentIndex] &&
										  matchIndices[searchResult.currentIndex].dataIndex === index &&
										  matchIndices[searchResult.currentIndex].partIndex === partIndex
											? 'orange'
											: 'yellow'
										: 'inherit',
								color: '#4D5256',
								fontWeight: 500,
								fontSize: 16,
							}}
						>
							{part}
						</span>
					))}
				</Box>
			</Stack>
			{/* <Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 30,
					horizontal: 0,
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 125,
				}}
			>
				<ContextMenu
					isBookmark={isBookmark}
					onToggleBookmark={onToggleBookmark}
					onCopyClipboard={() => onCopyClipboard(boxRef.current.innerText)}
					onPasteNote={onPasteNote}
					onClose={() => setAnchorEl(null)}
				/>
			</Popover> */}
		</Stack>
	);
};

export default SttRowV2;

// const ContextMenu = ({ isBookmark, onToggleBookmark, onCopyClipboard, onPasteNote, onClose }) => {
// 	return (
// 		<Box
// 			sx={{
// 				width: 136,
// 				height: 40,
// 				boxSizing: 'border-box',
// 				border: `1px solid rgba(206, 211, 214, 1)`,
// 				borderRadius: '4px',
// 				boxShadow: `2px 2px 8px 0px rgba(0, 0, 0, 0.1)`,
// 				padding: '8px 16px 8px 16px',
// 				background: '#fff',
// 			}}
// 		>
// 			<Stack direction='row' sx={{ gap: '16px' }}>
// 				<Paper
// 					sx={{
// 						width: '24px',
// 						height: '24px',
// 						cursor: 'pointer',
// 						'&:hover': {
// 							backgroundColor: '#F0F0F0',
// 						},
// 					}}
// 					onClick={() => {
// 						onToggleBookmark();
// 						onClose() && onClose();
// 					}}
// 				>
// 					<Tooltip title={isBookmark ? '북마크 제거' : '북마크 추가'} placement='top'>
// 						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
// 							<AddIcon />
// 						</SvgIcon>
// 					</Tooltip>
// 				</Paper>

// 				<Paper
// 					sx={{
// 						width: '24px',
// 						height: '24px',
// 						cursor: 'pointer',
// 						'&:hover': {
// 							backgroundColor: '#F0F0F0',
// 						},
// 					}}
// 					onClick={() => {
// 						onCopyClipboard();
// 						onClose();
// 					}}
// 				>
// 					<Tooltip title={'내용 복사'} placement='top'>
// 						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
// 							<CopyIcon />
// 						</SvgIcon>
// 					</Tooltip>
// 				</Paper>
// 				<Paper
// 					sx={{
// 						width: '24px',
// 						height: '24px',
// 						cursor: 'pointer',
// 						'&:hover': {
// 							backgroundColor: '#F0F0F0',
// 						},
// 					}}
// 					onClick={() => {
// 						onPasteNote();
// 						onClose();
// 					}}
// 				>
// 					<Tooltip title={'노트에 붙여넣기'} placement='top'>
// 						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
// 							<PasteIcon />
// 						</SvgIcon>
// 					</Tooltip>
// 				</Paper>
// 			</Stack>
// 		</Box>
// 	);
// };
