import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import TimbloLogo from './img/sk-poc-logo.png';
import AppleIcon from './img/apple-icon.svg';
import GoogleIcon from './img/google-icon.svg';
import { LoginBorder, LoginContainer, LoginHeader, LoginWrapper, Logo, LogoImage } from './LoginComponents';
import { useAuthAction } from './Action';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../store/AuthStore';
import ConfirmButton from '../../components/button/ConfirmButton';
import Input from './components/Input/Input';
import AccountActions from './components/Link/AccountActions';
import ActionLink from './components/Link/ActionLink';
import ErrorMessage from './components/Message/ErrorMessage';
import IdSaveCheckbox from './components/CheckBox/IdSaveCheckbox';
import { ToastInfo } from '../../components/common/toast/Toast';
import { getSafeUrl } from '../../util/Util';

const Divider = styled.span`
	background-color: #e1e4e6;
	align-self: stretch;
	width: 1px;
	height: 12px;
	margin: auto 0;
`;
const LoginPageV3 = () => {
	const pwRef = useRef();

	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const urlParams = new URLSearchParams(window.location.search);
	const [accessToken] = useState(urlParams.get('accessToken'));
	const [refreshToken] = useState(urlParams.get('refreshToken'));
	const { refreshAuth, login } = useAuthStore();

	//states
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isSaveEmail, setIsSaveEmail] = useState(false);
	const [loading, setLoading] = useState(false);
	const [resCode, setResCode] = useState(0);

	useEffect(() => {
		//id가 저장된 것이 있으면 가져온다
		try {
			const saved = localStorage.getItem('timblo-email');
			if (saved && '' !== saved) {
				setEmail(saved);
				setIsSaveEmail(true);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	useEffect(() => {
		console.log('access', accessToken);
		console.log('refresh', refreshToken);

		if (accessToken) {
			const tokens = { accessToken: accessToken, refreshToken: refreshToken };
			const time =
				3600 * Number(process.env.REACT_APP_COOKIE_EXPIRE_HOUR ? process.env.REACT_APP_COOKIE_EXPIRE_HOUR : 1); //default 1시간
			const expiration = new Date(Date.now() + time * 1000);
			setCookie(process.env.REACT_APP_COOKIE_ALIAS, tokens, {
				path: '/',
				expires: expiration,
			});
			console.log('[Login] save cookie to local storage', tokens);
			try {
				localStorage.setItem('timblo-cookie', JSON.stringify(tokens));
			} catch (e) {
				console.error(e);
			}
		}
	}, [accessToken, refreshToken]);

	useEffect(() => {
		if (cookies[process.env.REACT_APP_COOKIE_ALIAS] && cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken) {
			refreshAuth(cookies[process.env.REACT_APP_COOKIE_ALIAS], () => {
				navigate(getSafeUrl('/home'));
			});
		}
	}, [cookies]);

	const onLogin = e => {
		//로그인
		e.preventDefault();

		if (!email || '' === email.trim()) {
			setResCode(601);
		} else if (!password || '' === password.trim()) {
			setResCode(602);
		} else {
			setLoading(true);

			try {
				if (isSaveEmail) {
					console.log('[Login] save email');
					localStorage.setItem('timblo-email', email);
				} else {
					localStorage.setItem('timblo-email', '');
				}
			} catch (e) {
				console.error(e);
			}

			checkEmail()
				.then(isMember => {
					console.log('isMember', isMember);
					if (isMember) {
						login(
							{ email: email, password: password },
							tokens => {
								console.log('[Login] login ok', tokens);

								const time =
									3600 *
									Number(
										process.env.REACT_APP_COOKIE_EXPIRE_HOUR
											? process.env.REACT_APP_COOKIE_EXPIRE_HOUR
											: 1
									); //default 1시간
								const expiration = new Date(Date.now() + time * 1000);
								setCookie(process.env.REACT_APP_COOKIE_ALIAS, tokens, {
									path: '/',
									expires: expiration,
								});
								try {
									console.log('[Login] save cookie to local storage', tokens);
									localStorage.setItem('timblo-cookie', JSON.stringify(tokens));
								} catch (e) {
									console.error(e);
								}
								const from = location.state?.pathname ?? '/home';
								navigate(from === getSafeUrl('/') ? getSafeUrl('/home') : from, { replace: true });
							},
							code => {
								console.log('login failed', code);
								setResCode(code);
								// switch (code) {
								// 	case 403:
								// 	case 506:
								// 		setMessage('비밀번호가 일치하지 않거나 회원정보가 존재하지 않습니다.');
								// 		break;
								// 	default:
								// 		setMessage(`장애가 발생하였습니다. (${code})`);
								// 		break;
								// }
							},
							() => {
								setLoading(false);
							}
						);
					} else {
						setResCode(603);
						setLoading(false);
					}
				})
				.catch(() => {
					setResCode(400);
					setLoading(false);
				});
		}
	};

	const onJoin = e => {
		navigate(getSafeUrl('/join?email=') + email);
	};
	const onFindPassword = e => {
		navigate(getSafeUrl('/findPassword?email=') + email);
	};

	const checkEmail = () => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_API_URL}/auth/email`, { email: email })
				.then(res => {
					console.log('[Login] check email', res.data.httpCode);
					switch (res.data.httpCode) {
						case 200:
							resolve(true);
							break;
						case 528:
							resolve(false);
							break;
						default:
							reject(res.data.httpCode);
							break;
					}
				})
				.catch(() => {
					reject(400);
				});
		});
	};

	const showLoginResult = () => {
		switch (resCode) {
			case 601:
				return <ErrorMessage text={'아이디(이메일)를 입력해 주세요.'} />;
			case 602:
				return <ErrorMessage text={'비밀번호를 입력해 주세요.'} />;
			case 603:
				return (
					<ErrorMessage
						text={'회원정보가 존재하지 않습니다.'}
						prompt={'해당 이메일로 가입하시겠어요?'}
						onPromptClick={onJoin}
					/>
				);
			case 403:
			case 506:
				return <ErrorMessage text={'비밀번호가 일치하지 않거나 \r\n회원정보가 존재하지 않습니다.'} />;
			case '':
				return <></>;
			default:
				return <ErrorMessage text={`장애가 발생하였습니다. ${resCode}`} />;
		}
	};

	return (
		<LoginContainer>
			<LoginBorder>
				<LoginWrapper>
					<Logo>
						<LogoImage loading='lazy' src={TimbloLogo} alt='Timblo Logo' />
					</Logo>
					<Input
						type='text'
						value={email}
						setValue={setEmail}
						placeholder={'아이디(이메일)'}
						onEnter={() => pwRef.current?.focus()}
					/>
					<Input
						pwRef={pwRef}
						type='password'
						value={password}
						setValue={setPassword}
						placeholder={'비밀번호'}
						onEnter={onLogin}
					/>
					<IdSaveCheckbox value={isSaveEmail} setValue={setIsSaveEmail} />
					{resCode !== 0 && showLoginResult()}
					<ConfirmButton
						isLoading={loading}
						isActive={email}
						type='submit'
						label='로그인'
						onClick={e => {
							onLogin(e);
						}}
					/>

					<AccountActions
						components={
							<>
								<ActionLink label={'비밀번호 찾기'} onClick={onFindPassword} />
								<Divider />
								{/* <ActionLink
									label={'아이디 찾기'}
									onClick={() => ToastInfo('아직 지원하지 않는 기능입니다.')}
								/>
								<Divider /> */}
								<ActionLink label={'계정 가입'} onClick={onJoin} />
							</>
						}
					/>
				</LoginWrapper>
			</LoginBorder>
		</LoginContainer>
	);
};

export default LoginPageV3;
