import React, { useEffect, useState } from 'react';
import './ValidLayout.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SidebarV2 from '../../components/sidebarV2/SidebarV2';

import useContentsStore from '../../store/ContentsStore';
import { ToastError } from '../../components/common/toast/Toast';
import { useCookies } from 'react-cookie';
import ws from '../../libs/NotifyManager';
import useMessageStore from '../../store/MessageStore';
import { useAuthAction } from './Action';
import { getSafeUrl } from '../../util/Util';

const MENUS = [
	{ label: '홈', value: 'home', position: 'top' },
	{ label: '회의록', value: 'contents', position: 'top' },
	{ label: '캘린더', value: 'calendar', position: 'top' },
	{ label: '알림 메시지', value: 'inbox', position: 'top' },
	{ label: '북마크', value: 'bookmark', position: 'top' },
	// { label: '검색', value: 'search', position: 'top' },
	{ label: '휴지통', value: 'recycle', position: 'top' },
	// { label: '워크스페이스 참여', value: 'workspace', position: 'bottom' },
	{ label: '관리자 설정', value: 'admin', position: 'bottom' },
	{ label: '개인 환경 설정', value: 'setting', position: 'bottom' },
	{ label: '로그아웃', value: 'logout', position: 'bottom' },
];

const ValidLayout = ({ sidebarRef, calendarRef }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { isValidSession } = useAuthAction();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [isReady, setIsReady] = useState(false);

	const { onMessage } = useMessageStore();
	const { onContentsChanged, refreshHomeContents, onSttProgressUpdate } = useContentsStore();

	useEffect(() => {
		isValidSession()
			.then(isValid => {
				if (isValid) {
					setIsReady(true);
					initNotifySocket();
				} else {
					navigate(getSafeUrl('/login'), { state: { location } });
				}
			})
			.catch(() => {
				navigate(getSafeUrl('/login'), { state: { location } });
			});

		const handleBeforeUnload = event => {
			const shouldReload = window.confirm('변경사항이 사라질 수 있습니다. 새로고침 하시겠습니까?');

			if (shouldReload) {
				// 페이지를 새로고침
				window.location.reload();
			} else {
				// 새로고침 취소
				event.preventDefault();
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		if (isReady) {
			refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
		}
	}, [isReady]);

	const initNotifySocket = () => {
		try {
			console.log('[Main] initNotifySocket');

			ws.disconnect();

			if (
				cookies &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
			) {
				ws.init(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, message => {
					onMessage(message);

					switch (message.action) {
						case 'CONTENTS_CHANGED':
							{
								if (message.data.status === 'PROGRESS') {
									onSttProgressUpdate(message.data);
								} else if (message.data.status === 'ERROR') {
									onSttProgressUpdate(message.data);
								} else {
									onContentsChanged(message.data);
									onSttProgressUpdate(message.data);
								}
							}

							break;
						default:
							break;
					}
				});
			}
		} catch (e) {
			console.error(e);
			ToastError(1004);
		}
	};

	return (
		isReady && (
			<div className='main-container'>
				<div className='sidebar'>
					<SidebarV2
						ref={sidebarRef}
						menus={MENUS}
						onChangeMenu={menu => {
							navigate(getSafeUrl(`/${menu.value}`));
						}}
						calendarRef={calendarRef}
					/>
				</div>
				<div className='main-content-v2'>
					<Outlet />
				</div>
			</div>
		)
	);
};

export default ValidLayout;
