import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimbloLogo from './img/sk-poc-logo.png';
import {
	Divider,
	HyperLink,
	Input,
	Label,
	LoginContainer,
	LoginHeader,
	LoginWrapper,
	Logo,
	LogoImage,
} from './LoginComponents';
import TimbloCheckboxItem from '../../components/common/CheckBox';
import ConfirmButton from '../../components/button/ConfirmButton';
import SocialLoginButton from './SocialLoginButton';
import AppleIcon from './img/apple-icon.svg';
import GoogleIcon from './img/google-icon.svg';
import axios from 'axios';
import EmailJoinForm from './join/EmailJoinForm';
import ProfileForm from './join/ProfileForm';
import { getSafeUrl } from '../../util/Util';

const JoinPage = () => {
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const [agreeRequired, setAgreeRequired] = useState(false);
	const [email, setEmail] = useState(urlParams.get('email'));
	const [emailCertifyResult, setEmailCertifyResult] = useState(null);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [step, setStep] = useState(1); //default : 1

	const [terms, setTerms] = useState([
		{
			id: 'a(uuid)',
			required: true,
			label: 'AI회의록 이용약관 동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			description: null,
			checked: false,
		},
		{
			id: 'b(uuid)',
			required: true,
			label: '개인정보 수집 및 이용동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			checked: false,
		},
		{
			id: 'c(uuid)',
			required: false,
			label: 'E-mail 광고성 정보 수신동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			description: '다양한 프로모션 소식 및 업데이트 정보를 보내 드립니다.',
			checked: false,
		},
	]);

	useEffect(() => {
		let uncheckedCnt = 0;
		terms.map(item => {
			if (item.required && !item.checked) {
				uncheckedCnt++;
			}
		});

		setAgreeRequired(!uncheckedCnt);
	}, [terms]);

	const drawTerms = () => {
		return (
			<>
				<TimbloCheckboxItem
					label={'약관 전체동의'}
					isMainCheckbox={true}
					onChanged={isChecked => {
						setIsAllChecked(isChecked);
						setTerms(
							terms.map(item => ({
								...item,
								checked: isChecked,
							}))
						);
					}}
					checked={isAllChecked}
				/>
				<Divider />
				{terms &&
					terms.map((item, index) => {
						return (
							<TimbloCheckboxItem
								key={index}
								{...item}
								onChanged={isChecked => {
									setTerms(
										terms.map(term =>
											term.id === item.id ? { ...term, checked: isChecked } : { ...term }
										)
									);
								}}
							/>
						);
					})}
				<ConfirmButton
					label={'다음'}
					isActive={agreeRequired}
					onClick={() => {
						setStep(2);
					}}
				/>
			</>
		);
	};

	const drawSelectJoinType = () => {
		return (
			<>
				{/* <SocialLoginButton icon={GoogleIcon} text='Google로 계속하기' provider='Google' />
				<SocialLoginButton icon={AppleIcon} text='Apple로 계속하기' provider='Apple' /> */}
				<Divider />

				{/* 여기 부터 생략 */}
				<EmailJoinForm
					emailParam={email}
					emailCertifyResult={emailCertifyResult}
					setEmailCertifyResult={setEmailCertifyResult}
					onComplete={() => {
						setStep(3);
					}}
				/>
			</>
		);
	};

	const drawProfileForm = () => {
		return (
			<ProfileForm
				email={email}
				accessToken={emailCertifyResult && emailCertifyResult.accessToken}
				onComplete={() => {
					navigate(getSafeUrl('/login'));
				}}
			/>
		);
	};

	const drawStep = () => {
		switch (step) {
			case 1:
				return drawTerms();
			case 2:
				return drawSelectJoinType();
			case 3:
				return drawProfileForm();
		}
	};

	return (
		<LoginContainer>
			<LoginWrapper>
				<Logo>
					<LogoImage loading='lazy' src={TimbloLogo} alt='Timblo Logo' />
				</Logo>
				{/* <LoginHeader>팀블로 가입하기</LoginHeader> */}
				{drawStep()}
			</LoginWrapper>
		</LoginContainer>
	);
};

export default JoinPage;
