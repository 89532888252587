import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useCookies } from 'react-cookie';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useContentsStore from '../../../store/ContentsStore';

import { ReactComponent as DeleteIcon } from './img/delete.svg';

const DeleteButton = ({
	title = '',
	description = '',
	size = 40,
	confirmText = '확인',
	cancelText = '취소',
	onConfirm,
	onCancel,
	onError,
}) => {
	const [open, setOpen] = React.useState(false);
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { deleteContents } = useContentsStore();

	const handleOpen = e => {
		e.stopPropagation();
		setOpen(true);
	};

	const handleCancel = event => {
		event.stopPropagation();
		onCancel && onCancel();
		setOpen(false);
	};

	const handleConfirm = event => {
		event.stopPropagation();
		onConfirm && onConfirm();
		setOpen(false);

		// deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, { contentId })
		// 	.then(() => {
		// 		onConfirm && onConfirm();
		// 		setOpen(false);
		// 	})
		// 	.catch(code => {
		// 		onError && onError(code);
		// 		setOpen(false);
		// 	});
	};

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					minWidth: `${size}px`,
					width: `${size}px`,
					height: `${size}px`,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: '1px solid #E1E4E6',
					borderRadius: '8px',
					padding: 0,
				}}
			>
				<DeleteIcon stroke='#878D91' />
			</IconButton>

			<Dialog open={open} onClose={handleCancel} maxWidth={'sm'}>
				<DialogContent sx={{ padding: '24px 44px' }}>
					<DialogTitle
						sx={{
							// width: '100%',
							fontSize: '20px',
							fontWeight: 600,
							textAlign: 'center',
							color: 'var(--Dark-grey700, #292A2B)',
							paddingBottom: '4px',
							fontFamily: 'Noto Sans KR',
							borderRadius: '50%',
							padding: 0,
						}}
					>
						{title}
					</DialogTitle>
					<DialogContentText
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							textAlign: 'center',
							color: 'var(--Dark-grey400, #878D91)',
							// marginLeft: '32px',
							// marginRight: '32px',
							marginBottom: '16px',
							fontFamily: 'Noto Sans KR',
						}}
					>
						{description}
					</DialogContentText>
					<DialogActions sx={{ justifyContent: 'center', padding: 0 }}>
						<Button
							sx={{
								background: '#E1E4E6',
								color: '#4D5256',
								width: '152px',
								height: '48px',
								fontFamily: 'Noto Sans KR',
								fontSize: 18,
								fontWeight: 500,
							}}
							onClick={handleCancel}
						>
							{cancelText}
						</Button>
						<Button
							sx={{
								background: 'var(--Blue-primary700, #1C6EFF)',
								color: 'white',
								width: '152px',
								height: '48px',
								fontFamily: 'Noto Sans KR',
								fontSize: 18,
								fontWeight: 500,
							}}
							onClick={handleConfirm}
							autoFocus
						>
							{confirmText}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DeleteButton;
