import React, { useEffect, useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import { Box, Grid2, Stack, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import useContentsStore from '../../../store/ContentsStore';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { formatDateToKoreanStyle, getBlockTitle, getSafeUrl } from '../../../util/Util';

import { ReactComponent as AttendeeIcon } from '../img/attendee.svg';
import { ReactComponent as KeywordIcon } from '../img/keyword.svg';
import { ReactComponent as SttIcon } from '../img/stt.svg';
import { ReactComponent as SummaryIcon } from '../img/summary.svg';
import { ReactComponent as TopicIcon } from '../img/topic.svg';
import { ReactComponent as TasksIcon } from '../img/tasks.svg';
import { ReactComponent as IssuesIcon } from '../img/issues.svg';
import { ReactComponent as BookmarkNoDataIcon } from '../img/bookmark_no_data.svg';

import HtmlTooltip from '../../../components/tooltip/HtmlTooltip';

const HomeBookmark = () => {
	const { home } = useContentsStore();
	const [rows, setRows] = useState([]);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (home.bookmarks && !home.bookmarks.id) {
			setRows(home.bookmarks.map((r, index) => ({ id: index, ...r })));
			setIsLoading(false);
		} else {
			setRows([]);
		}
	}, [home]);

	const onContentClick = ({ contentId }) => {
		navigate(getSafeUrl(`/content/${contentId}`));
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
			}}
		>
			<Typography
				variant='p'
				style={{
					width: '100%',
					height: '58px',
					display: 'flex',
					alignItems: 'center',
					fontSize: '20px',
					fontWeight: 600,
					color: '#292A2B',
					padding: '16px 24px',
					boxSizing: 'border-box',
				}}
			>
				최근 북마크
			</Typography>

			<Box
				sx={{
					flexGrow: 1,
					padding: `0 16px 0 0`,
					// padding: `16px 10px`,
					height: 'calc(100% - 66px)', // 부모 높이에서 66px 제외
					boxSizing: 'border-box',
				}}
			>
				{rows && rows.length ? (
					<ResponsiveLayout data={rows} onClickContent={onContentClick} />
				) : isLoading ? (
					<></>
				) : (
					<NoDataBookmark />
				)}
			</Box>
		</Box>
	);
};

export default HomeBookmark;

const ResponsiveLayout = ({ data, onClickContent }) => {
	const dataLength = data.length;

	if (dataLength <= 2) {
		return (
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					gap: 2,
				}}
			>
				{data.map((item, index) => (
					<BookmarkItem
						data={item}
						key={index}
						fullWidth
						isFirstData={index === 0}
						onClickContent={onClickContent}
					/>
				))}
			</Box>
		);
	} else if (dataLength >= 3) {
		const leftData = data.slice(0, 2);
		const rightData = data.slice(2);

		return (
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'row',
					// gap: 2,
				}}
			>
				{/* 왼쪽 영역 */}
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						gap: 4,
						maxWidth: rightData.length ? '50%' : '100%',
					}}
				>
					{leftData.map((item, index) => (
						<BookmarkItemV2
							data={item}
							key={index}
							size='large'
							isFirstData={index === 0}
							onClickContent={onClickContent}
						/>
					))}
				</Box>

				{/* 오른쪽 영역 */}
				<Box
					sx={{
						// flex: 1/,
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						maxWidth: '50%',
						width: '100%',
					}}
				>
					{rightData.map((item, index) => (
						<BookmarkItemV2
							key={index}
							data={item}
							size='small'
							isFirstData={index === 0}
							onClickContent={onClickContent}
						/>
					))}
				</Box>
			</Box>
		);
	}

	return null;
};

const BookmarkItem = ({ data, size = 'large', fullWidth, isFirstData, onClickContent }) => {
	const getIconFromKey = size => {
		if (data) {
			switch (data.key) {
				case 'segments':
					return <SttIcon width={size} height={size} />;

				case 'keywords':
					return <KeywordIcon width={size} height={size} />;

				case 'speakerInfo':
					return <AttendeeIcon width={size} height={size} />;

				case 'summary':
				case 'summaryTime':
					return <SummaryIcon width={size} height={size} />;

				case 'topics':
					return <TopicIcon width={size} height={size} />;

				case 'segments':
				case 'mergedSegments':
					return <SttIcon width={size} height={size} />;

				case 'tasks':
					return <TasksIcon width={size} height={size} />;

				case 'issues':
					return <IssuesIcon width={size} height={size} />;

				default:
					return <>Unknown</>;
			}
		}
	};

	const getBookmarkText = () => {
		if (data) {
			switch (data.key) {
				case 'speakerInfo':
					return data.data.map(item => item.name).join(', ');

				case 'segments':
				case 'mergedSegments':
					return data.data[0].text;

				case 'summaryTime':
					return `${data.data[0].index}. ${data.data[0].topic}`;

				default:
					return data.data.join(', ');
			}
		}
	};

	return (
		<>
			{!isFirstData && <Divider />}
			<Stack
				spacing={1}
				direction='row'
				sx={{
					width: '100%',
					height: fullWidth || size === 'large' ? 124 : 84,
					boxSizing: 'border-box',
					cursor: 'pointer',
				}}
				onClick={() => onClickContent(data)}
			>
				<Box
					sx={{
						width: fullWidth || size === 'large' ? 64 : 56,
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'flex-start',
						flexShrink: 0,
					}}
				>
					<SvgIcon
						component='svg'
						inheritViewBox
						sx={{
							width: fullWidth || size === 'large' ? 32 : 24,
							height: fullWidth || size === 'large' ? 32 : 24,
						}}
					>
						{getIconFromKey(fullWidth || size === 'large' ? 32 : 24)}
					</SvgIcon>
				</Box>

				<Box sx={{ width: `calc(100% - 80px)` }}>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						sx={{
							width: '100%',
							height: 22,
							marginBottom: fullWidth || size === 'large' ? '16px' : '8px',
						}}
					>
						<Typography
							sx={{
								fontFamily: 'Noto Sans KR',
								fontSize: fullWidth || size === 'large' ? 16 : 14,
								fontWeight: 600,
								color: '#878D91',
							}}
						>
							{getBlockTitle(data.key)}
						</Typography>
						<Typography
							sx={{
								fontFamily: 'Noto Sans KR',
								fontSize: fullWidth || size === 'large' ? 16 : 14,
								fontWeight: 500,
								color: '#878D91',
								marginLeft: 'auto',
							}}
						>
							생성일 | {dayjs(data.time).locale('ko').format('LLL')}
						</Typography>
					</Stack>

					<HtmlTooltip
						title={
							<Typography
								sx={{
									width: '100%',
									fontSize: fullWidth || size === 'large' ? 18 : 16,
									fontWeight: 600,
									color: '#28323C',
									fontFamily: 'Noto Sans KR',
									display: 'block',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}
							>
								{getBookmarkText()}
							</Typography>
						}
						arrow
						placement='top-start'
						slotProps={{
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [-10, 0],
										},
									},
								],
							},
						}}
					>
						<Typography
							sx={{
								width: '100%',
								fontSize: fullWidth || size === 'large' ? 18 : 16,
								fontWeight: 600,
								color: '#28323C',
								fontFamily: 'Noto Sans KR',
								paddingBottom: fullWidth || size === 'large' ? '8px' : '4px',
								display: 'block',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{getBookmarkText()}
						</Typography>
					</HtmlTooltip>

					<Typography
						sx={{
							width: '100%',
							fontSize: fullWidth || size === 'large' ? 16 : 14,
							fontWeight: 500,
							color: '#878D91',
							fontFamily: 'Noto Sans KR',
							display: 'block',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}
					>
						회의록명 |{' '}
						<HtmlTooltip
							title={
								<Typography
									sx={{
										width: '100%',
										fontSize: fullWidth || size === 'large' ? 16 : 14,
										fontWeight: 500,
										color: '#292A2B',
										fontFamily: 'Noto Sans KR',
										display: 'block',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
									}}
								>
									{data.title}
								</Typography>
							}
							placement='top-start'
							slotProps={{
								popper: {
									modifiers: [
										{
											name: 'offset',
											options: {
												offset: [-10, 0],
											},
										},
									],
								},
							}}
						>
							{data.title}
						</HtmlTooltip>
					</Typography>
				</Box>
			</Stack>
		</>
	);
};

const BookmarkItemV2 = ({ data, size = 'large', fullWidth, onClickContent }) => {
	const getIconFromKey = size => {
		if (data) {
			switch (data.key) {
				case 'segments':
					return <SttIcon width={size} height={size} />;

				case 'keywords':
					return <KeywordIcon width={size} height={size} />;

				case 'speakerInfo':
					return <AttendeeIcon width={size} height={size} />;

				case 'summary':
				case 'summaryTime':
					return <SummaryIcon width={size} height={size} />;

				case 'topics':
					return <TopicIcon width={size} height={size} />;

				case 'segments':
				case 'mergedSegments':
					return <SttIcon width={size} height={size} />;

				case 'tasks':
					return <TasksIcon width={size} height={size} />;

				case 'issues':
					return <IssuesIcon width={size} height={size} />;

				default:
					return <>Unknown</>;
			}
		}
	};

	const getBookmarkText = () => {
		if (data) {
			switch (data.key) {
				case 'speakerInfo':
					return data.data.map(item => item.name).join(', ');

				case 'segments':
				case 'mergedSegments':
					return data.data[0].text;

				case 'summaryTime':
					return `${data.data[0].index}. ${data.data[0].topic}`;

				default:
					return data.data.join(', ');
			}
		}
	};

	return (
		<Stack
			spacing={2}
			direction={'row'}
			sx={{
				width: '100%',
				height: size === 'large' ? 122 : 86,
				padding: size === 'large' ? `16px 24px 0 24px` : `8px 24px 0 24px`,
				boxSizing: 'border-box',
				cursor: 'pointer',
			}}
			onClick={() => onClickContent(data)}
		>
			<Box sx={{ display: 'flex' }}>{getIconFromKey(size === 'large' ? 32 : 24)}</Box>
			<Box sx={{ flex: 1, borderBottom: '1px solid #E1E4E6', width: '100%', overflow: 'hidden' }}>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					sx={{
						width: '100%',
						height: 22,
						marginBottom: fullWidth || size === 'large' ? '16px' : '4px',
					}}
				>
					<Typography
						sx={{
							fontFamily: 'Noto Sans KR',
							fontSize: fullWidth || size === 'large' ? 16 : 14,
							fontWeight: 600,
							color: '#878D91',
						}}
					>
						{getBlockTitle(data.key)}
					</Typography>
					<Typography
						sx={{
							fontFamily: 'Noto Sans KR',
							fontSize: fullWidth || size === 'large' ? 16 : 14,
							fontWeight: 500,
							color: '#878D91',
							marginLeft: 'auto',
						}}
					>
						생성일 | {dayjs(data.time).locale('ko').format('LLL')}
					</Typography>
				</Stack>

				<HtmlTooltip
					title={
						<Typography
							sx={{
								width: '100%',
								fontSize: fullWidth || size === 'large' ? 18 : 16,
								fontWeight: 600,
								color: '#28323C',
								fontFamily: 'Noto Sans KR',
								display: 'block',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{getBookmarkText()}
						</Typography>
					}
					arrow
					placement='top-start'
					slotProps={{
						popper: {
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [-10, 0],
									},
								},
							],
						},
					}}
				>
					<Typography
						sx={{
							width: `100%`,
							fontSize: fullWidth || size === 'large' ? 18 : 16,
							fontWeight: 600,
							color: '#28323C',
							fontFamily: 'Noto Sans KR',
							paddingBottom: fullWidth || size === 'large' ? '8px' : '2px',
							display: 'block',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							// height: 22,
						}}
					>
						{getBookmarkText()}
					</Typography>
				</HtmlTooltip>

				<Typography
					sx={{
						width: '100%',
						fontSize: fullWidth || size === 'large' ? 16 : 14,
						fontWeight: 500,
						color: '#878D91',
						fontFamily: 'Noto Sans KR',
						display: 'block',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					회의록명 |
					<HtmlTooltip
						title={
							<Typography
								sx={{
									width: '100%',
									fontSize: fullWidth || size === 'large' ? 16 : 14,
									fontWeight: 500,
									color: '#292A2B',
									fontFamily: 'Noto Sans KR',
									display: 'block',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}
							>
								{data.title}
							</Typography>
						}
						placement='top-start'
						slotProps={{
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [-10, 0],
										},
									},
								],
							},
						}}
					>
						{data.title}
					</HtmlTooltip>
				</Typography>
				{/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Noto Sans KR', color: '#878D91' }}>
						{getBlockTitle(data.key)}
					</Typography>
					<Typography
						sx={{
							fontFamily: 'Noto Sans KR',
							fontSize: fullWidth || size === 'large' ? 16 : 14,
							fontWeight: 500,
							color: '#878D91',
							marginLeft: 'auto',
						}}
					>
						생성일 | {dayjs(data.time).locale('ko').format('LLL')}
					</Typography>
				</Box> */}
			</Box>
		</Stack>
	);
};

const NoDataBookmark = () => {
	return (
		<Stack
			spacing={1}
			direction={'column'}
			sx={{ width: '100%', height: '100%' }}
			justifyContent={'center'}
			alignItems={'center'}
		>
			<SvgIcon
				component='svg'
				inheritViewBox
				sx={{
					width: 80,
					height: 80,
				}}
			>
				<BookmarkNoDataIcon />
			</SvgIcon>
			<Typography sx={{ fontSize: 20, fontWeight: 600, color: '#28323C', fontFamily: 'Noto Sans KR' }}>
				최근 북마크가 없습니다.
			</Typography>
			<Typography sx={{ fontSize: 16, fontWeight: 500, color: '#858C99', fontFamily: 'Noto Sans KR' }}>
				다시 보고 싶은 부분이 있다면, 북마크 기능을 활용해 보세요.
			</Typography>
		</Stack>
	);
};
