import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DateCalendar, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import React, { useState } from 'react';

const CALENDAR_HEADER_HEIGHT = 62;
const CALENDAR_DAY_WEEK_HEIGHT = 30;
const CALENDAR_FOOTER_HEIGHT = 40;

const CustomCalendarHeader = props => {
	const { currentMonth, onMonthChange, onViewChange, view, selectedDate, setSelectedDate } = props;

	const isToday = dayjs().isSame(selectedDate, 'day');
	const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
	const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');

	const handleHeaderClick = () => {
		onViewChange(view === 'month' ? 'day' : 'month');
	};

	return (
		<Box
			display='flex'
			sx={{
				width: '100%',
				height: CALENDAR_HEADER_HEIGHT,
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: '30px',
				padding: '0 20px',
				boxSizing: 'border-box',
				position: 'relative',
			}}
		>
			<Typography
				style={{
					// width: '100%',
					height: '66px',
					display: 'flex',
					alignItems: 'center',
					fontSize: '20px',
					fontWeight: 600,
					color: '#292A2B',
					boxSizing: 'border-box',
					cursor: 'pointer',
				}}
				onClick={handleHeaderClick}
			>
				{currentMonth.format('YYYY년 MM월')}
			</Typography>
			{/* 
			<Button
				variant='outlined'
				size='small'
				sx={{
					position: 'absolute',
					left: '44%',
					border: '1px solid #CED3D6',
					padding: '4px 12px',
					borderRadius: '4px',

					color: isToday ? '#E1E4E6' : '#292A2B',
					background: isToday ? '#F9FAFB' : '#fff',
					minWidth: 'unset',
					width: '54px',
					height: '30px',
				}}
				disabled={isToday ? true : false}
				onClick={() => setSelectedDate(dayjs())}
			>
				오늘
			</Button> */}

			<Stack spacing={0.5} direction='row' sx={{ marginLeft: 'auto' }}>
				<IconButton sx={{ width: '25px' }} onClick={selectPreviousMonth} title='Previous month'>
					<ChevronLeft />
				</IconButton>
				<IconButton sx={{ width: '25px' }} onClick={selectNextMonth} title='Next month'>
					<ChevronRight />
				</IconButton>
			</Stack>
		</Box>
	);
};

const CustomDay = props => {
	const { day, outsideCurrentMonth, today, selected, ...other } = props;
	const date = dayjs(day);

	return (
		<PickersDay
			{...other}
			day={day}
			outsideCurrentMonth={outsideCurrentMonth}
			sx={{
				height: '100%',
				opacity: outsideCurrentMonth ? 0.5 : 1,
				color: selected ? '#fff !important' : date.day() === 0 ? 'red !important' : 'inherit',
				borderColor: today ? '#1C6EFF' : 'transparent',
				borderWidth: 1,
				borderStyle: 'solid',
				backgroundColor: selected ? '#1C6EFF' : 'transparent',
				position: 'relative',
				boxSizing: 'border-box',

				'&:focus': {
					backgroundColor: '#1C6EFF',
					position: 'relative',
				},
			}}
		>
			{day.format('D')}
		</PickersDay>
	);
};

const MuiCalendar = ({ date, onChange }) => {
	const [selectedDate, setSelectedDate] = useState(dayjs(date ?? null));

	const handleOnChange = newDate => {
		setSelectedDate(newDate);

		onChange && onChange(newDate);
	};

	return (
		<Box
			sx={{
				width: 368,
				height: 365,
				// minHeight: 404,
				// maxHeight: 402,
				borderRadius: '8px',
				boxShadow: `2px 2px 16px 0px #00000029`,
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
				<DateCalendar
					value={selectedDate}
					onChange={handleOnChange}
					slots={{
						calendarHeader: props => (
							<CustomCalendarHeader
								{...props}
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
							/>
						),
						day: CustomDay,
					}}
					dayOfWeekFormatter={date => {
						const day = dayjs(date).day();
						return (
							<span
								style={{
									display: 'inline-block',
									width: '30px',
									height: '30px',
									fontSize: '16px',
									fontWeight: 500,
									color: day === 0 ? 'red' : 'inherit',
								}}
							>
								{date.format('dd')}
							</span>
						);
					}}
					showDaysOutsideCurrentMonth
					sx={{
						width: '100%',
						height: '100% !important',
						maxHeight: 'none !important',
						flex: 1,

						'& .MuiMonthCalendar-root': {
							width: '100% !important',
							height: '100% !important',
						},

						'& .MuiYearCalendar-root': {
							width: '100% !important',
							height: '100% !important',
							maxHeight: '100%',
						},

						'& .MuiDayCalendar-root': {
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'stretch',
							flex: 1,
						},

						'& .MuiDateCalendar-viewTransitionContainer': {
							flex: 1,
							// height: `calc(100% - ${CALENDAR_HEADER_HEIGHT}px)`,
						},

						'& .MuiDateCalendar-viewTransitionContainer > div': {
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'stretch',
							height: '100%',
						},

						'& .MuiPickersYear-yearButton.Mui-selected': {
							color: '#fff',
						},

						'& .MuiPickersMonth-root': {
							height: '50px !important',
						},

						'& .MuiPickersSlideTransition-root': {
							flex: 1,
							// height: `calc(100% - (${CALENDAR_DAY_WEEK_HEIGHT}px))`,
							// minHeight: 300,
							minHeight: 0,
							overflow: 'hidden',
						},

						'& .MuiDayCalendar-header': {
							height: CALENDAR_DAY_WEEK_HEIGHT,
							justifyContent: 'space-around',
						},

						'& .MuiDayCalendar-weekDayLabel': {
							height: CALENDAR_DAY_WEEK_HEIGHT,
						},

						'& .MuiPickersDay-root': {
							width: '30px',
							height: '30px !important',
							margin: '11px',
							// padding: '10px !important',
							fontSize: '16px',
							fontWeight: '500',
							boxSizing: 'border-box',
							position: 'relative',
							fontSize: `16px !important`,
							flexShrink: 0,
						},
						'& .MuiPickersMonth-monthButton': {
							width: '75px',
							height: '50px',
						},
						'& .MuiPickersMonth-monthButton.Mui-selected': {
							color: 'white', // 선택된 텍스트 색상
						},
					}}
				/>
			</LocalizationProvider>
		</Box>
	);
};

export default MuiCalendar;
