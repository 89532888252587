import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ConfirmButton from '../../components/button/ConfirmButton';
import Input from './components/Input/Input';
import CloseIcon from '@mui/icons-material/Close';
import { ToastInfo } from '../../components/common/toast/Toast';
import useAuthStore from '../../store/AuthStore';
import { useCookies } from 'react-cookie';

const ChangePasswordPopup = ({ onClose }) => {
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [errorCode, setErrorCode] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const { changePassword } = useAuthStore();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	const isValidPassword = password => {
		// 조건: 8~20자, 국문/영문, 숫자, 특수문자를 포함
		const lengthRegex = /^.{8,20}$/; // 길이가 8~20자인지 확인
		const combinationRegex = /(?=.*[A-Za-z가-힣])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`_+=-])/; // 영문/한글, 숫자, 특수문자 포함 여부

		// 모든 조건을 만족하는지 확인
		const isValid = lengthRegex.test(password) && combinationRegex.test(password);

		return isValid;
	};

	return (
		<Box
			sx={{
				width: '365px',
				height: '460px',
				backgroundColor: '#ffffff',
				border: '1px solid rgba(206, 211, 214, 1)',
				borderRadius: '10px',
				padding: '20px',
			}}
		>
			<Stack>
				<Stack>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
					</Box>
				</Stack>
				<Stack>
					<Typography
						sx={{
							fontFamily: 'Noto Sans KR',
							fontSize: '20px',
							fontWeight: 600,
							color: '#292A2B',
							textAlign: 'left',
							lineHeight: '26px',
						}}
					>
						{'비밀번호 재설정을 위해'}
					</Typography>
					<Typography
						sx={{
							display: 'flex',
							fontFamily: 'Noto Sans KR',
							fontSize: '20px',
							fontWeight: 600,
							color: '#1C6EFF',
							textAlign: 'left',
							lineHeight: '26px',
						}}
					>
						{'새 비밀번호를 입력'}
						<Typography
							sx={{
								fontFamily: 'Noto Sans KR',
								fontSize: '20px',
								fontWeight: 600,
								color: '#292A2B',
								textAlign: 'left',
								lineHeight: '26px',
							}}
						>
							해 주세요.
						</Typography>
					</Typography>

					<Typography></Typography>
				</Stack>
				<Stack sx={{ height: '24px' }} />
				<Stack sx={{}}>
					<Stack>
						<Typography
							sx={{ fontFamily: 'Noto Sans KR', fontSize: '14px', fontWeight: '500', color: '#374553' }}
						>
							새 비밀번호
						</Typography>
					</Stack>
					<Input
						sx={{ flex: 1 }}
						type='password'
						value={password}
						setValue={setPassword}
						error={'' !== password && !isValidPassword(password)}
						placeholder={'비밀번호를 입력해 주세요.'}
					/>
				</Stack>

				<Stack>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#878D91',
							fontFamily: 'Noto Sans KR',
						}}
					>
						{'*국문/영문, 숫자, 특수문자를 조합하여 8~20자로 입력해주세요.'}
					</Typography>
				</Stack>

				{'' !== password && !isValidPassword(password) && (
					<Stack sx={{ marginTop: '12px' }}>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{'비밀번호를 다시 확인해 주세요.'}
						</Typography>
					</Stack>
				)}

				<Stack sx={{ height: '24px' }}></Stack>
				<Stack sx={{}}>
					<Stack>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#374553', fontFamily: 'Noto Sans KR' }}
						>
							새 비밀번호 확인
						</Typography>
					</Stack>
					<Input
						sx={{ flex: 1 }}
						type='password'
						value={passwordConfirm}
						error={isValidPassword(password) && '' !== passwordConfirm && password !== passwordConfirm}
						setValue={setPasswordConfirm}
						placeholder={'비밀번호를 한 번 더 입력해 주세요.'}
					/>
				</Stack>

				{isValidPassword(password) && '' !== passwordConfirm && password !== passwordConfirm && (
					<Stack sx={{ marginTop: '12px' }}>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{'비밀번호가 일치하지 않습니다.'}
						</Typography>
					</Stack>
				)}

				{'' !== errorCode && (
					<Stack>
						<Typography
							sx={{ fontSize: '14px', fontWeight: '500', color: '#F9463B', fontFamily: 'Noto Sans KR' }}
						>
							{errorCode}
						</Typography>
					</Stack>
				)}
				<Stack sx={{ height: '24px' }}></Stack>
				<Stack>
					<ConfirmButton
						label={'비밀번호 변경하기'}
						isActive={isValidPassword(password) && password === passwordConfirm}
						isLoading={isLoading}
						onClick={async () => {
							try {
								setLoading(true);
								changePassword(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									password,
									passwordConfirm,
									() => {
										setPassword('');
										setPasswordConfirm('');
										ToastInfo('비밀번호가 변경되었습니다.');
									},
									code => {
										setErrorCode(`비밀번호 변경이 실패하였습니다. ${code}`);
									},
									() => {
										setLoading(false);
									}
								);

								// const code = await passwordChange();
								// if (200 === code) {
								// 	//성공
								// } else {
								// 	setErrorCode(`비밀번호 변경이 실패하였습니다. (${code})`);
								// }
							} catch (e) {
								console.error(e);
								setErrorCode('인증 요청 중 오류가 발생했습니다. (-600)');
								setLoading(false);
							}
						}}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default ChangePasswordPopup;
