import React from 'react';
import './Toast.css';

import { ReactComponent as DoneIcon } from './img/done.svg';
import { ReactComponent as ErrorIcon } from './img/error.svg';
import { toast } from 'react-toastify';

const drawIcon = type => {
	switch (type) {
		case 'error':
			return <ErrorIcon />;

		case 'debug':
		case 'warn':
		case 'info':
		default:
			return <DoneIcon />;
	}
};

const Toast = (type = 'done', title, description, closeOnClick = true, autoClose = 3000, onClickToast = () => {}) => {
	const handleOnClick = closeToast => {
		closeOnClick && closeToast();
		onClickToast && onClickToast();
	};

	return toast(
		({ closeToast }) => (
			<div className={`toast-container ${type}`} onClick={() => handleOnClick(closeToast)}>
				<div className='title'>
					<div className='icon'>{drawIcon(type)}</div>
					<div className='text'>{title}</div>
				</div>
				<div className='separator' />
				<div className='description'>{description}</div>
			</div>
		),
		{ autoClose: autoClose }
	);
};

const ToastError = (
	code,
	description = '장애가 발생하였습니다.',
	title = 'A.Biz',
	closeOnClick = true,
	autoClose = 3000,
	onClickToast = () => {}
) => {
	const desc = 0 < code ? `${description} (${code})` : description;
	return Toast('error', title, desc, closeOnClick, autoClose, onClickToast);
};

const ToastInfo = (description, title = 'A.Biz', closeOnClick = true, autoClose = 3000, onClickToast = () => {}) => {
	return Toast('info', title, description, closeOnClick, autoClose, onClickToast);
};

const ToastComponent = ({ closeToast, title = 'A.Biz', description, type = 'info' }) => {
	return (
		<div className={`toast-container ${type}`}>
			<div className='title'>
				<div className='icon'>{drawIcon(type)}</div>
				<div className='text'>{title}</div>
			</div>
			<div className='separator' />
			<div className='description'>{description}</div>
		</div>
	);
};

export { Toast, ToastInfo, ToastError, ToastComponent };
