import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './SidebarV2.css';
import Avatar from 'react-avatar';
import Popup from 'reactjs-popup';

import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as RecordIcon } from './img/record.svg';
import { ReactComponent as UploadIcon } from './img/upload.svg';
import { ReactComponent as HomeIcon } from './img/home.svg';
import { ReactComponent as ContentsIcon } from './img/contents.svg';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as InboxIcon } from './img/inbox.svg';
import { ReactComponent as IntegrateIcon } from './img/integrate.svg';
import { ReactComponent as MeetingIcon } from './img/meeting.svg';
import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import { ReactComponent as RecycleIcon } from './img/recycle.svg';
import { ReactComponent as SettingIcon } from './img/setting.svg';
import { ReactComponent as LogoutIcon } from './img/logout.svg';
import { ReactComponent as BookmarkIcon } from './img/bookmark.svg';
import { ReactComponent as SKLogo } from './img/sk_logo.svg';
import { ReactComponent as WorkspaceAdminIcon } from './img/admin.svg';

import useAuthStore from '../../store/AuthStore';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import PrivateSetting from '../popup/setting/action/privateSetting/PrivateSetting';

import { Box, Modal, Stack, Typography, LinearProgress, Button } from '@mui/material';
import useContentsStore from '../../store/ContentsStore';
import { Toast, ToastInfo, ToastComponent, ToastError } from '../common/toast/Toast';
import { toast } from 'react-toastify';
import RecorderV2 from '../popup/record/RecorderV2';
import UploadOption from '../popup/upload/UploadOption';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';
import FileSaver from 'file-saver';
import { getSafeUrl } from '../../util/Util';

const SidebarV2 = forwardRef(({ menus = [], onChangeMenu = () => {}, calendarRef }, ref) => {
	const recorderControls = useVoiceVisualizer();
	const inputRef = useRef();
	const recordRef = useRef();

	const navigate = useNavigate();

	const { auth, logout } = useAuthStore();
	const { fileUpload, sttProgress } = useContentsStore();
	const [cookies, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	const [selectedMenu, setSelectedMenu] = useState(menus[0]);

	const [fileType, setFileType] = useState(null);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [privateSettingCloseTrigger, setPrivateSettingCloseTrigger] = useState(true);

	// 녹음 파일 변환 팝업
	const [isOpenProgressPopup, setIsOpenProgressPopup] = useState(false);

	useImperativeHandle(ref, () => ({
		onClickUpload: () => inputRef.current.click(),
		onClickRecord: () => recordRef.current.click(),
	}));

	useEffect(() => {
		if (file) {
			setIsOpenModal(true);
		}
	}, [file]);

	useEffect(() => {
		if (!sttProgress.list.length) {
			setIsOpenProgressPopup(false);
		}
	}, [sttProgress]);

	const drawMenus = () => {
		const drawMenuIcon = menu => {
			switch (menu) {
				case '':
				case 'home':
					return <HomeIcon />;

				case 'contents':
					return <MeetingIcon />;

				case 'search':
					return <SearchIcon />;

				case 'inbox':
					return <InboxIcon />;

				case 'integrate':
					return <IntegrateIcon />;

				case 'calendar':
					return <CalendarIcon />;

				case 'recycle':
					return <RecycleIcon />;

				case 'setting':
					return <SettingIcon />;

				case 'logout':
					return <LogoutIcon />;

				case 'bookmark':
					return <BookmarkIcon />;

				default:
					return <>{menu}</>;
			}
		};

		const onClickMenu = menu => {
			setSelectedMenu(menu);

			onChangeMenu && onChangeMenu(menu);
		};

		return menus
			.filter(menu => menu.position === 'top')
			.map(menu => (
				<div
					key={menu.value} // key는 menu.value를 사용
					className={`menu ${selectedMenu === menu ? 'active' : ''}`}
					onClick={() => onClickMenu(menu)}
				>
					<div className='icon'>{drawMenuIcon(menu.value)}</div>
					<div className='text'>{menu.label}</div>
				</div>
			));
	};

	const drawBottomMenus = () => {
		const drawMenuIcon = menu => {
			switch (menu) {
				case 'admin':
					return <WorkspaceAdminIcon />;

				case 'setting':
					return <SettingIcon />;

				case 'logout':
					return <LogoutIcon />;

				default:
					return <>{menu}</>;
			}
		};

		const onClickMenu = menu => {
			if (menu.value === 'logout') {
				onLogout();
			} else if (menu.value === 'admin') {
				window.open('/bo/', '_blank');
			}
		};

		return menus
			.filter(menu => menu.position === 'bottom')
			.map(menu => {
				if (menu.value === 'setting') {
					return (
						<Popup
							key={menu.value} // key가 이 위치에도 필요
							trigger={
								<div key={menu.value} className={`menu`}>
									<div className='icon'>{drawMenuIcon(menu.value)}</div>
									<div className='text'>{menu.label}</div>
								</div>
							}
							closeOnDocumentClick={privateSettingCloseTrigger}
							position={'top left'}
						>
							{close => (
								<PrivateSetting
									setPrivateSettingCloseTrigger={setPrivateSettingCloseTrigger}
									onClose={() => close()}
								/>
							)}
						</Popup>
					);
				} else if (menu.value === 'admin') {
					if (isAdminUser()) {
						return (
							<div
								key={menu.value} // key는 menu.value를 사용
								className={`menu`}
								onClick={() => onClickMenu(menu)}
							>
								<div className='icon'>{drawMenuIcon(menu.value)}</div>
								<div className='text'>{menu.label}</div>
							</div>
						);
					}
				} else {
					return (
						<div
							key={menu.value} // key는 menu.value를 사용
							className={`menu ${selectedMenu === menu ? 'active' : ''}`}
							onClick={() => onClickMenu(menu)}
						>
							<div className='icon'>{drawMenuIcon(menu.value)}</div>
							<div className='text'>{menu.label}</div>
						</div>
					);
				}
			});
	};

	const onLogout = () => {
		logout(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			() => {
				navigate(getSafeUrl('/'));
			},
			code => {
				console.log('logout failed', code);
			},
			() => {
				removeCookie(process.env.REACT_APP_COOKIE_ALIAS);
			}
		);
	};

	const handleFileChange = event => {
		const file = event.target.files[0];

		if (file) {
			setFile(file);
			setFileName(file.name);
			setFileType('file');
		}
	};

	const handleOnRecordComplete = (file, fileName) => {
		if (file && fileName) {
			setFile(file);
			setFileName(fileName);
			setFileType('record');
		}
	};

	const isAdminUser = () => {
		return auth && auth.member && auth.member.role === 'ADMIN';
	};

	const handleOnSubmit = options => {
		setIsOpenModal(false);

		if (file && fileName) {
			const currentToast = toast(<ToastComponent description={`${fileName} 업로드 중 - 0%`} />);

			fileUpload(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				file,
				fileName,
				options,
				fileType,
				auth,
				() => {
					console.info('File upload OK', 'Recorder');
					console.flush(
						'Recorder',
						auth.user.profile.email,
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						true
					);
				},
				code => {
					console.error(`File upload Failed (${fileName})`, 'Recorder');
					console.flush(
						'Recorder',
						auth.user.profile.email,
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						true
					);
					toast.update(currentToast, {
						render: props => (
							<ToastComponent
								{...props}
								description={`${fileName} 업로드를 실패했습니다.`}
								type='error'
							/>
						),
						autoClose: 3000,
					});

					if (fileType === 'record') {
						FileSaver.saveAs(file, fileName);
					}
				},
				() => {
					setFile(null);
					setFileName('');
				},
				(id, progress) => {
					if (Number(progress) < 100) {
						toast.update(currentToast, {
							render: props => (
								<ToastComponent {...props} description={`${fileName} 업로드 중 - ${progress}%`} />
							),
						});
					} else if (Number(progress) === 100) {
						toast.update(currentToast, {
							render: props => <ToastComponent {...props} description={`${fileName} 업로드 완료`} />,
							autoClose: 3000,
						});
					}

					console.log('[progress]', progress);
				}
			);
		}
	};

	return (
		<div className='sidebar-container'>
			{auth && auth.member && auth.member.workspace ? (
				<>
					<div className='logo'>
						<SKLogo
							cursor={'pointer'}
							onClick={() => {
								navigate(getSafeUrl('/'));
								setSelectedMenu(menus[0]);
							}}
						/>
					</div>

					<div className='buttons'>
						{/* <Popup
							trigger={
								<div className='button'>
									<div className='icon' ref={recordRef}>
										<RecordIcon />
									</div>
									<div className='text'>녹음</div>
								</div>
							}
							className='recorder-v2-popup'
							position={'bottom left'}
							keepTooltipInside='.main-container'
							nested
						>
							{close => (
								<RecorderV2
									onClose={close}
									onRecordComplete={handleOnRecordComplete}
									recorderControls={recorderControls}
								/>
							)}
						</Popup> */}

						<RecorderV2 onRecordComplete={handleOnRecordComplete} recorderControls={recorderControls} />

						<div
							className='button'
							onClick={() => {
								if (inputRef && inputRef.current) {
									inputRef.current.click();
								}
							}}
						>
							<div className='icon'>
								<UploadIcon style={{ border: '1px solid #CED3D6', borderRadius: '50%' }} />
							</div>
							<div className='text'>파일 업로드</div>
							<input
								ref={inputRef}
								style={{ display: 'none' }}
								type='file'
								onChange={event => {
									handleFileChange(event);
									// 같은 파일을 다시 선택할 수 있도록 input 값을 초기화
									if (inputRef && inputRef.current) {
										inputRef.current.value = '';
									}
								}}
								accept='audio/*,video/*,.aac'
							/>
						</div>
					</div>
					<div className='menus'>
						{/* <div className='title'>전체 메뉴</div> */}
						<div className='menu-list'>{drawMenus()}</div>

						<div className='separator' style={{ position: 'relative' }}>
							{sttProgress.list.length ? (
								<Box
									sx={{
										position: 'absolute',
										minHeight: isOpenProgressPopup
											? sttProgress.list.length === 1
												? 110
												: 182
											: 38,
										maxHeight: isOpenProgressPopup
											? sttProgress.list.length === 1
												? 110
												: 182
											: 38,
										width: 218,
										boxShadow: `2px 4px 10px 0px #00000033`,
										borderRadius: `8px`,
										fontFamily: 'Noto Sans KR',
										display: 'flex',
										flexDirection: 'column',
										background: '#fff',
										bottom: `15px`,
										left: -5,
										transition: 'all 0.3s ease-in-out',
									}}
								>
									<Stack
										direction='row'
										spacing={1}
										sx={{
											height: '38px',
											cursor: 'pointer',
											padding: '7px 0',
										}}
										alignItems={'center'}
										justifyContent={'center'}
										onClick={() => setIsOpenProgressPopup(prev => !prev)}
									>
										<Typography
											sx={{
												fontSize: 16,
												fontWeight: 600,

												color: '#292A2B',
											}}
										>
											녹음 파일 변환
										</Typography>
										<Stack spacing={0.5} direction={'row'} alignItems={'center'}>
											<Typography sx={{ fontSize: 16, fontWeight: 600, color: '#1C6EFF' }}>
												{sttProgress.list.length}
											</Typography>
											<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#292A2B' }}>
												건 진행 중
											</Typography>
											<ArrowIcon
												style={{
													transform: isOpenProgressPopup ? 'rotate(0deg)' : 'rotate(180deg)',
													transition: 'transform 0.3s ease-in-out',
												}}
											/>
										</Stack>
									</Stack>
									<Box
										sx={{
											flex: 1,
											flexShrink: 0,
											overflow: 'auto',
											maxHeight: 'calc(182px - 38px)',
											height: isOpenProgressPopup ? 'auto' : 0,
											opacity: isOpenProgressPopup ? 1 : 0,
											visibility: isOpenProgressPopup ? 'visible' : 'hidden',
											transition: 'height 0.3s ease-in-out, opacity 0.2s ease-in-out',
											transitionDelay: isOpenProgressPopup ? '0.1s' : '0s',
										}}
									>
										{sttProgress.list.map(content => (
											<ContentProgress content={content} />
										))}
									</Box>
								</Box>
							) : (
								<></>
							)}
						</div>
						<div className='workspace'>
							<div className='avatar'>
								<Avatar name={auth.member.workspace.name} size='32' round='10px' color='#007AFF' />
							</div>
							<div className='title'>{auth.member.workspace.name}</div>
						</div>
						<div className='menu-list'>{drawBottomMenus()}</div>
					</div>
				</>
			) : (
				<div
					onClick={() => {
						onLogout();
					}}
				>
					Cannot find member
				</div>
			)}

			{isOpenModal && (
				<UploadOption
					fileType={fileType}
					isOpen={isOpenModal}
					onSubmit={handleOnSubmit}
					onClose={() => {
						setIsOpenModal(false);
						setFileType(null);
						setFile(null);
						setFileName(null);
					}}
				/>
			)}
		</div>
	);
});

export default SidebarV2;

const ContentProgress = ({ content }) => {
	const [progress, setProgress] = useState(0);
	const { onDeleteSttProgress } = useContentsStore();

	useEffect(() => {
		setProgress(content.percentage);
	}, [content.percentage]);

	return (
		<Box sx={{ width: '100%', padding: `4px 8px`, boxSizing: 'border-box' }}>
			<Typography
				sx={{
					fontSize: 14,
					fontWeight: 500,
					fontFamily: 'Noto Sans KR',
					color: '#4D5256',
					display: 'block',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
				}}
			>
				파일명 : {content.title}
			</Typography>
			<LinearProgress variant='determinate' value={progress} />
			<Stack justifyContent={'space-between'} alignItems={'center'} direction='row' sx={{ paddingTop: `5px` }}>
				<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR', color: '#A9AFB3' }}>
					{content.status === 'ERROR' ? '변환 실패' : progress < 80 ? 'STT 변환 중' : 'LLM 진행 중'}
				</Typography>
				{content.status === 'ERROR' ? (
					<Button
						sx={{
							width: 50,
							height: 28,
							borderRadius: `4px`,
							background: `#F9FAFB`,
							border: `1px solid #CED3D6`,
							boxSizing: 'border-box',
							color: '#292A2B',
							minWidth: 0,
						}}
						onClick={() => onDeleteSttProgress(content)}
					>
						확인
					</Button>
				) : (
					<Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Noto Sans KR', color: '#1C6EFF' }}>
						{progress}%
					</Typography>
				)}
			</Stack>
		</Box>
	);
};
