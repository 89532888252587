import { Box, Paper, Stack, SvgIcon, Typography, Tooltip } from '@mui/material';
import React from 'react';

import { ReactComponent as CopyIcon } from './img/copy.svg';
import { ReactComponent as PasteIcon } from './img/paste.svg';
import { ReactComponent as AddIcon } from './img/add.svg';

const ContextMenu = ({ isBookmark, onToggleBookmark, onCopyClipboard, onPasteNote, onClose }) => {
	return (
		<Box
			sx={{
				width: 136,
				height: 40,
				boxSizing: 'border-box',
				border: `1px solid rgba(206, 211, 214, 1)`,
				borderRadius: '4px',
				boxShadow: `2px 2px 8px 0px rgba(0, 0, 0, 0.1)`,
				padding: '8px 16px 8px 16px',
				background: '#fff',
			}}
		>
			<Stack direction='row' sx={{ gap: '16px' }}>
				<Paper
					sx={{
						width: '24px',
						height: '24px',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onToggleBookmark();
						onClose() && onClose();
					}}
				>
					<Tooltip title={isBookmark ? '북마크 제거' : '북마크 추가'} placement='top'>
						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
							<AddIcon />
						</SvgIcon>
					</Tooltip>
				</Paper>

				<Paper
					sx={{
						width: '24px',
						height: '24px',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onCopyClipboard();
						onClose();
					}}
				>
					<Tooltip title={'내용 복사'} placement='top'>
						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
							<CopyIcon />
						</SvgIcon>
					</Tooltip>
				</Paper>
				<Paper
					sx={{
						width: '24px',
						height: '24px',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onPasteNote();
						onClose();
					}}
				>
					<Tooltip title={'노트에 붙여넣기'} placement='top'>
						<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
							<PasteIcon />
						</SvgIcon>
					</Tooltip>
				</Paper>
			</Stack>
		</Box>
	);
};

export default ContextMenu;
