import React, { useEffect, useState } from 'react';
import './Download.css';
import { htmlToText } from 'html-to-text';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

import { ReactComponent as PdfIcon } from './img/pdf.svg';
import { ReactComponent as SourceIcon } from './img/source.svg';
import { ReactComponent as TxtIcon } from './img/txt.svg';
import { ReactComponent as WordIcon } from './img/word.svg';
import { ReactComponent as CheckedIcon } from './img/checked.svg';
import { ReactComponent as SubCheckedIcon } from './img/subChecked.svg';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { ToastInfo } from '../../common/toast/Toast';

const OPTIONS = [
	// { label: 'PDF', value: 'pdf', isChecked: false },
	// { label: 'Word', value: 'word', isChecked: false },
	{ label: '텍스트파일', value: 'txt', isChecked: false },
	{ label: '음성파일', value: 'source', isChecked: false },
];

const SUB_OPTIONS = [
	{ label: '전체 요약', value: 'summary', isChecked: true },
	// { label: '대화 분석', value: 'analysis', isChecked: true },
	// { label: '구간 요약', value: 'chronological', isChecked: true },
	{ label: '북마크', value: 'highlight', isChecked: true },
	{ label: '노트', value: 'note', isChecked: true },
	{ label: '원본 기록', value: 'transcription', isChecked: true },
];

const Download = ({ content }) => {
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	const [options, setOptions] = useState(OPTIONS);
	const [subOptions, setSubOptions] = useState(SUB_OPTIONS);
	const [isLoading, setIsLoading] = useState(false);

	const { selectedContent, getNoteData, downloadFile } = useContentsStore();

	useEffect(() => {
		console.log('content : ', content);
	}, [content]);

	const drawOption = option => {
		const drawIcon = () => {
			switch (option.value) {
				case 'pdf':
					return <PdfIcon />;

				case 'word':
					return <WordIcon />;

				case 'txt':
					return <TxtIcon />;

				case 'source':
					return <SourceIcon />;
			}
		};

		return (
			<div
				className='option'
				onClick={() =>
					setOptions(prev =>
						prev.map(item => (item.value === option.value ? { ...item, isChecked: !item.isChecked } : item))
					)
				}
			>
				<div className='icon'>{drawIcon()}</div>
				<div className='text'>{option.label}</div>
				<div className='checked'>{option.isChecked && <CheckedIcon />}</div>
			</div>
		);
	};

	const isVisibleSubOptions = () => {
		const isCheckedTextOption = options
			.filter(option => option.value !== 'source')
			.some(option => option.isChecked);

		if (isCheckedTextOption) {
			return true;
		} else {
			return false;
		}
	};

	const drawSubOption = option => {
		return (
			<div
				className='option'
				onClick={() =>
					setSubOptions(prev =>
						prev.map(item => (item.value === option.value ? { ...item, isChecked: !item.isChecked } : item))
					)
				}
			>
				<div className='checkbox'>{option.isChecked ? <SubCheckedIcon /> : <div className='circle' />}</div>
				<div className='text'>{option.label}</div>
			</div>
		);
	};

	const onDownload = async () => {
		setIsLoading(true);

		const makeTextBlob = async () => {
			let text = '';

			const getTitle = key => {
				switch (key) {
					case 'topics':
						return '주제';

					case 'speakerInfo':
						return ' 참석자';

					case 'keywords':
						return '키워드';

					case 'summary':
					case 'aiSummary':
						return '전체 요약';

					case 'summaryTime':
						return '주제별 상세 요약';

					case 'todoList':
					case 'tasks':
						return '할 일';

					case 'issues':
						return '이슈';

					case 'segments':
					case 'mergedSegments':
						return '음성 기록';

					default:
						return key;
				}
			};

			const getDurationFormat = seconds => {
				seconds = Math.floor(seconds / 1000);
				const hours = Math.floor(seconds / 3600);
				seconds %= 3600;
				const minutes = Math.floor(seconds / 60);
				seconds %= 60;

				const hoursFormatted = String(hours).padStart(2, '0');
				const minutesFormatted = String(minutes).padStart(2, '0');
				const secondsFormatted = String(seconds).padStart(2, '0');

				const result = `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
				return result;
			};

			const getSpeakerName = (speakerList, speakerId) => {
				const target = speakerList && speakerList.find(speaker => speaker.speakerId === speakerId);

				if (target) {
					return target.displayName ?? target.name;
				} else {
					return '알 수 없음';
				}
			};

			const onAppendSummaryData = (aiResult, summaryTime) => {
				if (aiResult) {
					// delete data['speakerSummary'];

					Object.keys(aiResult).map(key => {
						if (aiResult[key].length) {
							text += `- ${getTitle(key)} -\n${aiResult[key].join('\n')}\n\n`;
						}
					});
				}

				if (summaryTime) {
					text += `- 주제별 상세 요약 -\n`;

					summaryTime.map(data => {
						text += `${data.index}. ${data.topic}  ${data.time ?? ''}\n`;

						data.summary.map(
							summary => (text += `ㆍ${typeof summary === 'object' ? summary.content : summary}\n`)
						);

						if (data.issues && data.issues.length) {
							data.issues.map(
								issue => (text += `[이슈] ${typeof issue === 'object' ? issue.content : issue}\n`)
							);
						}

						if (data.tasks && data.tasks.length) {
							data.tasks.map(
								task => (text += `[할일] ${typeof task === 'object' ? task.content : task}\n`)
							);
						}

						text += `\n`;
					});
				}
			};

			const onAppendSummaryTime = summaryTime => {
				summaryTime.map(data => {
					text += `- ${data.topic} - \n${data.time}\n${data.summary.join('\n')}\n${data.issues.join(
						'\n'
					)}\n${data.tasks.join('\n')}\n\n`;
				});
			};

			const onAppendSegments = (segments, speakerInfo) => {
				segments.map(segment => {
					text += `- ${getSpeakerName(speakerInfo, segment.speakerId)} : ${getDurationFormat(
						segment.startTime
					)}\n- ${segment.text}\n\n`;
				});
			};

			const onAppendSpeakerInfo = speakerInfo => {
				speakerInfo.map(speaker => {
					text += `${speaker.displayName ?? speaker.name}\n`;
				});
			};

			const onAppendHighlights = highlights => {
				highlights.map(highlight => {
					text += `- ${getTitle(highlight.key)} -\n`;

					if (highlight.key === 'segments') {
						onAppendSegments(highlight.data, content.speakerInfo);
					} else if (highlight.key === 'summaryTime') {
						onAppendSummaryTime(highlight.data);
					} else if (highlight.key === 'speakerInfo') {
						onAppendSpeakerInfo(highlight.data);
					} else {
						highlight.data.map(data => (text += `${data}\n`));
					}
					text += `\n`;
				});
			};

			const checkedOptions = subOptions.filter(option => option.isChecked);

			for (const option of checkedOptions) {
				if (option.value === 'summary') {
					text += '--- 전체 요약 ---\n';
					onAppendSummaryData(content.aiResult, content.summaryTime);
				}

				// if (option.value === 'chronological') {
				// 	text += '--- 구간별 요약 ---\n';
				// 	onAppendSummaryTime(content.summaryTime);
				// }

				if (option.value === 'highlight') {
					text += '--- 북마크 ---\n';
					onAppendHighlights(content.bookmarks);
				}

				if (option.value === 'note') {
					text += '--- 노트 ---\n';

					const noteData = await getNoteData(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						content.file.contentId
					);

					const noteText = htmlToText(noteData.content);

					text += `${noteText}\n\n`;
				}

				if (option.value === 'transcription') {
					text += '--- 음성 기록 ---\n';
					onAppendSegments(content.mergedSegments, content.speakerInfo);
				}
			}

			return new Blob([text], { type: 'text/plain;charset=utf-8' });
		};

		const makeAudioBlob = async () => {
			const audioUrl = `${process.env.REACT_APP_API_URL}/contents/download/${
				content.file.contentId
			}?accessToken=${cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken}`;

			const audioResponse = await fetch(audioUrl);

			return await audioResponse.blob();
		};

		const checkedOptions = options.filter(option => option.isChecked);

		if (checkedOptions.length > 1) {
			const zip = new JSZip();

			for (const option of checkedOptions) {
				if (option.value === 'txt') {
					const textBlob = await makeTextBlob();
					zip.file(`${content.meta.title}.txt`, textBlob);
				}

				if (option.value === 'source') {
					const audioBlob = await makeAudioBlob();
					zip.file(`${content.file.fileName}`, audioBlob);
				}
			}

			zip.generateAsync({ type: 'blob' }).then(blob => {
				FileSaver.saveAs(blob, `${content.meta.title}.zip`);
			});
		} else {
			const option = options.find(option => option.isChecked);

			if (option.value === 'txt') {
				FileSaver.saveAs(await makeTextBlob(), `${content.meta.title}.txt`);
			} else if (option.value === 'source') {
				downloadFile(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, content.meta);
			}
		}

		ToastInfo('선택한 파일이 다운로드되었습니다.', '다운로드 완료');
	};

	return (
		<div className='popup-download-container'>
			<div className='title'>다운로드</div>
			<div className='separator' />
			<div className='option-list'>{options.map(option => drawOption(option))}</div>
			{isVisibleSubOptions() && (
				<>
					<div className='separator' />
					<div className='option-list'>{subOptions.map(option => drawSubOption(option))}</div>
				</>
			)}
			<button
				onClick={onDownload}
				disabled={
					options.some(option => option.isChecked) && subOptions.some(option => option.isChecked)
						? false
						: true
				}
			>
				다운로드
			</button>
		</div>
	);
};

export default Download;
