import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReactComponent as ChatbotIcon } from './img/chatbot.svg';
import { Box, Button, Divider, IconButton, Input, Stack, Typography } from '@mui/material';
import ModalessPopup from './ModalessPopup';
import { drawContentTypeV2 } from '../common/ContentIcon/ContentIcon';
import useAuthStore from '../../store/AuthStore';
import SendIcon from '@mui/icons-material/Send';
import Keyword from './items/Keyword';
import Welcome from './items/Welcome';
import Balloon from './items/Balloon';
import Popup from 'reactjs-popup';
import { ToastInfo } from '../common/toast/Toast';
import axios from 'axios';

const API_HOST = process.env.REACT_APP_SK_API_HOST;

const Chatbot = ({ content }) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isClosePopupOpen, setClosePopupOpen] = useState(false);
	const { auth } = useAuthStore();

	const openPopup = () => setIsPopupOpen(true);
	const closePopup = () => setIsPopupOpen(false);

	const [sendText, setSendText] = useState('');
	const [chattings, setChattings] = useState([]);
	const [isBotLoading, setIsBotLoading] = useState(false);
	const [selectedRecommends, setSelectedRecommends] = useState([]);

	const chatContainerRef = useRef(null); // Ref 생성

	const recommnads = [
		{
			keyword: '이번 회의의 주요 결론은 무엇인가요?',
		},
		{
			keyword: '회의에서 논의된 중요한 이슈는 무엇이었나요?',
		},
		{
			keyword: '회의에서 결정된 다음 액션 아이템은 무엇이고 누가 담당하나요?',
		},
		{
			keyword: '회의에서 언급된 [특정 주제/이슈]에 대해 더 자세히 알려줄 수 있나요?',
		},
		{
			keyword: '아직 해결되지 않은 이슈는 무엇이 있나요?',
		},
		{
			keyword: '회의에서 제기된 우려 사항이나 리스크는 무엇이었나요?',
		},
		{
			keyword: '[특정 주제/이슈]에 대한 참석자 1의 피드백 정리해주세요.',
		},
	];

	useLayoutEffect(() => {
		function getRandomRecommendations(recommendations, count = 3) {
			const shuffled = recommendations.sort(() => 0.5 - Math.random()); // 배열을 무작위로 섞음
			return shuffled.slice(0, count); // 상위 count개 선택
		}

		setSelectedRecommends(getRandomRecommendations(recommnads));
	}, []);

	useEffect(() => {
		// 스크롤을 가장 아래로 이동
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [chattings]); // chattings가 변경될 때 실행

	const drawRecommand = () => {
		return selectedRecommends.map((item, index) => (
			<Stack
				key={`recommand-${index}`}
				sx={{
					paddingBottom: '16px',
				}}
			>
				<Keyword
					label={item.keyword}
					sx={{}}
					onClick={() => {
						send(item.keyword);
					}}
				/>
			</Stack>
		));
	};

	const drawChatting = () => {
		return chattings.map((item, index) => <Balloon message={(index, item)} />);
	};

	const send = (text = '') => {
		let message = '';
		if (text) {
			message = text;
		} else {
			message = sendText;
			setSendText('');
		}

		if ('' !== message) {
			setChattings(prev => [
				...prev,
				{
					type: 'me',
					text: message,
				},
			]);

			requestMessage(message);
		}
	};

	function replaceNewlinesWithBr(text) {
		return text; //.replace(/\n/g, '</br>');
	}

	const requestMessage = async message => {
		// 챗봇 서버에 메시지 전송

		setIsBotLoading(true);
		const data = {
			email: auth.user.profile.email,
			contentId: content.meta.contentId,
			query: message,
		};

		const url = `${API_HOST}/llm_api/chat`;
		console.log('[Chatbot] Request URL:', url);

		try {
			const response = await axios.post(url, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.data && response.data.agent) {
				setChattings(prev => [
					...prev,
					{
						type: 'bot',
						text: replaceNewlinesWithBr(response.data.agent[0].content),
						time: new Date(),
					},
				]);
			} else {
				console.log9('[Chatbot] Response:', response);
				showErrorMessage(100);
			}
		} catch (error) {
			console.error('[Chatbot] Error:', error);
			showErrorMessage(200);
		} finally {
			setIsBotLoading(false);
		}
	};

	const showErrorMessage = () => {
		let errorMessage = '서버가 응답하지 않습니다.\n잠시 후 다시 시도해주세요.';
		setChattings(prev => [
			...prev,
			{
				type: 'bot',
				text: replaceNewlinesWithBr(errorMessage),
				time: new Date(),
			},
		]);
	};

	return (
		<>
			<IconButton
				onClick={() => {
					setIsPopupOpen(true);
				}}
				sx={{
					width: 48,
					height: 48,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginLeft: 'auto',
					position: 'absolute',
					top: 4,
					right: 20,
					padding: 0,
				}}
			>
				<ChatbotIcon />
			</IconButton>

			<ModalessPopup isOpen={isPopupOpen} onClose={closePopup}>
				<Box sx={{ height: '60px' }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						{drawContentTypeV2(auth, content.meta, 24)}
						<Typography
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								color: '#28323C',
								fontFamily: 'Noto Sans KR',
								fontSize: '16px',
								fontWeight: '600',
								lineHeight: '22px',
								flex: 1,
								flexShrink: 0,
							}}
						>
							{content.meta.title}
						</Typography>
						<Button
							sx={{
								display: 'flex',
								padding: '8px',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '8px',
								borderRadius: '8px',
								border: '1px solid #CED3D6',
								backgroundColor: '#fff',
								color: '#28323C',
								flexShrink: 0,
								'&:hover': {
									background: `#1C6EFF`,
									color: '#fff',
								},
							}}
							onClick={() => {
								setClosePopupOpen(true);
							}}
						>
							채팅 종료
						</Button>

						{isClosePopupOpen && (
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									background: 'rgba(0, 0, 0, 0.5)',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									zIndex: 10, // ModalessPopup 내부에서 최상위로 설정
								}}
							>
								<Box
									sx={{
										background: '#fff',
										padding: '24px',
										borderRadius: '16px',
										boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
										textAlign: 'center',
										maxWidth: '400px',
										width: '320px', // 원하는 너비
										height: '164px', // 원하는 높이
									}}
								>
									<Typography
										sx={{
											// marginTop: '16px',
											marginBottom: '4px',
											color: '#292A2B',
											fontSize: '20px',
											fontWeight: '600',
											lineHeight: '26px',
										}}
									>
										채팅을 종료할까요?
									</Typography>
									<Typography
										sx={{
											color: '#878D91',
											marginBottom: '24px',
											fontSize: '14px',
											fontWeight: '400',
											lineHeight: '26px',
										}}
									>
										A.Biz 회의록 챗봇이 종료됩니다.
									</Typography>
									<Stack direction='row' spacing={2} justifyContent='center'>
										<Button
											sx={{
												background: '#E1E4E6',
												color: '#4D5256',
												width: '132px',
												height: '48px',
											}}
											onClick={() => setClosePopupOpen(false)} // 팝업 닫기
										>
											취소
										</Button>
										<Button
											sx={{
												background: '#1C6EFF',
												color: '#ffffff',
												width: '132px',
												height: '48px',
											}}
											onClick={() => {
												// 종료 로직 추가
												setClosePopupOpen(false); // 팝업 닫기
												closePopup(); // ModalessPopup 닫기
											}}
										>
											확인
										</Button>
									</Stack>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
				<Divider sx={{ margin: '0 0 16px 0' }} />
				<Box
					ref={chatContainerRef} // Ref 연결
					sx={{
						paddingRight: '16px',
						marginRight: '-16px',
						height: '540px',
						overflow: 'auto',
					}}
				>
					<Welcome />
					{drawRecommand()}
					{drawChatting()}
					{isBotLoading && <Balloon message={{ type: 'botWorking', time: new Date() }} />}
				</Box>
				<Divider sx={{ margin: '0 0 16px 0' }} />
				<Box
					sx={{
						width: '100%',
						height: '44px',
						display: 'flex',
						padding: '7px 12px',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '48px',
						background: '#F3F5F7',
						boxSizing: 'border-box',
					}}
				>
					<Input
						disableUnderline
						sx={{
							color: '#292A2B',
							fontFamily: 'Noto Sans KR',
							fontSize: '16px',
							fontStyle: 'normal',
							fontWeight: '500',
							lineHeight: '20px',
							flex: 1,
							'&::placeholder': {
								color: 'red',
							},
						}}
						placeholder='텍스트를 입력해주세요.'
						onFocus={() => {}}
						onBlur={() => {}}
						value={sendText}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								send();
							}
						}}
						onChange={e => setSendText(e.target.value)}
					/>
					<IconButton
						onClick={() => {
							send();
						}}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '44px',
							height: '30px',
							padding: '7px 12px',
							borderRadius: '99px',
							background: `${'' === sendText ? '#CED3D6' : '#1C6EFF'}`,
							'&:hover': {
								background: `#1C6EFF`,
							},
						}}
					>
						<SendIcon sx={{ color: '#fff' }} />
					</IconButton>
				</Box>
			</ModalessPopup>
		</>
	);
};

export default Chatbot;
