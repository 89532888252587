// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc-slider-handle {
	background: #1c6eff !important;
	border: 1px solid transparent !important;
	opacity: 1;
}

.rc-slider-track,
.rc-slider-handle {
	background-color: #1c6eff !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/transcription/TranscriptionV2.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,wCAAwC;CACxC,UAAU;AACX;;AAEA;;CAEC,oCAAoC;AACrC","sourcesContent":[".rc-slider-handle {\n\tbackground: #1c6eff !important;\n\tborder: 1px solid transparent !important;\n\topacity: 1;\n}\n\n.rc-slider-track,\n.rc-slider-handle {\n\tbackground-color: #1c6eff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
