// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
button {
	/* font-family: 'Noto Sans KR', sans-serif; */
	font-family: 'Noto Sans KR !important';
}

.App {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	margin: 0;
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-thumb {
	background: #e0e0e0; /* 색상 */
	border-radius: 6px; /* 둥근 모서리 */
}

body > #webpack-dev-server-client-overlay {
	display: none;
}

html {
	color-scheme: light; /* 운영 체제 테마와 관계없이 라이트 모드 고정 */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;CAWC,6CAA6C;CAC7C,sCAAsC;AACvC;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,gBAAgB;CAChB,SAAS;AACV;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,mBAAmB,EAAE,OAAO;CAC5B,kBAAkB,EAAE,WAAW;AAChC;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,mBAAmB,EAAE,6BAA6B;AACnD","sourcesContent":["body,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\ninput,\ntextarea,\nselect,\nbutton {\n\t/* font-family: 'Noto Sans KR', sans-serif; */\n\tfont-family: 'Noto Sans KR !important';\n}\n\n.App {\n\twidth: 100vw;\n\theight: 100vh;\n\toverflow: hidden;\n\tmargin: 0;\n}\n\n::-webkit-scrollbar {\n\twidth: 6px;\n}\n\n::-webkit-scrollbar-thumb {\n\tbackground: #e0e0e0; /* 색상 */\n\tborder-radius: 6px; /* 둥근 모서리 */\n}\n\nbody > #webpack-dev-server-client-overlay {\n\tdisplay: none;\n}\n\nhtml {\n\tcolor-scheme: light; /* 운영 체제 테마와 관계없이 라이트 모드 고정 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
