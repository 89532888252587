import { Box, Stack } from '@mui/material';
import { ReactComponent as SearchIcon } from './img/searchvs.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as ClearIcon } from './img/clear.svg';
import { useEffect, useRef, useState } from 'react';

const SearchBox = ({
	searchResult,
	setIsTracking,
	keyword,
	setKeyword,
	setSearchResult,
	totalMatches,
	placeholder = '검색어를 입력해주세요.',
	type = 'standard',
	style = {},
}) => {
	const inputRef = useRef();

	const onCancel = () => {
		setKeyword && setKeyword('');
		setSearchResult &&
			setSearchResult({
				currentIndex: 0,
				total: 0,
			});
	};

	return (
		<>
			{type === 'standard' && (
				<SearchIcon style={{ width: '24px', height: '24px', fontSize: '17px', marginLeft: '10px' }} />
			)}
			<input
				ref={inputRef}
				type='text'
				value={keyword}
				onChange={e => {
					setKeyword && setKeyword(e.target.value);
					setIsTracking && setIsTracking(false);
				}}
				onKeyDown={e => {
					e.stopPropagation();
					if (e.key === 'Escape') {
						onCancel();
					} else if (e.key === 'Enter') {
						setSearchResult &&
							setSearchResult(prev => ({
								...prev,
								currentIndex: (prev.currentIndex + 1) % totalMatches,
								keyword: keyword,
							}));
						e.preventDefault();
					}
				}}
				placeholder={placeholder}
				style={{
					paddingLeft: type === 'standard' ? 0 : '8px',
					display: 'flex',
					flex: 1,
					backgroundColor: type === 'standard' ? 'hsla(210, 20%, 98%, 1)' : '#fff',
					border: 'none',
					minWidth: '0px',
					outline: 'none',
					fontSize: '16px',
					fontWeight: 400,
					lineHeight: '22px',
					boxSizing: 'border-box',
					fontFamily: 'Noto Sans KR',
					...style,
				}}
			/>
			<Stack
				spacing={1.25}
				direction={'row'}
				sx={{
					alignItems: 'center',
					justifyContent: 'flex-end', // 오른쪽 끝에 정렬
					userSelect: 'none',
					color: '#858c99',
					fontSize: '12px',
					minWidth: '85px',
					paddingRight: '10px',
					height: '100%',
				}}
				onClick={() => keyword === '' && inputRef.current.focus()}
			>
				<Stack spacing={1.5} direction='row' alignItems={'center'}>
					{keyword && (
						<>
							<Box
								className='search-result'
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									// width: type === 'standard' ? '80px' : 'auto',
									cursor: 'pointer',
								}}
							>
								{totalMatches > 0 ? searchResult.currentIndex + 1 : 0} / {totalMatches}
							</Box>
							<Box
								className='search-result-up'
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									cursor: 'pointer',
								}}
								onClick={() => {
									setSearchResult &&
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex - 1 + totalMatches) % totalMatches,
										}));
									setIsTracking && setIsTracking(false);
								}}
							>
								<ArrowIcon />
							</Box>
							<Box
								className='search-result-down'
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									cursor: 'pointer',
									transform: 'scaleY(-1)',
								}}
								onClick={() => {
									setSearchResult &&
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex + 1) % totalMatches,
										}));
									setIsTracking && setIsTracking(false);
								}}
							>
								<ArrowIcon />
							</Box>
							{type === 'standard' && (
								<Box
									className='search-result-cancel'
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
									onClick={() => {
										onCancel();
									}}
								>
									<ClearIcon />
								</Box>
							)}
						</>
					)}
				</Stack>
			</Stack>
		</>
	);
};

export default SearchBox;
