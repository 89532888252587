import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './HeaderV2.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from './img/edit.svg';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as DeleteIcon } from './img/delete.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import DateRangePicker from '../../common/DateRangePicker';
import Popup from 'reactjs-popup';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import FilterV2 from '../../popup/filter/FilterV2';
import Order from '../../popup/order/Order';
import useFilterStore from '../../../store/FilterStore';
import { ToastInfo } from '../../common/toast/Toast';
import { getSafeUrl } from '../../../util/Util';

const HeaderV2 = forwardRef(
	(
		{
			icon,
			text = 'Untitled',
			originTitle = '',
			recentList,
			description,
			enableSearch,
			enableEdit,
			enableFilter,
			enableOrder,
			enableRange,
			buttonComponents,
			onChangeTitle,
			style = {},
			searchKeyword = '',
		},
		ref
	) => {
		const inputRef = useRef();
		const popupRef = useRef();
		const navigate = useNavigate();
		const [keyword, setKeyword] = useState(searchKeyword);
		const [recents, setRecents] = useState([]);
		const [editMode, setEditMode] = useState(false);
		const [isVisibleRecent, setIsVisibleRecent] = useState(false);
		const [dates, setDates] = useState(null);

		const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
		const {
			home,
			search,
			searchHistory,
			searchContents,
			isSearching,
			deleteSearchHistoryKeyword,

			deleteSearchHistoryAllKeyword,
		} = useContentsStore();
		const { orderType, ORDER_LIST, shareType, contentType } = useFilterStore();

		const [isOpenFilter, setIsOpenFilter] = useState(false);
		const [isOpenOrder, setIsOpenOrder] = useState(false);

		useImperativeHandle(ref, () => ({
			onClickKeyword: keyword => {
				onSearchKeyword(keyword);
			},
		}));

		useEffect(() => {
			if (home && home.keywords && home.keywords.length) {
				setRecents(home.keywords.map(item => ({ ...item, text: item.keyword })));
			} else {
				setRecents([]);
			}
		}, [home]);

		useEffect(() => {
			if (editMode) {
				inputRef.current.focus();
			}

			inputRef.current.scrollLeft = 0;
		}, [editMode]);

		useEffect(() => {
			const handleClickOutside = event => {
				if (
					popupRef.current &&
					!popupRef.current.contains(event.target) &&
					!inputRef.current.contains(event.target)
				) {
					setIsVisibleRecent(false);
				}
			};

			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, []);

		const drawRecentList = () => {
			const drawRow = item => {
				return (
					<div className='recent-row' onClick={() => onSearchKeyword(item.text)}>
						<div className='icon'>
							<SearchIcon stroke='#878D91' />
						</div>
						<div className='text'>{item.text}</div>
						<div className='date'>{moment(item.searchAt).locale('ko').format('YY.MM.DD')}</div>
						<IconButton
							sx={{
								width: 35,
								height: 35,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								boxSizing: 'border-box',

								'&:hover': {
									border: '1px solid #5793FF',
								},

								'&:hover > svg': {
									color: '#5793FF',
								},
							}}
							onClick={event => {
								event.stopPropagation();

								deleteSearchHistoryKeyword(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									item.keywordId
								);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				);
			};

			return (
				<div className='recent-list-container' ref={popupRef}>
					<div className='header'>
						<div className='title'>최근 검색어</div>
						<button
							onClick={() =>
								deleteSearchHistoryAllKeyword(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken)
							}
						>
							전체 삭제
						</button>
					</div>
					<div className='list'>
						{recents && recents.length ? recents.map(recent => drawRow(recent)) : null}
					</div>
				</div>
			);
		};

		const drawFilterText = () => {
			let filterCount = 0;

			if (shareType !== 'ALL') {
				filterCount += 1;
			}
			if (contentType !== 'ALL') {
				filterCount += 1;
			}

			return (
				<Stack spacing={0.5} direction={'row'} alignItems={'center'}>
					<Typography
						sx={{
							fontSize: 16,
							fontWeight: 500,
							fontFamily: 'Noto Sans KR',
							color: filterCount > 0 ? '#2A53FF' : 'inherit',
						}}
					>
						필터
					</Typography>

					{filterCount > 0 && (
						<Box
							sx={{
								width: 19,
								height: 19,
								borderRadius: '50%',
								background: '#2A53FF',
								color: '#fff',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: 12,
								fontWeight: 600,
								fontFamily: 'Noto Sans KR',
							}}
						>
							{filterCount}
						</Box>
					)}
				</Stack>
			);
		};

		const onSearchKeyword = text => {
			if (!isSearching) {
				if (text.trim() !== '') {
					setKeyword(text);
					navigate(
						getSafeUrl(
							`/search?start=${dates.start}&end=${dates.end ?? dates.start}${text && `&keyword=${text}`}`
						)
					);
				}
			}
		};

		const getOrderTypeText = () => {
			try {
				const target = ORDER_LIST.find(order => order.value === orderType);

				if (target) {
					return target.label;
				} else {
					return 'Unknown';
				}
			} catch {
				return 'Unknown';
			}
		};

		const handleChangeTitle = updatedText => {
			if (updatedText === originTitle) {
				setEditMode(false);
			} else {
				if (updatedText.trim() !== '') {
					onChangeTitle(
						updatedText,
						() => {
							inputRef.current.innerText = updatedText;
						},
						() => (inputRef.current.innerText = text)
					);
				} else {
					onChangeTitle(
						originTitle,
						() => {
							inputRef.current.innerText = originTitle;
						},
						() => (inputRef.current.innerText = originTitle)
					);
				}
				setEditMode(false);
			}
		};

		return (
			<div className={`header-v2-container ${description ? 'detail' : ''}`} style={style}>
				{icon && <div className='icon'>{icon}</div>}
				<div className='info'>
					<div className='top'>
						<div
							ref={inputRef}
							className={`title ${editMode ? 'edit' : ''}`}
							contentEditable={editMode ? true : false}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									handleChangeTitle(e.target.innerText);
								} else if (e.key === 'Escape') {
									inputRef.current.innerText = text;
									setEditMode(false);
								}
							}}
						>
							{text}
						</div>
						{enableEdit && (
							<button
								className={`${editMode ? 'save' : ''}`}
								onClick={() => {
									if (editMode) {
										handleChangeTitle(inputRef.current.innerText);
									} else {
										setEditMode(true);
									}
								}}
							>
								{editMode ? '저장' : <EditIcon stroke='#4D5256' />}
							</button>
						)}
					</div>

					{description && <div className='bottom'>{description}</div>}
				</div>
				{enableSearch && (
					<>
						<div
							className='date-picker'
							style={{ marginLeft: 'auto', visibility: enableRange ? 'visible' : 'hidden' }}
						>
							<DateRangePicker
								onChanged={date => {
									setDates(date);
								}}
							/>
						</div>

						<div className='search-bar' style={{ marginLeft: enableRange ? `none` : 'auto' }}>
							<SearchIcon stroke='#4D5256' />
							<input
								ref={inputRef}
								type='text'
								placeholder='검색어를 입력해주세요.'
								// disabled={isSearching}
								value={keyword}
								onChange={e => setKeyword(e.target.value)}
								onFocus={() => setIsVisibleRecent(true)}
								onKeyDown={e => e.key === 'Enter' && onSearchKeyword(e.target.value)}
								style={{
									fontFamily: 'Noto Sans KR',
								}}
							/>
							{isVisibleRecent && !keyword && drawRecentList()}
						</div>
					</>
				)}
				{enableFilter && (
					<DropDownButton
						title={drawFilterText()}
						isOpen={isOpenFilter}
						setIsOpen={setIsOpenFilter}
						type='filter'
					/>
				)}
				{enableOrder && (
					<DropDownButton
						title={getOrderTypeText()}
						isOpen={isOpenOrder}
						setIsOpen={setIsOpenOrder}
						type='order'
					/>
				)}

				{buttonComponents && <div className='buttons'>{buttonComponents}</div>}
			</div>
		);
	}
);

export default HeaderV2;

const DropDownButton = ({ title, isOpen, setIsOpen, type }) => {
	return (
		<Popup
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			trigger={
				<Button
					variant='outlined'
					sx={{
						padding: '9px',
						width: type === 'order' ? 145 : 95,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						borderRadius: '8px',
						border: '1px solid rgba(225, 228, 230, 1)',
						boxSizing: 'border-box',
						gap: '8px',
						backgroundColor: isOpen ? `#F5F8FF !important` : `#fff !important`,
						color: isOpen ? `#1C6EFF !important` : `#28323C !important`,
						flexShrink: 0,
					}}
				>
					<Typography
						sx={{ fontSize: 16, fontWeight: 500, fontFamily: 'Noto Sans KR', color: 'rgba(40, 50, 60, 1)' }}
					>
						{title}
					</Typography>
					<ArrowIcon style={{ rotate: `${isOpen ? '180deg' : `0deg`}` }} />
				</Button>
			}
		>
			{close => (type === 'filter' ? <FilterV2 /> : <Order onClose={close} />)}
		</Popup>
	);
};
